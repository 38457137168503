<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')"
                          fit="cover"></el-image>
            </div>
            <div class="pleft">
                当前位置：<span>首页</span><span>>个人中心</span><span>>基本资料</span>
            </div>
        </div>
        <div class="hc flex">

            <el-form
                    size="small"
                    :inline="true"
                    ref="form"
                    :model="form"
                    label-width="80px"
                    class="formstylejp"
            >

                <el-form-item label=""  class=" fsizestyle" prop="sendcity">

                            <span class="spanstyles">
                                手机号码：{{userinfo.mobile}}
                            </span>
                    <span class="spanstyles">
                                注册时间：{{userinfo.create_data}}
                            </span>
                </el-form-item>
                <el-form-item label=""  class=" fsizestyle" prop="sendcity">

                            <span class="spanstyles">
                                单位：{{userinfo.gs_name}}<el-button style="margin-left:10px;" @click="delgs">解绑</el-button>
                            </span>

                </el-form-item>
                <el-form-item label=""  class=" fsizestyle" prop="sendcity">

                            <span class="spanstyles">
                                部门：  <el-input class="inputstyle heightstyle" placeholder="请输入部门" v-model="userinfo.dept_name" readonly
                                               clearable></el-input>
                                <!--<el-select v-model="form.name" placeholder="选择部门">-->
                                    <!--<el-option label="软件研发部" value="0"></el-option>-->
                                    <!--<el-option label="测试部门" value="1"></el-option>-->
                                <!--</el-select>-->
                            </span>

                </el-form-item>

            </el-form>


        </div>
        <div class="savebtndiv" >
            <el-button class="savebtn" @click="updatemem">保存</el-button>            
        </div>
        <div style="padding: 20px 0">
            <label for="">拒绝加入单位原因:</label>
            <div style="padding: 20px 50px">{{userInfoData.passenger_frequently?.shenhe_yijian || '无'}}</div>
        </div>
        <div class="savebtndiv" v-if="userInfoData.passenger_frequently?.shenhe_yijian">
            <el-button class="savebtn" @click="updateInfo">修改</el-button>
        </div>
        <div class="copyright">
            工业和信息化部备案管理系统网站 豫B1-20080191-28
        </div>
        <el-dialog title="修改信息" top="50px" :visible.sync="updateDialogFormVisible" class="regformreg" width="600px">
            <el-form ref="updform" :model="updform" class="formstyle">
                  <el-form-item label="单位"  label-width="120px" class="labelstyle"  prop="danwei">
                      <el-select filterable remote reserve-keyword :remote-method="(value) => getcomdeptdata(value)" :loading="loading" class="upd-inputstyle" v-model="updform.danwei" placeholder="请选择单位(选填)" @change="danweiChange">
                          <el-option
                            v-for="(item,index) in comdeptdata"
                            :key="index"
                            :label="item.company_name"
                            :value="item.company_id">
                          </el-option>
                      </el-select>
                      <!-- <el-input class="inputstyle"  v-model="form.danwei" clearable></el-input> -->
                  </el-form-item>
                  <el-form-item label="部门(选填)"  label-width="120px" class="labelstyle"  prop="dept">
                      <el-select class="upd-inputstyle" v-model="updform.dept" placeholder="请选择部门(选填)">
                          <el-option label="请选择部门(选填)" value=""></el-option>
                          <el-option
                            v-for="(item,index) in comdeptdata2.departments"
                            :key="index"
                            :label="item.dpt_name"
                            :value="item.dpt_id">
                          </el-option>
                      </el-select>
                      <!-- <el-input class="inputstyle"  v-model="form.dept" clearable></el-input> v-if="form.danwei" -->
                  </el-form-item>
                  <div style="overflow: hidden;"> 
                      <el-col :span="24">
                          <el-form-item label="姓名" label-width="120px" class="labelstyle"  prop="xingming">
                              <el-input class="upd-inputstyle" v-model="updform.xingming" clearable></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col :span="24">
                          <el-form-item label="性别" label-width="120px" class="labelstyle"  prop="xingbie">
                              <el-select class="upd-inputstyle" v-model="updform.xingbie"
                                  placeholder="选择">
                                  <el-option label="男" value="1"></el-option>
                                  <el-option label="女" value="2"></el-option>
                              </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :span="24">
                          <el-form-item label="人员类型" label-width="120px" class="labelstyle"  prop="person_type">
                              <el-select class="upd-inputstyle" v-model="updform.person_type"
                                  placeholder="选择">
                                  <el-option label="成人" value="1"></el-option>
                                  <el-option label="儿童" value="2"></el-option>
                                  <el-option label="学生" value="3"></el-option>
                              </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :span="24">
                          <el-form-item label="证件类型" label-width="120px"  class="labelstyle"  prop="id_type">
                              <el-select class="upd-inputstyle" v-model="updform.id_type"
                                  placeholder="选择">
                                  <el-option label="身份证" value="1"></el-option>
                                  <el-option label="护照" value="2"></el-option>
                              </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :span="24">
                          <el-form-item label="证件号码" label-width="120px"  class="labelstyle"  prop="id_number">
                              <el-input class="upd-inputstyle"  v-model="updform.id_number" clearable></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col :span="24">
                          <el-form-item label="证件照片" label-width="120px"  class="labelstyle"  prop="zjnumber">
                            <el-col :span="12">
                              <el-upload :show-file-list="false" class="upload-image-style" :on-success="handleSuccessCardZhengmian"
                                  action="http://jhkadmin.3.16448.cn/api/common/upload"
                                  list-type="picture-card" :limit="1">
                                  <i class="el-icon-plus" v-if="!updform.id_img_zhengmian"></i>
                                  <div class="icon-div" @click.stop="()=> false">
                                    <i class="el-icon-delete" @click.stop="deleteImage(1)" v-if="updform.id_img_zhengmian"></i>
                                  </div>
                                  <img :src="updform.id_img_zhengmian" class="previewimgstyles" v-if="updform.id_img_zhengmian" />
                              </el-upload>
                            </el-col>
                            <el-col :span="12">
                              <el-upload :show-file-list="false" class="upload-image-style" :on-success="handleSuccessCardFanmian"
                                  action="http://jhkadmin.3.16448.cn/api/common/upload"
                                  list-type="picture-card" :limit="1">
                                  <i class="el-icon-plus" v-if="!updform.id_img_fanmian"></i>
                                  <div class="icon-div" @click.stop="()=> false">
                                    <i class="el-icon-delete" @click.stop="deleteImage(2)" v-if="updform.id_img_fanmian"></i>
                                  </div>
                                  <img :src="updform.id_img_fanmian" class="previewimgstyles" v-if="updform.id_img_fanmian" />
                              </el-upload>
                            </el-col>
                          </el-form-item>                          
                      </el-col>
                </div>
            </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="updateDialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitUpdate">确 定</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
    import ElImage from "element-ui/packages/image/src/main";
    import ElCard from "element-ui/packages/card/src/main";
    import ElButton from "element-ui/packages/button/src/button";
    import Cookie from 'js-cookie';
    import { querymemByIdInfo,updatememinfo,delPassenger, getUserInfo, getComdeptdata, saveUserInfo } from '../api/index';
    export default {
        components: {
            ElButton,
            ElCard,
            ElImage
        },
        data () {
            return {
                activeName: 'first',
                objmem:{},
                user:'',
                form: {
                    memid:'',
                    deptname:'',
                    date1: "",
                    date2:"",
                    sendcity: "",
                    arrivalcity:"",
                },
                updform: {
                    user_id: '',
                    pg_id: '',
                    danwei: '',
                    dept: '',
                    xingming: '',
                    xingbie: '',
                    person_type: '', 
                    id_type: '',
                    id_number: '',
                    id_img_zhengmian: '', 
                    id_img_fanmian: ''
                },
                rules: {
                    mobilephone: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                        { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
                    ],

                    pwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                    ],
                    xingming: [
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                    ],
                    xingbie: [
                        { required: true, message: '请输入性别', trigger: 'blur' },
                    ],
                    person_type: [
                        { required: true, message: '请选择人员类型', trigger: 'blur' },
                    ],
                    id_type: [
                        { required: true, message: '请选择证件类型', trigger: 'blur' },
                    ],
                    id_number: [
                        { required: true, message: '请输入证件号码', trigger: 'blur' },
                    ],
                },
                userinfo:"",
                userInfoData: {},
                updateDialogFormVisible: false,
                
                comdeptdata:[],
                comdeptdata2:[],
                fileList: [],
                loading:false
            }
        },
        activated(){
            const userinfo =JSON.parse(Cookie.get('userinfo'));
            this.userinfo=userinfo
            const user = Cookie.get('phoneKey');
            if (user) {
                this.user = user;
            }
            //根据手机号查询会员信息
            // this.querymeminfobyid(user);
            
            getUserInfo({user_id: userinfo.id}).then(res => {
                const resData = res.data;
                const _this = this
                if(resData.code == 1) {
                    _this.userInfoData = resData.data;                    
                    const {passenger_frequently} = resData.data;
                    if(passenger_frequently) {
                        _this.updform = {
                            user_id: userinfo.id,
                            pg_id: passenger_frequently.pg_id,
                            danwei: passenger_frequently.gs_id,
                            dept: Number(passenger_frequently.deptname),
                            xingming: passenger_frequently.xingming,
                            xingbie: passenger_frequently.xingbie,
                            person_type: passenger_frequently.person_type, 
                            id_type: passenger_frequently.id_type,
                            id_number: passenger_frequently.id_number,
                            id_img_zhengmian: passenger_frequently.id_img_zhengmian, 
                            id_img_fanmian: passenger_frequently.id_img_fanmian
                        }
                        _this.fileList.push({name: '正面', url: passenger_frequently.id_img_zhengmian});
                        _this.fileList.push({name: '背面', url: passenger_frequently.id_img_fanmian});
                        _this.getcomdeptdata(resData.data.gs_name, () => {
                            _this.updform.danwei = passenger_frequently.gs_id;
                            _this.danweiChange(() => {
                                _this.updform.dept = Number(passenger_frequently.deptname);
                            });
                        });
                    }
                }
            })
        },
        methods: {
            deleteImage(type) {
                console.log(type);
                if(type == 1) {
                    this.updform.id_img_zhengmian = '';
                }
                else if(type == 2) {
                    this.updform.id_img_fanmian = '';
                }
            },
            // 获取单位部门
            getcomdeptdata(query, fn){
                const _this = this
                if(query.length<2){
                    return false;
                    _this.comdeptdata=[]
                }
                _this.loading=true
                getComdeptdata({search:query}).then(res => {
                    _this.comdeptdata=res.data.data
                    _this.loading=false
                    fn && fn();
                })
            },
            danweiChange(fn){
                const _this = this
                var comdeptdata2=this.comdeptdata.find(function(item){
                    return item.company_id==_this.updform.danwei
                })
                _this.comdeptdata2=comdeptdata2||[]
                _this.updform.dept=""
                fn && fn();
            },
            updateInfo() {
                this.updateDialogFormVisible = true;
            },
            //提交修改
            submitUpdate(){
                var danweistr=this.updform.danwei;
                var deptstr=this.updform.dept;
                this.$refs["updform"].validate((val) => {
                    if (val) {
                        const params = {
                            gs_id: danweistr,
                            dept_id: deptstr,
                        }
                        if(danweistr){
                            if(!deptstr){
                                this.$message.warning('请选择部门');
                                return false;
                            }
                            if(!this.updform.id_img_zhengmian){
                                this.$message.warning('请上传证件照正面');
                                return false;
                            }
                            if(!this.updform.id_img_fanmian){
                                this.$message.warning('证件照反面');
                                return false;
                            }
                        }
                        params.pg_id = this.updform.pg_id;
                        params.user_id = this.updform.user_id;
                        params.xingming=this.updform.xingming
                        params.xingbie=this.updform.xingbie
                        params.person_type=this.updform.person_type
                        params.id_type=this.updform.id_type
                        params.id_number=this.updform.id_number
                        params.id_img_zhengmian=this.updform.id_img_zhengmian
                        params.id_img_fanmian=this.updform.id_img_fanmian
                        //const p = this.$querystring.stringify(params);
                        saveUserInfo(params).then(res => {
                            this.updateDialogFormVisible = false;
                            if (res.data.code && res.data.code === 1) {
                                this.$message.success('修改成功');
                            }else{
                                this.updateDialogFormVisible = false;
                                var msgstr=res.data.msg;
                                this.$message.error('修改失败:'+msgstr);            
                            }
                        })
                    } else {
                        return false;
                 }
              });
            },
            querymeminfobyid(phone){
                const params={mobilePhone:phone};
                const p = this.$querystring.stringify(params);
                querymemByIdInfo(p).then(res => {
                    this.objmem=res.data.data.ret[0];
                    this.form.deptname=this.objmem.deptName;
                    this.form.memid=this.objmem.memId;
                 })
            },
            delgs(){
                this.$confirm("是否解绑公司?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(async () => {
                        delPassenger({user_id:this.userinfo.id,compnay_id:this.userinfo.gs_id,pg_id:this.userinfo.pg_id}).then((res) => {
                            try {
                                console.log("%c Line:80 🥖 res", "color:#ea7e5c", res);
                                if (res.data.code != 1) {
                                  this.$message({
                                    type: "error",
                                    message: res.data.msg,
                                  });
                                } else {
                                    this.$message.success("解绑成功");
                                    this.userinfo.gs_id=""
                                    this.userinfo.gs_name=""
                                    Cookie.set('userinfo',JSON.stringify(this.userinfo));
                                }
                            } catch (error) {
                                console.log("%c Line:70 🍯", "color:#3f7cff", error);
                             }
                                    
                        });
                    })
                    .catch(() => { });
                
            },
            //修改会员信息
            updatemem(){
                const deptinfo=this.form.deptname;
                if(""==deptinfo || null==deptinfo || undefined==deptinfo){
                    this.$message.warning('请输入部门');
                    return;
                }
                const params = {
                    mobilePhone: this.user,
                    deptName: deptinfo,
                    memId:this.form.memid
                }
                updatememinfo(params).then(res => {
                    if (res.data.code && res.data.code === 200) {

                        this.$message.success('修改成功');
                        this.form.deptname="";
                        this.$router.push('/gerenzx');
                    }else{
                        var  msgstr=res.data.msg;
                        this.$message.error('修改失败:'+msgstr);

                    }
                })
            },
            
            //上传证件照正面
            handleSuccessCardZhengmian(res, file) {
                console.log(res)
                if(res.code==1){
                    this.updform.id_img_zhengmian=res.data.fullurl
                }else{
                    this.$message.error(res.msg)
                }
                console.log(res)
                console.log(this.form)
            },
            //图片预览
            handlePictureCardPreview(file) {
                this.updform.id_img_zhengmian = file.url;
            },
            //上传证件照反面
            handleSuccessCardFanmian(res, file) {
                if(res.code==1){
                    this.updform.id_img_fanmian=res.data.fullurl
                }else{
                    this.$message.error(res.msg)
                }
            
            },
        },
        name: "updateziliao-right"
    }
</script>

<style>
    .upload-image-style > div:hover .icon-div {
        display: flex;
    }
    .upload-image-style > div:hover .previewimgstyles {
        opacity: .3;
    }
</style>
<style scoped>
    .el-upload{position: relative;}
    .el-upload img{position: absolute;width: 100%;height: 100%;left: 0;top: 0;object-fit: contain;}
    .icon-div {
        position: relative;
        display: none;
        align-items: center;
        justify-content: center;
        height: 100%;
        z-index: 1;
    }
    .el-icon-delete {
        position: relative;
        z-index: 2;
        color: #666;
    }    
    .regformreg .formstyle{
        font-family: "MicrosoftYaHei";
        color:#000000;
    }
    .upd-inputstyle {
        width: 460px;
        max-width: 100%;
    }
    .savebtndiv{
        margin-top:3vh;
        text-align: center;
    }
    .savebtn{
        background-color:#42B134;
        color:#FFFFFF;
        width:400px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    .spanstyles{
        width:20vw;
        display: inline-block;
        margin-left:20px;
    }
    .tcontentwidth{
        width: calc(60% - 0px - 0px) !important;
    }
    .rightoper {
        margin-top: 30px;
    }
    .shuxian{
        height:180px;
        font-size:1px;
        width:1px;
        border-right:1px dashed rgba(66, 177, 52, 1);

        margin-left:30px;
    }
    .defaultbtn{
        background-color: #42B134;
        color:#FFFFFF;
    }
    .hcpdiv{
        margin-top:2vh;
        background-color:#F1F1F1;
        width:200px;
        padding-top:2vh;
        padding-left:1.6vw;
        border-top-right-radius: 20px;
        border-top-left-radius: 6px;

    }
    .titlecolor{

    }
    .xiancolor{
        color:rgba(66, 177, 52, 1);
    }
    .hcpicon{
        width:20px;
        height:27px;
    }
    .dfkcolor{
        color:rgba(100, 100, 100, 1);
    }
    .mleft{
        margin-left:16px;
    }
    .titlecolor{
        color:rgba(66, 177, 52, 1);
    }
    .mttop{
        margin-top:1vh;
    }
    .weiziiconst{
        width:24px;
        height:24px;
    }
    .inputstyle{
        width:10vw;
    }
    .cdiv{
        margin-top:30px;
    }
    .xiadandate{
        color:#646464;
        font-size:12px;
        margin-left:10vw;
    }
    .xiadaninfo{
        margin-left:1.8vw;
        margin-top:3vh;
        color:#646464;
        font-size:12px;

    }
    .mtopstyle{
        margin-left:1vw;
        margin-top:1vh;
    }
    .hengxian{
        border-bottom:1px solid rgba(34, 172, 56, 1);
        margin-left:2vw;
    }
    .paycolor{
        color:#42B134;
        border:1px solid #42B134;
        width:100px;
        height:35px;
        text-align: center;
    }
    .mstyle{
        width:100px;
        text-align:center;

    }
    .operbtn{
        margin-left:30px;
        margin-top:8vh;

    }
    .redcolorstyle{
        color:#FF7342;
        font-weight:bolder;
    }
    .bluecolor{
        color:#22AC38;
    }
    .sficon{
        margin-left:0.5vw;
    }
    .tabwidth{
        background-color:#F1F1F1;
        margin-top:3vh;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    .weiziiconsj{
        width:16px;
        height:16px;
        padding-left:1.2vw;
    }
    .iconleft{
        margin-left:0.5vw;
        margin-top:0.2vh;
    }
    .datestrs{
        margin-left:0.5vw;
    }
    .traindatediv{
        background-color:#F1F1F1;
        border-top-right-radius: 20px;
        padding-top:20px;

    }
    .copyright{
        font-size:12px;
        text-align: center;
        margin-top:5vh;
        color:#B6B6B6;
    }
    .mleftmargin{
        margin-left:1vw;
    }
    .pager{
        margin-top:5vh;
        margin-left:0vw;
        display: flex;
        justify-content: center;

    }
    .shouye{

    }
    .sybtn{
        width:100px;
        height:35px;
        border:1px solid #666666;
        text-align: center;
    }
    .btnnodiv{
        margin-left:1vw;
    }
    .btnno{
        width:50px;
        height:35px;
        border:1px solid #666666;
        text-align: center;
    }

    .commonmtop{
        margin-top:2vh;
    }
    .btnmargin{
        margin-top:1vh;
    }
    .btnstyle{
        width:100px;
        height:35px;
        background-color: #42B134;
        color:#FFFFFF;
        border-radius: 5px;
    }

    .checizuowei{
        margin-left:5vw;
    }
    .colorstyle{
        color:#F49200;
        font-weight: bolder;
    }
    .checileixing{
        margin-left:5vw;
    }
    .checimoney{
        margin-left:5vw;
    }
    .zwleixing{
        margin-top:0.1vh;
    }
    .cmtopzw{
        margin-top:2vh;
    }
    .cxinfo{
        margin-left:2vw;
    }
    .endcheci{
        margin-left:3vw;
    }
    .sleft{
        margin-left:1vw;
    }
    .scheci{
        margin-left:2vw;

    }
    .startzhan{
        margin-left:1vw;
    }
    .scdiv{
        margin-top:2vh;
    }
    .sccdate{
        font-size:20px;
        font-weight: bolder;
        margin-left:1vw;
    }
    .haoshi{
        margin-left:3vw;
    }
    .tcontent{
        background-color:#F1F1F1;
        height:180px;
        margin-top:-1vh;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    .mleftd{
        margin-left:-2vw;
    }
    .querybtn{
        background-color:#42B134;
        color:#FFFFFF;
        border-radius: 5px;
        margin-left:1vw;
        width:100px;
    }
    .trainorderstyle{
        margin-left:1vw;
    }
    .formstyle{
        padding-top:2.2vh;
    }
    .container{

    }
    .header{
        font-size:12px;
    }
    .flex{
        display:flex;
    }
    .weiziicon{

    }
    .pleft{
        margin-left:0.5vw;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
    }
    .hc{
        background-color: #F1F1F1;
        height:130px;
        margin-top:2vh;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    .formstylejp{
        padding-top:2.2vh;
    }
    button:hover {background-color: #42B134;color:#FFFFFF;}
</style>