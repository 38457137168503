<template>
  <div class="order">
    <div class="left" v-if="active === 0">
      <!-- <el-button type="text"><router-link to="/home">返回首页</router-link></el-button> -->
      <div class="step" v-show="false">
        <el-card>
          <el-steps :active="active" finish-status="success">
            <el-step title="乘机信息"></el-step>
            <el-step title="增值服务"></el-step>
            <el-step title="支付"></el-step>
            <el-step title="完成"></el-step>
          </el-steps>
        </el-card>
      </div>

      <div class="wraning" v-if="false">
        <div>
          <i class="el-icon-warning" style="color: #ff7700"></i
          >&nbsp;您预订的产品不可使用港澳通行证预订。
        </div>
        <div>
          <i class="el-icon-warning" style="color: #ff7700"></i
          >&nbsp;该航班预计在扣款成功后20分钟内完成出票，保障出行。
        </div>
      </div>

      <div class="passengers">
        <div class="psg-passenger-bg"></div>
        <div class="content">
          <div class="title">
            <h2>乘机人</h2>
          </div>
          <div class="userList">
            <el-checkbox
              size="medium"
              v-model="checked1"
              label="张三"
              border
            ></el-checkbox>
            <el-checkbox
              size="medium"
              v-model="checked2"
              label="李四"
              border
            ></el-checkbox>
            <el-checkbox
              size="medium"
              v-model="checked3"
              label="王五"
              border
            ></el-checkbox>
          </div>
          <div
            class="desc"
            v-for="(item, index) in ruleForm.passengersList"
            :key="item.id"
          >
            <el-form ref="ruleForm" :model="ruleForm" label-width="10px">
              <el-row :gutter="20">
                <el-col :span="4">
                  <div class="num">{{ index + 1 }}</div>
                </el-col>
                <el-col :span="18">
                  <div class="from">
                    <div class="from">
                      <el-form-item
                        :prop="'passengersList.' + index + '.user'"
                        :rules="{
                          required: true,
                          message: '请填写登记证件姓名',
                          trigger: 'blur',
                        }"
                      >
                        <el-input
                          v-model="item.user"
                          placeholder="请与登记证件姓名保持一致"
                          clearable
                          size="small"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="" prop="id">
                        <el-input
                          size="small"
                          placeholder="请输入身份证"
                          v-model="item.id"
                        >
                          <template slot="prepend">身份证</template>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="" prop="tel">
                        <el-input
                          placeholder="请输入手机号码(必填)"
                          v-model="item.tel"
                          class="input-with-select"
                          size="small"
                        >
                          <template slot="prepend">中国86</template>
                        </el-input>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="delbtn">
                    <el-button
                      icon="el-icon-close"
                      type="text"
                      @click="remove(index)"
                      >删除</el-button
                    >
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="add" @click="addPassenger">
            <div>
              <i class="el-icon-circle-plus-outline"></i>&nbsp;新增乘机人
            </div>
          </div>
        </div>
      </div>

      <div class="contacts">
        <h3>联系人</h3>
        <el-card>
          <el-input
            placeholder="请输入手机号码(必填)"
            v-model="lianxiren"
            class="input-with-select"
            style="width: 502px"
            size="small"
          >
            <template slot="prepend">中国86</template>
          </el-input>
          <div class="warning">
            <i class="el-icon-warning"></i
            >&nbsp;订单信息将发送到此手机号，请确认信息正确
          </div>
        </el-card>
      </div>

      <div class="next">
        <el-button type="primary" @click="saveForm">下一步</el-button>
      </div>
    </div>
    <div class="left" v-else>
      订单已创建!
      <router-link to="/home">返回首页</router-link>
      <!-- <div class="next">
        <el-button type="primary" @click="saveForm">下一步</el-button>
      </div> -->
    </div>
    <!-- <div class="left" v-else-if="active === 2">
      支付
      <div class="next">
        <el-button type="primary" @click="saveForm">下一步</el-button>
      </div>
    </div>
    <div class="left" v-else>完成</div> -->
    <div class="right">
      <div class="picks">
        <div class="t1">05-11&nbsp;&nbsp;&nbsp;周六 武汉 上海</div>
        <div class="t2">
          <el-image
            style="width: 16px; height: 16px"
            :src="require('../assets/mu1.png')"
            fit="cover"
          ></el-image>
          东方航空 MU2503 波音738 经济舱
        </div>
        <div class="t3">
          <div class="l">
            <span>07:25</span>
            <span>天河国际机场T3</span>
          </div>
          <div class="m">1h30m</div>
          <div class="r">
            <span>08:55</span>
            <span>浦东国际机场T1</span>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="t4">
          <div class="line">
            <span
              >成人套餐<span class="abbr">退改¥78起</span
              ><span class="abbr">行李额</span></span
            >
            <span>¥304&nbsp;<span class="gray">x 1</span></span>
          </div>

          <div class="line">
            <span>机建</span>
            <span>¥50&nbsp;<span class="gray">x 1</span></span>
          </div>

          <div class="line">
            <span>燃油税</span>
            <span>¥40&nbsp;<span class="gray">x 1</span></span>
          </div>
        </div>
        <div class="t5">
          <!-- <el-tag size="mini" effect="dark" type="success">赠品</el-tag>
          订票即享
          <div class="gifs">
            <div class="line1">
              <span>租车600满减券</span>
              <span>免费</span>
            </div>
            <div class="line1">
              <span>赠接送机82折券</span>
              <span>免费</span>
            </div>
          </div> -->
        </div>
        <el-divider></el-divider>
        <div class="t6">¥394</div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookie from 'js-cookie';
export default {
  data () {
    return {
      active: 0,
      lianxiren: Cookie.get('user'),
      ruleForm: {
        passengersList: [
          {
            user: '张三111',
            id: 421300199909082333,
            tel: 13545679876,
          },
        ],
      },
      checked1: false,
      checked2: false,
      checked3: false,
      form: {
        user: null,
        id: null,
        tel: null,
      },
      select: '1',
      rules: {
        user: [
          { required: true, message: '请输入登记人姓名', trigger: 'blur' },
        ],
        id: [
          { required: true, message: '请输入身份证', trigger: 'blur' },
        ],
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
        ],
      }
    }
  },
  methods: {
    saveForm () {
      if (this.active++ > 2) this.active = 0;
      this.$refs["ruleForm"].validate((val) => {
        if (val) {
          console.log("符合要求，保存成功", this.ruleForm);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    remove (idx) {
      console.log(idx, this.ruleForm.passengersList);
      if (idx === 0) {
        return
      } else {
        this.ruleForm.passengersList.splice(idx, 1);
      }
    },
    addPassenger () {
      if (this.ruleForm.passengersList.length >= 9) {
        this.$message.warning('最多9位乘机人！');
        return
      } else {
        const obj = {
          user: '',
          id: '',
          tel: '',
        }
        this.ruleForm.passengersList.push(obj);
      }

    },
    generateRandom18Digits () {
      let randomNumber = '';
      for (let i = 0; i < 18; i++) {
        randomNumber += Math.floor(Math.random() * 10); // 生成0-9的随机数
      }
      return randomNumber;
    },
  },
  mounted () {
    const obj = this.$route.params
    if (Object.keys(obj).length !== 0) {
      console.log(obj, '预定传过来的参数');
    } else {
      this.$router.push('/home');
    }
  }
}
</script>
<style lang="less" scoped>
.order {
  width: 66%;
  display: flex;
  justify-content: space-between;

  .right {
    width: 28%;
    max-height: 520px;
    padding-bottom: 14px;
    border: solid #d6dde2;
    border-width: 0 1px 1px 1px;
    background: #f5f8f9;
    position: fixed;
    right: 100px;
    top: 60px;
    .picks {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding-top: 20px;
      .t1 {
        text-align: center;
        padding-bottom: 8px;
        font-size: 16px;
        font-weight: 700;
      }
      .t2 {
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
        color: #849bab;
        font-size: 12px;
        font-family: BlinkMacSystemFont, Helvetica, Arial, Tahoma, "PingFang SC",
          "Hiragino Sans GB", "Lantinghei SC", "Microsoft YaHei", sans-serif;
      }
      .t3 {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        .l,
        .r {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          span:nth-child(1) {
            line-height: 10px;
            font-size: 24px;
            font-weight: 500;
          }
          span:nth-child(2) {
            line-height: 40px;
            font-size: 12px;
            font-weight: 500;
          }
        }
        .r {
          align-items: flex-start;
        }
      }
      .t4 {
        padding: 0 35px;
        color: #234;
        font-size: 12px;
        .line {
          display: flex;
          justify-content: space-between;
          line-height: 30px;
          .abbr {
            margin-left: 15px;
            padding-bottom: 1px;
            color: #849bab;
            border-bottom: 1px dotted #849bab;
            cursor: help;
          }
          .gray {
            color: #849bab;
          }
        }
      }
      .t5 {
        font-size: 12px;
        padding: 10px 35px;
        .gifs {
          margin-top: 5px;
          color: #849bab;
          padding: 0 9px;
          border-left: 4px solid #b4c4d6;
          .line1 {
            line-height: 30px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
      .t6 {
        color: #ff7d13;
        font-size: 35px;
        font-weight: 700;
        font-family: BlinkMacSystemFont, Helvetica, Arial, Tahoma, "PingFang SC",
          "Hiragino Sans GB", "Lantinghei SC", "Microsoft YaHei", sans-serif;
        text-align: right;
        margin-right: 20px;
      }
    }
  }
  .step {
    width: 96%;
    margin-bottom: 20px;
    /deep/.el-card__body {
      padding: 0 10px;
      font-size: 12px;
    }
    /deep/.el-step__title {
      font-size: 12px;
    }
    .el-card {
      width: 100%;
      font-size: 14px;
    }
  }
  .left {
    width: 100%;
  }
  .wraning {
    padding: 0 20px;
    width: 92.3%;
    height: 65px;
    color: #6c8291;
    background: #fff9eb;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 3px;
    -webkit-box-shadow: -1px 2px 1px 0 #dee4e7;
    box-shadow: -1px 2px 1px 0 #dee4e7;
  }
  .passengers {
    width: 93%;
    background: #fff;
    padding: 22px 16px 25px;
    border-radius: 4px;
    box-shadow: 0 4px 16px 1px rgba(0, 0, 0, 0.06);
    position: relative;
    z-index: 5;
    .psg-passenger-bg {
      background: linear-gradient(-180deg, #e4f2ff 0%, #fff 100%);
      border-radius: 4px 4px 0 0;
      height: 100px;
      width: 99.5%;
      opacity: 0.5;
      position: absolute;
      top: 2px;
      left: 2px;
    }
    .content {
      position: relative;
      z-index: 5;
    }
    .title {
      h2 {
        font-size: 18px;
        font-weight: 500;
        color: #333;
      }
    }
    .desc {
      padding: 16px;
      margin-bottom: 10px;
      height: 200px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #dddddd;
      .num {
        height: 200px;
        border-right: 1px dashed #d6dde2;
        font-weight: bold;
        font-size: 34px;
        color: #ccd6e5;
        font-family: D-DIN-Bold, "Helvetica Neue", Tahoma, Arial,
          PingFangSC-Regular, "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
      }
      .from {
        margin-top: 20px;
        .el-input {
          width: 80%;
          /deep/ .el-input__inner {
            border-radius: 0%;
            border-top: none;
            border-right: none;
            border-left: none;
            font-family: "PingFangSC-Regular", "Helvetica Neue", Tahoma, Arial,
              PingFangSC-Regular, "Hiragino Sans GB", "Microsoft Yahei",
              sans-serif;
            color: #000;
            font-weight: bold;
            &::placeholder {
              font-weight: normal;
              font-size: 12px;
            }
          }
          /deep/.el-input-group__prepend {
            background: transparent;
            border: none;
          }
        }
      }
      .el-select .el-input {
        width: 130px;
      }
      .input-with-select .el-input-group__prepend {
        background-color: #fff;
      }
    }
    .userList {
      display: flex;
      flex-wrap: wrap;
      width: 750px;
      .el-checkbox {
        width: 110px;
        margin: 0 12px 5px 0;
      }
    }

    .add {
      cursor: pointer;
      width: 100%;
      div {
        color: #0086f6;
        font-size: 14px;
        margin: 25px 0 0 0;
        text-align: center;
      }
    }
  }
  .contacts {
    width: 96.6%;
    .warning {
      margin-top: 5px;
      color: #849bab;
    }
    /deep/ .el-input__inner {
      border-radius: 0%;
      border-top: none;
      border-right: none;
      border-left: none;
      font-family: "PingFangSC-Regular", "Helvetica Neue", Tahoma, Arial,
        PingFangSC-Regular, "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
      color: #000;
      font-weight: bold;
      &::placeholder {
        font-weight: normal;
        font-size: 12px;
      }
    }
    /deep/.el-input-group__prepend {
      background: transparent;
      border-top: none;
      border-right: none;
      border-left: none;
    }
  }
  .next {
    margin-top: 10px;
    width: 96.6%;
    .el-button--primary {
      width: 100%;
    }
  }
}
</style>