<template>
    <div class="orderlist">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="常旅客信息" name="first">
                <el-card>
                    <el-form
                            size="small"
                            :inline="true"
                            ref="form"
                            :model="form"
                            label-width="80px"
                    >
                        <el-form-item label="旅客姓名">
                            <el-input v-model="form.cnName" placeholder="中文名/英文名"></el-input>
                        </el-form-item>

                        <el-form-item label="">
                            <el-button type="primary">查询</el-button>
                            <el-button type="primary" @click="addpassenger">新增</el-button>
                        </el-form-item>
                    </el-form>

                    <div class="cc">
                        <el-table
                                :data='tableData1'
                                stripe
                                :border='true'
                                max-height='300px'
                        >
                            <el-table-column type='selection'></el-table-column>
                            <el-table-column width="150px" prop='name' label='姓名'></el-table-column>
                            <el-table-column width="150px" prop='phone' label='手机电话'></el-table-column>
                            <el-table-column width="150px" prop='certificatetype' label='证件类型'></el-table-column>
                            <el-table-column width="150px" prop='idnumber' label='证件号码'></el-table-column>
                            <el-table-column width="150px" prop='national' label='国籍(国家/地区)'></el-table-column>
                            <el-table-column width="50px" prop='sex' label='性别'></el-table-column>
                            <el-table-column width="250px" fixed='right' label='操作'>
                                <template slot-scope='scope'>
                                    <el-button @click='showName(scope.row)'>按钮</el-button>
                                    <el-button @click='removeCol(scope.row,scope.$index)'>移除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>

                    </div>
                </el-card>
            </el-tab-pane>

        </el-tabs>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                activeName: 'first',
                form: {
                    orderId: '',
                    name: '',
                    date1: '',
                    date2: '',
                    status: false,
                }
            };
        },
        methods: {
            //常旅客新增
            addpassenger(){

            },
            handleClick (tab, event) {
                console.log(tab, event);
            }
        }
    };
</script>
<style lang="less" scoped>
    .cc {
        height: 900px;
        overflow: auto;
    }
    .order-list {
        max-height: 190px;
        width: 98%;
        border: 1px solid #d9d9d9;
        margin-bottom: 20px;
        &:hover {
            border-color: #2577e3;
            background: #f7fbff;
        }
        .header {
            font-family: Tahoma;
            font-weight: 400;
            color: #333;
            padding-left: 10px;
            padding-top: 8px;
            height: 26px;
            background-color: #f6f6f6;
        }
        .content {
            cursor: pointer;
            padding-left: 30px;
            h3 {
                margin: 8px 0;
            }
            p {
                margin: 5px 0;
            }
        }
    }
</style>