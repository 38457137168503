<template>
  <div class="order222">
    <div class="menu">
      <div v-for="(itm, idx) in list" :key="idx" @click="handleClick(idx)" class="list">
        <p>{{ itm }}</p>
      </div>
    </div>

    <div class="tabs">
      <order-list v-if="defaultActive === 1" />
      <passenger v-else-if="defaultActive==2"></passenger>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="400px"
        class="demo-ruleForm"
        readonly
        v-else
      >
        <el-form-item label="手机" prop="tel">
          <el-input v-if="btnFlag" v-model="ruleForm.tel"></el-input>
          <span v-else>{{ ruleForm.tel }}</span>
        </el-form-item>
        <el-form-item label="邮箱" prop="mail">
          <el-input v-if="btnFlag" v-model="ruleForm.mail"></el-input>
          <span v-else>{{ ruleForm.mail }}</span>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-if="btnFlag" v-model="ruleForm.nickname"></el-input>
          <span v-else>{{ ruleForm.nickname }}</span>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-if="btnFlag" v-model="ruleForm.name"></el-input>
          <span v-else>{{ ruleForm.name }}</span>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-if="btnFlag" v-model="ruleForm.sex">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
          </el-radio-group>
          <span v-else>{{ ruleForm.sex }}</span>
        </el-form-item>
        <el-form-item label="生日" prop="birthday">
          <el-input v-if="btnFlag" v-model="ruleForm.birthday"></el-input>
          <span v-else>{{ ruleForm.birthday }}</span>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="btnFlag"
            type="primary"
            @click="submitForm('ruleForm')"
            >保存</el-button
          >
          <el-button v-else type="primary" @click="btnFlag = true"
            >编辑</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import orderList from '../components/orderList.vue'
import passenger from '../components/passenger.vue'
export default {
  components: {
    orderList,passenger
  },
  computed: {
    calcHeight () {
      return window.innerHeight - 66 + 'px';
    }
  },
  data () {
    return {
      list: [ '订单', '常旅客信息','个人中心'],
      defaultActive: 1,
      btnFlag: true,
      activeNames: ['1'],
      ruleForm: {
        tel: '',
        mail: '',
        nickname: '',
        name: '',
        sex: '男',
        birthday: '',
      },
      mockObj: {
        tel: '13357349978',
        mail: '23456@163.mail',
        nickname: '诩鲜',
        name: '张三',
        sex: '男',
        birthday: '1998-09-23',
      },
      rules: {
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
      }
    };
  },
  methods: {
    handleClick(idx){
      this.defaultActive = idx + 1;
    },
    handleSelect (index) {
      console.log(index);
      this.defaultActive = index;
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
          this.ruleForm = { ...this.mockObj };
          this.btnFlag = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style lang="less" scoped>
.order222 {
  background: #fff;
  display: flex;
  .menu{
    width: 20%;
    height: 600px;
    // background: forestgreen;
    text-align: center;
    .list {
      border: 1px solid #ccc;
      margin-bottom: 2px;
      cursor: pointer;
    }
  }
  .tabs{
    width: 79%;
    padding-left: 50px;
    .el-input {
      width: 70%;
    }
    .el-form {
      height: 900px;
      margin-top: 100px;
    }
  }
}
</style>