import { render, staticRenderFns } from "./Updatedept.vue?vue&type=template&id=77770a77&scoped=true"
import script from "./Updatedept.vue?vue&type=script&lang=js"
export * from "./Updatedept.vue?vue&type=script&lang=js"
import style0 from "./Updatedept.vue?vue&type=style&index=0&id=77770a77&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77770a77",
  null
  
)

export default component.exports