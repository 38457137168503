<template>
	<div class="cdiv">
		<div class="header flex">
			<div>
				<el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')" fit="cover"></el-image>
			</div>
			<div class="pleft">当前位置：<span>首页</span><span>>公告信息</span></div>
		</div>
		<div class="hc">
			<el-form size="small" :inline="true" ref="form" :model="form" label-width="80px" class="formstylegg">
				<el-form-item class="mttop mleftstyle" prop="titlename">
					<el-input style="width: 40vw; line-height: 37px" placeholder="请输入名称搜索" v-model="form.titlename" clearable></el-input>
				</el-form-item>
				<el-form-item class="mttop">
					<el-button class="savebtn" @click="searchgg" style="background-color: #42b134; color: #ffffff">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="heightstyle">
			<div class="ggc flex" v-for="(item, index) in listgg" :key="index" @click="forwardggdetails(item.gg_id)">
				<div class="ggcleft">
					{{ item.gg_title }}
				</div>
				<div class="ggcdate">发布时间：{{ item.gg_date.substr(0, 10) }}</div>
				<div class="ggccount">浏览次数：{{ item.gg_count }}</div>
			</div>
			<div v-if="listgg.length === 0" style="font-size: 16px; color: #666; text-align: center">暂无数据</div>
		</div>
		<div class="pager flex">
			<div class="shouye">
				<el-button :class="indexbtn ? 'cgbtn' : 'sybtn'" @click="forwardindex">首页</el-button>
			</div>
			<div class="btnnodiv flex" v-for="(item1, index1) in totalpages" :key="index1" @click="forwardpage(item1, index1)">
				<el-button v-if="btnflag == false" :class="isActive == item1 ? 'defaultbtn' : 'btnno'">{{ item1 }}</el-button>
				<el-button class="btnno" v-else>{{ item1 }}</el-button>
			</div>

			<div class="shouye mleftmargin">
				<el-button :class="lastbtn ? 'cgbtn' : 'sybtn'" @click="forwardlast">末页</el-button>
			</div>
		</div>
		<div class="copyright">工业和信息化部备案管理系统网站 豫B1-20080191-28</div>
	</div>
</template>

<script>
import ElImage from "element-ui/packages/image/src/main";
import ElCard from "element-ui/packages/card/src/main";
import ElButton from "element-ui/packages/button/src/button";
import { querygg } from "../api/index";
export default {
	components: {
		ElCard,
		ElImage,
		ElButton,
	},
	data() {
		return {
			lastbtn: false,
			indexbtn: false,
			btnflag: false,
			isActive: 1,
			loading: false,
			listgg: [],
			totalpages: 0,
			pagesize: 10,
			form: {
				titlename: "",
				titlename2: "",
			},
		};
	},
	activated() {
		this.querygonggao(1, this.pagesize);
	},
	methods: {
		//转向公告详细页面
        forwardggdetails(idstr) {
        	const href = this.$router.resolve({
        		path: "/gonggaodetails",
        		query: { ggId: idstr },
        	}).href;
        	window.open(href, "_blank");
        },
		//转向首页
		forwardindex() {
			this.btnflag = true;
			this.indexbtn = true;
			this.lastbtn = false;
			this.querygonggao(1, this.pagesize);
		},
		//转向尾页
		forwardlast() {
			this.indexbtn = false;
			this.btnflag = true;
			this.lastbtn = true;
			this.querygonggao(this.totalpages, this.pagesize);
		},
		//转向跳转页数
		forwardpage(page, index1) {
			this.lastbtn = false;
			this.btnflag = false;
			this.indexbtn = false;
			this.isActive = page;
			if (page > this.totalpages) {
				this.$message.warning("当前页应小于总页数");
			} else {
				this.querygonggao(page, this.pagesize);
			}
		},
		//搜索公告信息
		searchgg() {
			const titlenamestr = this.form.titlename;
			if (undefined == titlenamestr || null == titlenamestr) {
				titlenamestr = "";
			}
			this.form.titlename2 = titlenamestr;
			this.querygonggao(1);
		},
		//查询公告信息
		querygonggao(curpagestr) {
			const titlenamestr = this.form.titlename2;
			const params = {
				page: curpagestr,
				search: titlenamestr,
			};
			this.loading = true;
			querygg(params).then((res) => {
				this.loading = false;
				this.listgg = res.data.data.data;
				this.totalpages = res.data.data.pages;
			});
		},
	},
	name: "gonggaolist-right",
};
</script>

<style scoped>
.heightstyle {
	min-height: 45vh;
}
.searchinfo .el-input__inner {
}
.searchinfo .el-input {
	height: 40vh;
}
.weiziiconst {
	width: 24px;
	height: 24px;
}
.formstylegg {
	padding-top: 2vh;
	background-color: #ffffff;
}
.formstylegg .el-input__inner {
	height: 45px;
	line-height: 45px;
	background-color: rgba(241, 241, 241, 1);
}

.cdiv {
}
.copyright {
	width: 63vw;
	font-size: 12px;
	text-align: center;
	margin-top: 5vh;
	color: #b6b6b6;
}
.pager {
	height: 35px;
	margin-top: 5vh;
	margin-left: 0vw;
	display: flex;
	justify-content: center;
}
.shouye {
}
.cgbtn {
	width: 100px;
	height: 35px;
	background-color: #42b134;
	color: #ffffff;
	border: 1px solid #666666;
	text-align: center;
}
.sybtn {
	width: 100px;
	height: 35px;
	border: 1px solid #666666;
	text-align: center;
}
.btnnodiv {
	width: 50px;
	height: 35px;
	margin-left: 1vw;
}
.btnno {
	width: 50px;
	height: 35px;
	border: 1px solid #666666;
	text-align: center;
}

.cpgcontent {
	margin-top: 1vh;
}
.operbtn {
	margin-left: 8vw;
	color: #999999;
	font-size: 14px;
}
.checimoney {
	margin-left: 5vw;
	font-size: 14px;
}
.scheci {
	margin-left: 1vw;
	font-size: 16px;
	width: 450px;
}
.commonmtop {
	margin-top: 2vh;
}
.tcontent {
	background-color: #f1f1f1;
	height: 80px;
	margin-top: 1vh;
	align-items: center;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}
.savebtn {
	width: 200px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	background-color: #42b134;
	color: #ffffff;
	text-align: center;
	padding-top: 11px;
	padding-bottom: 11px;
	padding-left: 10px;
	padding-right: 10px;
}
.hc {
	margin-top: 2vh;
}
.container {
}
.mleftstyle {
	margin-left: 5vw;
}
.searchwidth {
	height: 30px;
	padding: 5px;
}
.header {
	font-size: 12px;
}
.flex {
	display: flex;
}
.weiziicon {
}
.pleft {
	margin-left: 0.5vw;
	font-family: MicrosoftYaHei;
	font-weight: 400;
	font-size: 16px;
	color: #000000;
}
.mleftmargin {
	margin-left: 1vw;
}
button:hover {
	background-color: #42b134;
	color: #ffffff;
}
.defaultbtn {
	background-color: #42b134;
	color: #ffffff;
}
.ggc {
	background-color: #f1f1f1;
	margin-left: 0px;
	width: calc(100% - 0px - 0px) !important;
	height: 8vh;
	line-height: 8vh;
	vertical-align: middle;
	font-size: 13px;
	margin-top: 1vh;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	padding: 0 20px;
	box-sizing: border-box;
}

.ggcleft {
	padding-left: 1vw;
	font-family: MicrosoftYaHei;
	font-weight: 400;
	font-size: 16px;
	color: #000000;
	overflow: hidden;
	width: 68%;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ggcdate {
	color: #999999;
	font-family: MicrosoftYaHei;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
	width: 20%;
}
.ggccount {
	width: 12%;
	font-family: MicrosoftYaHei;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
}
</style>
