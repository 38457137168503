import axios from 'axios'
import Cookies from 'js-cookie';

const http = axios.create({
  baseURL: '/api',
  //baseURL: 'http://zdym.bjcaicheng.cn/api', // 通用的请求地址前缀
  // baseURL: 'http://jhkadmin.3.16448.cn/api', // 通用的请求地址前缀
  timeout: 600000, // 超时时间(毫秒)
  headers:{
      'Content-Type':'application/x-www-form-urlencoded'
  }
})
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么 接入登录放开
  // const token = Cookies.get('token');
  // const headers = config.headers;
  // if(!headers.Authorization && token) {
  //   headers.Authorization = token
  // }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default http;