<template>
    <div class="commuter">
        <div class="hc">
            <div class="btnoper flex">
                <div>
                    <el-button class="sfsbbtn btnsinfo" @click="gotosfzshibie">身份证识别</el-button>
                </div>
                <div>
                    <el-button class="sfsbbtn btnsinfo" @click="gotogatxzshibie">护照港澳台识别</el-button>
                </div>
                <div v-if="userRoleInfo.role_id == 2">
                    <el-button class="sfsbbtn btnsinfo" @click="gotocompanyperson">本公司人员</el-button>
                </div>
                <div>
                    <el-button class="sfsbbtn btnsinfo" @click="gotopassenger">常用乘车人</el-button>
                </div>
                <div>
                    <el-button class="sfsbbtn btnsinfo" @click="gotowenbenshibie">文本识别</el-button>
                </div>
                <div>
                    <el-button class="sfsbbtn btnsinfo" @click="importDialogVisible = true">文件导入</el-button>
                </div>
            </div>
        </div>
        <div v-for="(item, index) in ruleForm.passengersList" :key="index">
            <div class="pginfo flex">
                <div class="xuhao">
                    {{ index + 1 }}
                </div>
                <div class="pginfotitle">乘客信息</div>
                <div class="pgdel" @click="remove(index)">【删除】</div>
            </div>
            <div class="hcpg">
                <el-form size="small" :inline="true" label-width="80px" class="formstyleyd" ref="ruleForm"
                    :model="ruleForm">
                    <el-col :span="8">
                        <el-form-item label="人员类型" class="mttop" prop="sendcity">
                            <el-select class="inputstyle" v-model="item.rytype" placeholder="选择人员类型">
                                <el-option label="成人" value="1"></el-option>
                                <el-option label="儿童" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="姓名" class="mttop fsizestyle" prop="sendcity">
                            <el-input class="inputstyle" placeholder="请输入" v-model="item.user" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="证件类型" class="mttop" prop="sendcity">
                            <el-select class="inputstyle" v-model="item.idtype" placeholder="选择证件类型">
                                <el-option label="身份证" value="1"></el-option>
                                <el-option label="护照" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form>
                <el-form size="small" :inline="true" ref="form" class="formstyleyd" :model="form" label-width="80px">
                    <el-col :span="8">
                        <el-form-item label="席座" class="" prop="sendcity" v-if="type === '1'">
                            <el-select class="inputstyle" v-model="item.zuoxi" placeholder="选择席座">
                                <el-option v-for="item in xizuoList" :label="item.label" :key="item.value"
                                    :value="item.value"></el-option>
                                <!-- 1 无座 2 二等座 3 一等座 4 硬卧 5 软卧 6 硬座 7 商务座 -->
                            </el-select>
                        </el-form-item>
                        <el-form-item label="席座" class="" prop="sendcity" v-if="type === '2'">
                            <el-select class="inputstyle" v-model="item.zuoxi" placeholder="选择席座">
                                <el-option label="经济舱" value="经济舱"></el-option>
                                <el-option label="头等舱" value="头等舱"></el-option>
                                <el-option label="公务舱" value="公务舱"></el-option>
                                <el-option label="商务舱" value="商务舱"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="证件号码" class="fsizestyle" prop="id">
                            <el-input class="inputstyle" placeholder="请输入" v-model="item.id" clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-form>

                <el-form size="small" :inline="true" ref="form" :model="form" label-width="80px">
                    <el-col :span="24">
                        <el-form-item label="购买保险" class="fsizestyle" prop="isbaoxian">
                            <el-checkbox class="all_t" v-model="item.isbaoxian">需要</el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-form>
            </div>
        </div>
        <div class="addpg">
            <el-button class="pgbtn btnsinfo" @click="addPassenger">新增乘客</el-button>
        </div>
        <el-dialog title="常用乘车人" v-show="dialogcypgVisible" :visible="dialogcypgVisible" width="954px" top="80px"
            :before-close="handleClosecypg" class="tpinfo">
            <el-card class="box-card">
                <el-form ref="form" :rules="rules" :model="form" label-width="150px">
                    <el-row :gutter="24" v-if="showPersonSelect">
                        <el-col :span="8" v-for="item in companycyList" :key="item.id">
                            <div class="pgdivinfo">
                                <div class="pgopertop flex">
                                    <div class="pgsinfo">
                                        <el-checkbox class="pgck"
                                            @change="(v) => handleCheckAllChangePassengerinfo(v, item)">{{ item.xingming
                                            }}</el-checkbox>
                                    </div>
                                    <div class="pgkuang">
                                        {{ item.person_type === "1" ? "成人" : "儿童" }}
                                    </div>
                                    <div class="sfz">身份证</div>
                                    <div class="editicon">
                                        <el-image class="editimgs" :src="require('../assets/img/editimg.png')"
                                            fit="cover"></el-image>
                                    </div>
                                </div>
                                <div class="pgcardid">{{ item.id_number }}</div>
                            </div>
                        </el-col>
                        <el-empty v-if="companycyList.length == 0" description="无人员"></el-empty>
                    </el-row>

                    <div class="pgbtns">
                        <el-button class="login-btn confirmbtn" type="primary" @click="confirmPerson"
                            style="background-color: rgba(66, 177, 52, 1)" :loading="logLoading">确认</el-button>
                    </div>
                </el-form>
            </el-card>
        </el-dialog>

        <el-dialog title="本公司人员" v-show="dialogbgsryVisible" :visible="dialogbgsryVisible" width="954px" top="80px"
            :before-close="handleClosebgsry" class="tpinfo">
            <el-card class="box-card">
                <el-form ref="form" :rules="rules" :model="form" label-width="150px">
                    <el-row :gutter="24" v-if="showPersonSelect">
                        <el-col :span="8" v-for="item in companyList" :key="item.id">
                            <div class="pgdivinfo">
                                <div class="pgopertop flex">
                                    <div class="pgsinfo">
                                        <el-checkbox class="pgck"
                                            @change="(v) => handleCheckAllChangePassengerinfo(v, item)">{{ item.xingming
                                            }}</el-checkbox>
                                    </div>
                                    <div class="pgkuang">
                                        {{ item.person_type === "1" ? "成人" : "儿童" }}
                                    </div>
                                    <div class="sfz">身份证</div>
                                    <div class="editicon">
                                        <el-image class="editimgs" :src="require('../assets/img/editimg.png')"
                                            fit="cover"></el-image>
                                    </div>
                                </div>
                                <div class="pgcardid">{{ item.id_number }}</div>
                            </div>
                        </el-col>

                        <el-empty v-if="companyList.length == 0" description="无人员"></el-empty>
                    </el-row>
                    <el-pagination
                        v-if="companyListpage > 0"
                        layout="prev, pager, next"
                        :page-count="companyListpage"
                        @current-change="companyListpageChange"
                    >
                    </el-pagination>
                    <div class="pgbtns">
                        <el-button class="login-btn confirmbtn" type="primary" @click="confirmPerson"
                            style="background-color: rgba(66, 177, 52, 1)" :loading="logLoading">确认</el-button>
                    </div>
                </el-form>
            </el-card>
        </el-dialog>
        <el-dialog title="身份证识别" v-show="dialogsfzVisible" :visible="dialogsfzVisible" width="30%" top="80px"
            :before-close="handleCloseLoginsfz" class="tpinfo">
            <el-card class="box-card">
                <el-form ref="form" :rules="rules" :model="form" label-width="150px">
                    <el-form-item>
                        <span slot="label">
                            <span class="spanstyle">上传身份证正面</span>
                        </span>
                        <el-upload ref="upload" :show-file-list="false" :data="{id_card_type:1}" name="id_card_image" :fileList="fileList" :on-success="handleSuccess" :auto-upload="false" :on-change="cardChange"
                            action="http://jhkadmin.3.16448.cn/api/common/uploadIdCard" list-type="picture-card" :limit="1">
                            <i class="el-icon-plus"></i>
                            <img :src="cardForm.id_card_image" alt="" v-show="cardForm.id_card_image">
                        </el-upload>
                    </el-form-item>
                    <el-form-item class="confirmbtns">
                        <el-button class="login-btn cbtns" type="button" style="background-color: rgba(66, 177, 52, 1)" @click="uploadIdCard"
                            :loading="logLoading">确定</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
        <el-dialog title="护照港澳通行证识别" v-show="dialoggatxzVisible" :visible="dialoggatxzVisible" width="35%" top="80px"
            :before-close="handleCloseLogingatxz" class="tpinfo">
            <el-card class="box-card">
                <el-form ref="form" :rules="rules" :model="form" label-width="200px">
                    <el-form-item>
                        <span slot="label">
                            <span class="spanstyle">上传护照港澳通行证正面</span>
                        </span>
                        <el-upload ref="upload2" :show-file-list="false" :data="{id_card_type:2}" name="id_card_image" :fileList="fileList" :on-success="handleSuccessGat" :auto-upload="false" :on-change="cardChange"
                            action="http://jhkadmin.3.16448.cn/api/common/uploadIdCard" list-type="picture-card" :limit="1">
                            <i class="el-icon-plus"></i>
                            <img :src="cardForm.id_card_image" alt="" v-show="cardForm.id_card_image">
                        </el-upload>
                    </el-form-item>
                    <el-form-item class="confirmbtns">
                        <el-button class="login-btn cbtns" type="primary" style="background-color: rgba(66, 177, 52, 1)" @click="uploadIdCard2"
                            :loading="logLoading">确定</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-dialog>
        <el-dialog title="文本识别" v-show="dialogVisible" :visible="dialogVisible" width="600px" top="80px"
            :before-close="handleCloseLogin" class="tpinfo">
            <el-card class="box-card">
                <el-input type="textarea" ref="textareaRef" class="wenzi-textarea" :rows="6"
                    v-model="textareaValue"></el-input>
                <div class="btn-row">
                    <el-button class="login-btn cbtns" :loading="logLoading" @click="handleCloseLogin">取消</el-button>
                    <el-button class="login-btn cbtns" type="primary" style="background-color: rgba(66, 177, 52, 1)"
                        :loading="logLoading" @click="handleSuccessWenzi">快速添加</el-button>
                </div>
            </el-card>
        </el-dialog>
        <el-dialog title="请选择导入表格" :visible.sync="importDialogVisible" width="550px">
          <el-upload
            class="upload-template"
            ref="upload"
            action=""
            :limit="1"
            accept=".xls, .xlsx"
            :on-change="uploadLimitMsg"
            :file-list="importFileList"
            :on-exceed="uploadLimitMsg"
            :on-success="uploadSuccess"
            :on-error="uploadError"
            :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            <span slot="tip" class="el-upload__tip">只能上传.xls, .xlsx文件</span>
          </el-upload>
          <div slot="footer" class="dialog-footer">
            <el-button @click="importDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitUpload">确 定</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
import ElCard from "element-ui/packages/card/src/main";
import ElButton from "element-ui/packages/button/src/button";
import ElCheckbox from "element-ui/packages/checkbox/src/checkbox";
import Cookie from "js-cookie";
import { queryPassengerlist,cardidshibie,textrecognition,getUserInfo } from "@/api";
export default {
    name: "CommuterVue",
    components: {
        ElButton,
        ElCard,
        ElCheckbox,
    },
    props: {
        type: {
            type: String,
            default: "1" // 1 火车票  2  飞机票
        }
    },
    activated() {
        this.ruleForm.passengersList = [
            {
                rytype: "",
                user: "",
                idtype: "",
                id: "",
                zuoxi: this.$route.query.seatTypeName,
                isbaoxian: "",
            }
        ],
        this.seat = this.$route.query.seat ? this.$route.query.seat.split('-') : [];
        this.seatTypeName = this.$route.query.seatTypeName;
        this.handleCloseLogin()
        this.handleCloseLogingatxz()
        this.handleCloseLoginsfz()
        this.handleClosebgsry()
        this.handleClosecypg();
        const that = this;
        const userinfo = JSON.parse(Cookie.get("userinfo"));
        getUserInfo({user_id: userinfo.id}).then(res => {
            if(res.data.code == 1) {                
                that.userRoleInfo = res.data.data
                console.log('----------', this.userRoleInfo)
            }
        })
    },
    computed: {
        xizuoList() {
            //  <!-- 1 无座 2 二等座 3 一等座 4 硬卧 5 软卧 6 硬座 7 商务座 -->
            return [
                { label: '无座', value: '无座' },
                { label: '二等座', value: '二等座' },
                { label: '一等座', value: '一等座' },
                { label: '硬卧', value: '硬卧' },
                { label: '软卧', value: '软卧' },
                { label: '硬座', value: '硬座' },
                { label: '商务座', value: '商务座' },
            ].filter(item => this.seat.includes(item.label))
        }
    },
    data() {
        return {
            cardImg:"",
            pgname: "",
            dialogbgsryVisible: false,
            dialogcypgVisible: false,
            dialoggatxzVisible: false,
            dialogsfzVisible: false,
            logLoading: false,
            dialogVisible: false,
            textareaValue: "",
            form: {
                name: "",
                region: "",
                date1: "",
                date2: "",
                delivery: false,
                type: [],
                resource: "",
                desc: "",
            },
            ruleForm: {
                passengersList: [
                    {
                        rytype: "",
                        user: "",
                        idtype: "",
                        id: "",
                        zuoxi: "",
                        isbaoxian: "",
                    },
                ],
            },
            rules: {
                mobilephone: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确", trigger: "blur" },
                ],

                pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
            },
            fileList: [],
            companyList: [],
            companycyList: [],
            defaultUserList: [],
            checkList: [],
            showPersonSelect: false,
            seat: [],
            cardForm:{
                id_card_image:"",
                id_card_side:"",
                id_card_type:"",
            },
            pagination: {
                curpage: 1,
                pagesize: 10,
                total: 0,
            },
            uploadLoading:false,
            companyListpage:0,            
            importDialogVisible: false,
            importFileList: [],
            userRoleInfo: {},
        };
    },
    created() {
        this.getCompanyUserList();
    },
    methods: {
        submitUpload() {
            this.$refs.upload.submit();
        },
        uploadLimitMsg(files, fileList) {
            if(fileList.length > 1) {
                this.$message.warning("只可选择一个文件!");
                this.importFileList.prop();
            }
        },
        uploadSuccess() {
            this.$message.success("上传成功!");
            this.importDialogVisible = false;
        },
        uploadError() {
            this.$message.error("上传失败!");
        },
        cardChange(file, fileList){
            if(fileList.length!=0){
                this.cardForm.id_card_image=file.url;
            }
        },
        uploadIdCard(){
            this.$refs.upload.submit();
            this.uploadLoading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        uploadIdCard2(){
            this.$refs.upload2.submit();
            this.uploadLoading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        getCompanyUserList() {
            const userinfo =JSON.parse(Cookie.get('userinfo'));
			console.log(userinfo)
            const params = {
                page: this.pagination.curpage,
                user_id:userinfo.id
            }
            if (userinfo) {
                queryPassengerlist({...params,type: 1}).then(({ data, status }) => {
                    // console.log("%c Line:547 ?? status", "color:#7f2b82", status);
                    if (status === 200&&data.code==1) {
                        this.companyList = data.data.data;
                        this.companyListpage = data.data.pages
                    }
                    console.log("%c Line:541 ?? rs", "color:#4fff4B", data);
                });
                queryPassengerlist({...params,type: 2}).then(({ data, status }) => {
                    // console.log("%c Line:547 ?? status", "color:#7f2b82", status);
                    if (status === 200&&data.code==1) {
                        this.companycyList = data.data.data;
                    }
                    console.log("%c Line:541 ?? rs", "color:#4fff4B", data);
                });
            }
        },
        companyListpageChange(page){
            const userinfo =JSON.parse(Cookie.get('userinfo'));
            const params = {
                page: page,
                user_id:userinfo.id
            }
            if (userinfo) {
                queryPassengerlist({...params,type: 1}).then(({ data, status }) => {
                    // console.log("%c Line:547 ?? status", "color:#7f2b82", status);
                    if (status === 200&&data.code==1) {
                        this.companyList = data.data.data;
                        this.companyListpage = data.data.pages
                    }
                    console.log("%c Line:541 ?? rs", "color:#4fff4B", data);
                });
            }
        },
        //本公司人员
        gotocompanyperson() {
            this.dialogbgsryVisible = true;
            this.showPersonSelect = true;
        },
        handleClosebgsry() {
            this.dialogbgsryVisible = false;
            this.showPersonSelect = false;
        },
        //选择
        handleCheckAllChangePassengerinfo(...args) {
            if (args[0]) {
                this.checkList.push(args[1]);
            } else {
                this.checkList.splice(this.checkList.indexOf(args[1]), 1);
            }
        },
        confirmPerson() {
            var _this=this;
            this.checkList.map((item) => {
                const data = {
                    rytype: item.person_type,
                    user: item.xingming,
                    idtype: item.id_type,
                    id: item.id_number,
                    zuoxi: _this.seatTypeName,
                    isbaoxian: "",
                };
                this.addData(data);
            })
            this.handleClosecypg();
            this.handleClosebgsry();
            this.checkList = [];
        },
        //关闭常用乘车人
        handleClosecypg() {
            this.showPersonSelect = false;
            this.dialogcypgVisible = false;
            // this.$refs.form.resetFields();
            // this.$store.commit("collapseFuntchose");
        },
        //常用乘车人弹窗
        gotopassenger() {
            this.dialogcypgVisible = true;
            this.showPersonSelect = true;
        },
        //护照港澳通行证关闭弹窗
        handleCloseLogingatxz() {
            this.dialoggatxzVisible = false;
            this.cardForm={
                id_card_image:"",
                id_card_side:"",
                id_card_type:"",
            }
            // this.$refs.form.resetFields();
            this.$store.commit("collapseFuntchose");
        },
        //护照港澳通行证识别
        gotogatxzshibie() {
            this.dialoggatxzVisible = true;
        },
        //身份证识别
        gotosfzshibie() {
            this.dialogsfzVisible = true;
        },
        //身份证识别关闭
        handleCloseLoginsfz() {
            this.dialogsfzVisible = false;
            this.cardForm={
                id_card_image:"",
                id_card_side:"",
                id_card_type:"",
            }
            // this.$refs.form.resetFields();
            this.$store.commit("collapseFuntchose");
        },
        //清除方法
        gotoclearBtn() {
            this.pgshow = false;
        },
        //删除乘客
        remove(idx) {
            if (
                this.ruleForm.passengersList.length === 1
            ) {
                this.$message.warning("至少要有一位乘客信息！");
                return;
            } else {
                this.ruleForm.passengersList.splice(idx, 1);
            }
        },
        //新增乘客
        addPassenger() {
            // if (this.ruleForm.passengersList.length >= 9) {
            //     this.$message.warning('最多9位乘机人！');
            //     return
            // } else {
            const obj = {
                rytype: "",
                user: "",
                idtype: "",
                id: "",
                zuoxi: this.seatTypeName,
                isbaoxian: "",
            };
            this.ruleForm.passengersList.push(obj);
            // }
        },
        //文本识别
        gotowenbenshibie() {
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.textareaRef.focus();
            })
        },

        handleCloseLogin() {
            this.dialogVisible = false;
            this.textareaValue = ""
        },
        //转向预定申请
        forwardyuding() {
            this.$router.push("/trainyudingshenqing");
        },
        getAgeFromIdCard(idCard) {
            if (!idCard || idCard.length !== 18) {
                throw new Error('Invalid ID card number');
            }

            const today = new Date();
            const birthDateString = idCard.substring(6, 14);
            const birthDate = new Date(
                birthDateString.substring(0, 4), // year
                parseInt(birthDateString.substring(4, 6)) - 1, // month (zero-based)
                birthDateString.substring(6, 8) // day
            );

            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            return age;
        },
        addData(data) {
            const emptyIndex = this.ruleForm.passengersList.findIndex(item => {
                return Object.keys(item).every(key => !item[key])
            });
            if(this.ruleForm.passengersList.length==1&&!this.ruleForm.passengersList[0].user&&!this.ruleForm.passengersList[0].id){
                this.ruleForm.passengersList=[data]
            }else if (emptyIndex !== -1) {
                this.ruleForm.passengersList.splice(emptyIndex, 1, data);
            }
            else {
                this.ruleForm.passengersList.push(data)
            }
        },
        handleSuccess(res, file) {
            this.uploadLoading.close();
            const { code,data,msg } = res;
            var _this=this;
            if(code==1){
                if(data.words_result["姓名"]||data.words_result["公民身份号码"]){
                    var validateWenzi=_this.validateWenzi(_this.ruleForm.passengersList[0])
                    var obj=_this.idcardInfo(data.words_result["公民身份号码"]?data.words_result["公民身份号码"].words:"")
                    if(_this.ruleForm.passengersList.length==1&&validateWenzi){
                        _this.ruleForm.passengersList=[{
                            rytype: obj.rytype,
                            user: data.words_result["姓名"]?data.words_result["姓名"].words:"",
                            idtype: '1',
                            id: data.words_result["公民身份号码"]?data.words_result["公民身份号码"].words:"",
                            zuoxi: _this.seatTypeName,
                            isbaoxian: "",
                        }];
                    }else{
                        _this.ruleForm.passengersList.push({
                            rytype: obj.rytype,
                            user: data.words_result["姓名"]?data.words_result["姓名"].words:"",
                            idtype: '1',
                            id: data.words_result["公民身份号码"]?data.words_result["公民身份号码"].words:"",
                            zuoxi: _this.seatTypeName,
                            isbaoxian: "",
                        });
                    }
                }else{
                    this.$message.error("无识别身份内容")
                }
            }else{
                this.$message.error(msg)
            }
            this.fileList = [];
            this.$refs.upload.clearFiles()
            this.handleCloseLoginsfz()
            // try {
            //     const result = JSON.parse(data.body);
            //     const { words_result } = result;
            //     const _data = {
            //         user: words_result['姓名'] ? words_result['姓名'].words : '',
            //         idtype: '1',
            //         id: words_result['公民身份号码'] ? words_result['公民身份号码'].words : '',
            //         rytype: '1',
            //         zuoxi: "",
            //         isbaoxian: false,
            //     }
            //     this.addData(_data)
            //     this.handleCloseLoginsfz()
            //     this.$message.success('识别成功')
            // } catch (error) {
            //     console.log("%c Line:409 ??", "color:#465975", error.message);
            // }
        },
        handleSuccessGat(res, file) {
            this.uploadLoading.close();
            const { code,data,msg } = res;
            var _this=this;
            if(code==1){
                if(data.words_result["护照号"]){
                    var validateWenzi=_this.validateWenzi(_this.ruleForm.passengersList[0])
                    console.log(data.words_result["出生日期"][0])
                    var obj=_this.idcardInfo2(data.words_result["出生日期"]?data.words_result["出生日期"][0].word:"")
                    if(_this.ruleForm.passengersList.length==1&&validateWenzi){
                        _this.ruleForm.passengersList=[{
                            rytype: obj.rytype,
                            user: data.words_result["姓名"]?data.words_result["姓名"][0].word:data.words_result["姓名拼音"]?data.words_result["姓名拼音"][0].word:"",
                            idtype: '2',
                            id: data.words_result["护照号"]?data.words_result["护照号"][0].word:"",
                            zuoxi: _this.seatTypeName,
                            isbaoxian: "",
                        }];
                    }else{
                        _this.ruleForm.passengersList.push({
                            rytype: obj.rytype,
                            user: data.words_result["姓名"]?data.words_result["姓名"][0].word:data.words_result["姓名拼音"]?data.words_result["姓名拼音"][0].word:"",
                            idtype: '2',
                            id: data.words_result["护照号"]?data.words_result["护照号"][0].word:"",
                            zuoxi: _this.seatTypeName,
                            isbaoxian: "",
                        });
                    }
                }else{
                    this.$message.error("无识别身份内容")
                }
            }else{
                this.$message.error(msg)
            }
            this.fileList = [];
            this.$refs.upload2.clearFiles()
            this.handleCloseLogingatxz()
        },
        validateSfz(value) {
            if (!value || value.length !== 18) {
                // this.$message.error('请输入正确的身份证号');
                return false
            }
            return true
        },
        validateWenzi(obj){
            // for (let key in obj) {
            //     if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
            //         return false; // 发现非空值，返回false
            //     }
            // }
            if(obj.user||obj.id){
                return false;
            }
            return true;
        },
        handleSuccessWenzi() {
            
            var _this=this;
            if(!_this.textareaValue){
                return
            }
            textrecognition({textcontent:_this.textareaValue}).then(({ data, status }) => {
                if(data.code==1){
                    data.data.forEach(function(item,index){
                        var validateWenzi=_this.validateWenzi(_this.ruleForm.passengersList[0])
                        var obj=_this.idcardInfo(item.id)
                        console.log(obj)
                        if(_this.ruleForm.passengersList.length==1&&validateWenzi){
                            _this.ruleForm.passengersList=[{
                                rytype: obj.rytype,
                                user: item.name,
                                idtype: obj.idtype,
                                id: item.id,
                                zuoxi: _this.seatTypeName,
                                isbaoxian: "",
                            }];
                        }else{
                            _this.ruleForm.passengersList.push({
                                rytype: obj.rytype,
                                user: item.name,
                                idtype: obj.idtype,
                                id: item.id,
                                zuoxi: _this.seatTypeName,
                                isbaoxian: "",
                            });
                        }
                    })
                }else{
                    this.$message.error(data.msg);
                    
                }
                console.log(data.code)
                // data.data
                // const obj = {
                //     rytype: "",
                //     user: "",
                //     idtype: "",
                //     id: "",
                //     zuoxi: "",
                //     isbaoxian: "",
                // };
                // this.ruleForm.passengersList.push(obj);
                _this.handleCloseLogin()
            });
            
        },
        idcardInfo(userCard){
            // // 身份证正则表达式
            // var re = /^\d{6}(((19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])\d{3}([0-9]|x|X))|(\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])\d{3}))$/;
            // if (!re.test(userCard)) {
            //     // 测试身份证是否正确
            //     console.log("身份证号码格式有误,请重新填写!");
            //     return false;
            // } else {
            //     console.log('身份证正确')
            // }
            var obj = {
                sex:'',
                age:'',
            }
            if(userCard.length==18){
                obj.idtype="1"
            }else{
                obj.idtype="2"
                return obj;
            }
            //获取出生日期
            obj.birth = userCard.substring(6, 10) + "-" + userCard.substring(10, 12) + "-" + userCard.substring(12, 14);
            //获取性别
            if (parseInt(userCard.substr(16, 1)) % 2 == 1) {
                obj.sex =  "男";
            } else {
                obj.sex =  "女";
            }
            //获取年龄
            var myDate = new Date();
            var month = myDate.getMonth() + 1;
            var day = myDate.getDate();
            var age = myDate.getFullYear() - userCard.substring(6, 10) - 1;
            if (userCard.substring(10, 12) < month || userCard.substring(10, 12) == month && userCard.substring(12, 14) <= day) {
                age++;
            }
            obj.age = age
            if(age>=18){
                obj.rytype="1"
            }else{
                obj.rytype="2"
            }
            return obj;
        },
        idcardInfo2(userCard){
            console.log(userCard)
            var obj = {
                sex:'',
                age:'',
            }
            obj.idtype="2"
            //获取出生日期
            obj.birth = userCard.substring(0, 4) + "-" + userCard.substring(4, 6) + "-" + userCard.substring(6, 8);
            //获取年龄
            var myDate = new Date();
            var month = myDate.getMonth() + 1;
            var day = myDate.getDate();
            var age = myDate.getFullYear() - userCard.substring(0, 4) - 1;
            if (userCard.substring(4, 6) < month || userCard.substring(4, 6) == month && userCard.substring(6, 8) <= day) {
                age++;
            }
            obj.age = age
            if(age>=18){
                obj.rytype="1"
            }else{
                obj.rytype="2"
            }
            return obj;
        },
    },
};
</script>

<style scoped>
/deep/ .el-upload{position: relative;}
/deep/ .el-upload img{position: absolute;width: 100%;height: 100%;left: 0;top: 0;}
.mttop{margin-top: 2vh;}
.flex {
    display: flex;
}

/* .addpg {
  margin-left: 20vw;
  margin-right: 20vw;
} */
.el-upload__tip {
    margin-left: 20px;
}
.upload-template {
    padding: 0 60px;
}
.xuhao {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
}

.sfsbbtn {
    border: 1px solid #000000;
    color: #000000;
    margin-left: 1vw;
}

.hc {
    background-color: #f1f1f1;
    height: 100px;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    align-items: center;
    display: flex;
}

.hcpg {
    background-color: #f1f1f1;
    height: 200px;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.formstyleyd .inputstyle {
    width: 207px;
}

.zjinputstyle {
    width: 192px;
}

.pgdivinfo {
    height: 93px;
    background: #f1f1f1;
    border-radius: 10px;
    margin-bottom: 20px;
}

.spanstyle {
    margin-top: 50px;
    display: inline-block;
}

.addpg {
    text-align: center;
    width: calc(88% - 0px - 10px) !important;
}

.pginfotitle {
    width: calc(100% - 0px - 70px) !important;
    font-weight: bolder;
    margin-left: 1vw;
}

.pginfo {
    margin-top: 2vh;
}

.pgdel {
    width: 70px;
}

.tpinfo /deep/ .el-dialog {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.tpinfo /deep/ .el-card {
    border: 1px solid #ffffff;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    -webkit-box-shadow: none;
}

.tpinfo /deep/ .el-card__body {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.tpinfo /deep/ .el-dialog__body {
    padding: 0;
    color: #606266;
    font-size: 14px;
    /*word-break: break-all;*/
}

.pgbtn {
    width: 350px;
    height: 45px;
    border: 1px solid #000000;
    color: #000000;
    padding: 10px;
    margin-top: 5vh;
}

.btnsinfo {
    background-color: rgba(255, 255, 255, 1);
}

.pgcardid {
    color: #999999;
    margin-left: 34px;
}

.editimgs {
    width: 19px;
    height: 17px;
    margin-left: 10px;
}

.pgopertop {
    align-items: center;
    height: 60px;
}

.pgkuang {
    width: 47px;
    height: 22px;
    line-height: 22px;
    border: 1px solid #b6b6b6;
    text-align: center;
    margin-left: 20px;
    color: #999999;
    font-family: MicrosoftYaHei;
    font-size: 12px;
    vertical-align: middle;
}

.sfz {
    font-family: MicrosoftYaHei;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    margin-left: 10px;
}

.pgck {
    color: #000000;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    font-size: 18px;

    padding-left: 10px;
}

.pgleftinfo {
    margin-left: 25px;
}

.pgmleft {}

.pgmargintop {
    margin-top: 20px;
}

.btnstylesinfo {
    margin-top: 30px;
    justify-content: center;
    display: flex;
    text-align: center;
}

.cbtns {
    padding-left: 80px;
    padding-right: 80px;
    text-align: center;
    /* color: #fff; */
    /* margin-left: -25px; */
}

.confirmbtns {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    justify-content: center;
}

.confirmbtn {
    width: calc(48% - 0px - 10px) !important;
    padding-left: 100px;
    padding-right: 100px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.pgbtns {
    margin-top: 20px;
    width: calc(100% - 0px - 0px) !important;
    justify-content: center;
    text-align: center;
}

.pgtop {
    margin-top: 10px;
}

.pgleft {
    margin-left: 10px;
}

.centered-label .el-form-item__label {
    display: flex;
    align-items: center;
    justify-content: center;
}

.clearbtn {
    background-color: rgba(150, 150, 150, 1);
    color: #ffffff;
}

.wenzi-textarea /deep/ .el-textarea__inner {
    border: none;
    outline: none;
}

.webit-textarea /deep/ .el-textarea__inner:focus {
    border: none;
    outline: none;
}

.btn-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
</style>
