<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')" fit="cover"></el-image>
            </div>
            <div class="pleft">
                当前位置：<span>首页</span><span>>机票订单</span><span>>机票订单详情</span>
            </div>
        </div>
        <div class="addpgstyle">
            订单状态：{{
            baseData.refundStatus === '3' ? "已退票" :
            baseData.cancelStatus === "5" ? "已取消" : ticketTypeDic[baseData.ticketStatus]
            }}
            <span v-if="baseData.danweiName!=null && baseData.danweiName!=''"> 所属单位：{{ baseData.danweiName}}</span>
        </div>
        <div class="tcontent">
            <div class="ttop flex">
                <div class="liushuino graycolor">
                    流水号：{{ baseData.liushuiNo }}
                </div>
                <div class="xiadandate graycolor">
                    下单时间：{{ baseData.createTime }}
                </div>

            </div>
            <template v-for="(item, index) in xcList">
                <div class="tcontentxc flex" :key="item.artoId">
                    <div class="scheci commonmtop">
                        <div class="sccdate flex">
                            <div class="startzhan">{{ item.sendCity }}</div>
                            <div class="iconstyles">
                                <el-image style="margin-left:1vw;" class="weiziicon"
                                          :src="require('../assets/img/shi.png')" fit="cover"></el-image>
                            </div>
                        </div>
                        <div class="scdiv flex startweight bluecolor">
                            {{ formatDate(item.sendDate) }}
                        </div>
                    </div>
                    <div class="haoshi commonmtop">
                        <div class="cxinfo">{{ item.flightNumber }}</div>
                        <div>
                            <el-image class="weiziicon lineicon" :src="require('../assets/img/xian.png')"
                                      fit="cover"></el-image>
                        </div>
                        <div class="sleft">
                            <span>起飞时间：{{ item.sendDate }}</span> {{ item.flightHours }}
                        </div>
                    </div>
                    <div class="endcheci commonmtop">
                        <div class="sccdate flex">
                            <div class="startzhan">{{ item.arrivalCity }}</div>
                            <div class="iconstyles">
                                <el-image style="margin-left:1vw;" class="weiziicon"
                                          :src="require('../assets/img/zhong.png')" fit="cover"></el-image>
                            </div>
                        </div>
                        <div class="scdiv flex startweight bluecolor">
                            {{ formatDate(item.arrivalDate) }}
                        </div>
                    </div>
                </div>
                <div class="hengxian linemargin" v-if="index !== xcList.length - 1" :key="item.artoId"></div>
            </template>
        </div>
        <div class="hcorder" style="background: #FBFBE3;">
            <div class="flex mleftinfo">
               <div class="divleft">
                   <div class="xuhao" style="margin-left:1.8vw;">
                      1
                   </div>
                   <div class="dltitle">
                       已改签
                   </div>
               </div>
                <div class="divright">
                    <template v-for="(item, index) in pgList">
                        <div :key="item.aorpgId + 'a'" class="flex mleftinfo">
                            <el-form size="small" :inline="true" ref="form" :model="form" label-width="100px"
                                     class="formstyletrainord">
                                <el-form-item class="fmtop fsizestyle row-name" prop="sendcity">
                                    {{
                                    item.xinming
                                    }}
                                </el-form-item>
                                <el-form-item class="fmtop fsizestyle" prop="sendcity">
                            <span class="cstyle">{{
                                item.renyuanLeixing === "1" ? "成人" : "儿童"
                            }}</span>
                                    <span class="cstyle">{{ item.cardType === "1" ? "身份证" : "护照" }}：{{ item.cardId }}</span>
                                    <!-- <span class="cstyle">{{ typeDic[item.xiwei] }} {{ item.zuowei }}</span> -->
                                    <span class="cstyle"> 订单号：{{ item.liushuiNo }} </span>
                                    <span class="cstyle"> 座位号：15B </span>
                                </el-form-item>
                                <el-form-item class="fmtop fsizestyle" prop="sendcity">
                            <span class="cstyle" v-if="item.isBuyBaoxian === '1'">
                                <el-image class="imgmargin" :src="require('../assets/img/bao.png')"
                                          fit="cover"></el-image>
                            </span>

                                </el-form-item>


                            </el-form>

                        </div>
                        <div class="tpstylegqtwo">
                            改签流水号：35723102
                        </div>
                        <div class="tpstylegq">
                            改签原因：这里显示具体改签时间
                        </div>
                        <!--<div :key="item.aorpgId + 'b'" v-if="index !== pgList.length - 1" class="hengxian"></div>-->

                    </template>
                </div>
            </div>

        </div>
        <div class="hcorder">
            <div class="flex mleftinfo">
                <div class="divleft">
                    <div class="xuhao" style="margin-left:1.8vw;">
                        2
                    </div>
                    <div class="dltitle">

                    </div>
                </div>
                <div class="divright">
                    <template v-for="(item, index) in pgList">
                        <div :key="item.aorpgId + 'a'" class="flex mleftinfo">
                            <el-form size="small" :inline="true" ref="form" :model="form" label-width="100px"
                                     class="formstyletrainord">
                                <el-form-item class="fmtop fsizestyle row-name" prop="sendcity">
                                    {{
                                    item.xinming
                                    }}
                                </el-form-item>
                                <el-form-item class="fmtop fsizestyle" prop="sendcity">
                            <span class="cstyle">{{
                                item.renyuanLeixing === "1" ? "成人" : "儿童"
                            }}</span>
                                    <span class="cstyle">{{ item.cardType === "1" ? "身份证" : "护照" }}：{{ item.cardId }}</span>
                                    <!-- <span class="cstyle">{{ typeDic[item.xiwei] }} {{ item.zuowei }}</span> -->
                                    <span class="cstyle"> 订单号：{{ item.liushuiNo }} </span>
                                    <span class="cstyle"> 座位号：15B </span>
                                </el-form-item>
                                <el-form-item class="fmtop fsizestyle" prop="sendcity">
                            <!--<span class="cstyle" v-if="item.isBuyBaoxian === '1'">-->
                                <!--<el-image class="imgmargin" :src="require('../assets/img/bao.png')"-->
                                          <!--fit="cover"></el-image>-->
                            <!--</span>-->
                                   <!---->
                                </el-form-item>
                            </el-form>
                        </div>
                        <div :key="item.aorpgId + 'b'" v-if="index !== pgList.length - 1" class="hengxian"></div>
                    </template>
                </div>
            </div>

        </div>
        <div class="hcorder" style="background-color: #FBE5E3;">
            <div class="flex mleftinfo">
                <div class="divleft">
                    <div class="xuhao" style="margin-left:1.8vw;">
                        3
                    </div>
                    <div class="dltitle">
                        已退票
                    </div>
                </div>
                <div class="divright">
                    <template v-for="(item, index) in pgList">
                        <div :key="item.aorpgId + 'a'" class="flex mleftinfo">
                            <el-form size="small" :inline="true" ref="form" :model="form" label-width="100px"
                                     class="formstyletrainord">
                                <el-form-item class="fmtop fsizestyle row-name" prop="sendcity">
                                    {{
                                    item.xinming
                                    }}
                                </el-form-item>
                                <el-form-item class="fmtop fsizestyle" prop="sendcity">
                            <span class="cstyle">{{
                                item.renyuanLeixing === "1" ? "成人" : "儿童"
                            }}</span>
                                    <span class="cstyle">{{ item.cardType === "1" ? "身份证" : "护照" }}：{{ item.cardId }}</span>
                                    <!-- <span class="cstyle">{{ typeDic[item.xiwei] }} {{ item.zuowei }}</span> -->
                                    <span class="cstyle"> 订单号：{{ item.liushuiNo }} </span>
                                    <span class="cstyle"> 座位号：15B </span>
                                </el-form-item>
                                <el-form-item class="fmtop fsizestyle" prop="sendcity">
                            <span class="cstyle" v-if="item.isBuyBaoxian === '1'">
                                <el-image class="imgmargin" :src="require('../assets/img/bao.png')"
                                          fit="cover"></el-image>
                            </span>

                                </el-form-item>


                            </el-form>

                        </div>
                        <div class="tpstyle">
                            退票原因：这里显示具体改签时间
                        </div>
                        <!--<div :key="item.aorpgId + 'b'" v-if="index !== pgList.length - 1" class="hengxian"></div>-->

                    </template>
                </div>

            </div>

        </div>
        <div class="remark">备注</div>
        <div class="remarkinfo">
            <el-input type="textarea" v-model="remarkTkorder" maxlength="500" show-word-limit resize="none" :rows="5"
                      class="textarea-box" placeholder="请输入备注信息"></el-input>
        </div>
        <div class="ftbottom flex">
            <div class="xiadanperson">
                下单人：{{ baseData.createPerson }} 共{{ pgList.length }}人
            </div>
            <div>
                总金额：<span class="moneycolor">{{ baseData.orderMoney ? `￥${baseData.orderMoney}` : '-' }}</span>
            </div>
        </div>
        <div class="obtn flex" v-if="baseData.cancelStatus !== '5' && baseData.refundStatus !== '3'">
            <div>
                <el-button class="cancelbtn" @click="handelCancle">取消</el-button>
            </div>
            <div>
                <el-button class="paybtn">支付</el-button>
            </div>
        </div>

        <div class="copyright">
            工业和信息化部备案管理系统网站 豫B1-20080191-28
        </div>
        <el-dialog v-show="dialogVisible" :visible="dialogVisible" width="380px" top="80px" :show-close="false"
                   :before-close="handleCloseTuipiao" class="tpinfo">
            <el-card shadow="never" class="box-card">
                <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                    <div>
                        <div class="tptitle">退票</div>
                        <div class="pgmargintop flex">
                            <div class="pgmleft">确认退票吗？</div>
                        </div>
                    </div>
                    <div class="btnstylesinfo">
                        <el-button class="login-btn clearbtn" @click="closex" style="
                background-color: rgba(181, 181, 181, 1);
                color: #ffffff;
                margin-right: 20px;
                width: 100px;
              ">
                            取消
                        </el-button>
                        <el-button @click="handleTuipiao" class="login-btn" type="primary" style="
                background-color: rgba(66, 177, 52, 1);
                margin-left: 20px;
                width: 100px;
              ">确认</el-button>
                    </div>
                </el-form>
            </el-card>
        </el-dialog>
        <el-dialog title="改签" v-show="dialoggaiqianVisible" :visible="dialoggaiqianVisible" width="50%" top="80px"
                   :before-close="handleClosegaiqian">
            <el-card shadow="never" class="box-card">
                <el-form ref="form" :rules="rules" :model="form" class="formstyleyd" label-width="150px">
                    <div class="flex">
                        <el-form-item label="出发：" class="fsizestyle" prop="sendcity">
                            <el-col :span="20">
                                <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.sendcity"
                                          clearable></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="到达：" class="fsizestyle" prop="sendcity">
                            <el-col :span="20">
                                <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.arrivalcity"
                                          clearable></el-input>
                            </el-col>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <el-form-item label="出发日期：" class="mttop" prop="date1">
                            <el-col :span="20">
                                <el-date-picker type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH:mm"
                                                value-format="yyyy-MM-dd HH:mm" v-model="form.senddate"
                                                style="width: 10vw"></el-date-picker>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="到达日期：" class="mttop" prop="date1">
                            <el-col :span="20">
                                <el-date-picker type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH:mm"
                                                value-format="yyyy-MM-dd HH:mm" v-model="form.arrivaldate"
                                                style="width: 10vw"></el-date-picker>
                            </el-col>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <span class="gqdesc">
                            改签说明：发只能改签15天以内的航班
                        </span>
                    </div>

                    <div class="pgbtns">
                        <el-button class="login-btn confirmbtn" type="primary" @click="handleGaiqian"
                                   style="background-color: rgba(66, 177, 52, 1)">提交</el-button>
                    </div>
                </el-form>
            </el-card>
        </el-dialog>
        <el-dialog title="修改" v-show="dialogupdateVisible" :visible="dialogupdateVisible" width="50%" top="80px"
                   :before-close="handleCloseupdate">
            <el-card shadow="never" class="box-card">
                <el-form ref="form" :rules="rules" :model="form" class="formstyleyd" label-width="150px">
                    <div class="flex">
                        <el-form-item label="人员类型" class="mttop" prop="sendcity">
                            <el-col :span="20">
                                <el-select v-model="form.rytype" placeholder="选择人员类型">
                                    <el-option label="成人" value="1"></el-option>
                                    <el-option label="儿童" value="2"></el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="姓名" class="mttop fsizestyle" prop="sendcity">
                            <el-col :span="20">
                                <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.user"
                                          clearable></el-input>
                            </el-col>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <el-form-item label="证件类型" class="mttop" prop="sendcity">
                            <el-col :span="20">
                                <el-select v-model="form.idtype" placeholder="选择证件类型">
                                    <el-option label="身份证" value="1"></el-option>
                                    <el-option label="护照" value="2"></el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="证件号码" class="fsizestyle" prop="sendcity">
                            <el-col :span="20">
                                <el-input class="zjinputstyle heightstyle" placeholder="请输入" v-model="form.id"
                                          clearable></el-input>
                            </el-col>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <el-form-item label="购买保险" class="fsizestyle" prop="sendcity">
                            <el-radio-group v-model="form.isbaoxian">
                                <el-radio class="all_t" label="1">是</el-radio>
                                <el-radio class="all_t" label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <div class="pgbtns">
                        <el-button class="login-btn confirmbtn" @click="confirmUpdate" type="primary"
                                   style="background-color: rgba(66, 177, 52, 1)">提交</el-button>
                    </div>
                </el-form>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
    import ElImage from "element-ui/packages/image/src/main";
    import ElCard from "element-ui/packages/card/src/main";
    import ElButton from "element-ui/packages/button/src/button";
    import { delairorder, queryairtorderbyId, updateairgaiqianordstatus, updateairorderpg, updateairorderstatus, updatetairpordstatus } from "@/api";
    export default {
        components: {
            ElButton,
            ElCard,
            ElImage
        },
        data() {
            return {
                ischecked: false,
                dialogVisible: false,
                dialoggaiqianVisible: false,
                dialogupdateVisible: false,
                textareaValue: '',
                form: {
                    isbaoxian: '2',
                    isnobaoxian: false,
                    zuoxi: "1",
                    idtype: "1",
                    id: null,
                    user: null,
                    rytype: "1",
                    sendcity: '',
                    arrivalcity: '',
                    senddate: '',
                    arrivaldate: ''
                },
                remarkTkorder:'',
                checkAll: false,
                rules: {
                    mobilephone: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                        { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
                    ],

                    pwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                    ],

                },
                ticketTypeDic: {
                    1: '待出票',
                    2: '出票中',
                    3: '未完成',
                    4: '已完成',
                    5: '待支付',
                },
                baseData: {},
                xcList: [],
                pgList: [],
                updateId: "",
                upateLiushui: "",
            }
        },
        created() {
            this.ischecked = true;
        },
        activated() {
            this.getDetail()
        },
        methods: {
            // 输入 YYYY-MM-DD HH:mm:ss 输出  HH:mm
            formatDate(date) {
                if (date) {
                    return date.slice(11, 16)
                }
            },
            async getDetail() {
        try {

            const id = this.$route.query.id
            const data = await queryairtorderbyId(id)
            //console.log("%c Line:517 🍎 data", "color:#33a5ff", data.data.data);
            if (data.data.data.body.length) {
                this.baseData = data.data.data.body[0]
                this.remarkTkorder=this.baseData.remarkAirtk
                this.xcList = this.baseData.xcList
                this.pgList = this.baseData.pgList.map(item => {
                    item['check'] = false
                    return item
                })
                this.$forceUpdate()
            }
        } catch (error) {

            this.baseData = {}
        }
    },

    handleCheckAllChangeSales(val) {
        this.pgList.forEach((item) => {
            if (val) {
                item.check = true;
            } else {
                item.check = false;
    }
    });
    },
    changeCheck() {
        // 判断  list  中 的  item  的  check  是 全部选中还是部分选中 还是 全不选中
        let flag = true;
        this.pgList.forEach((item) => {
            if (!item.check) {
            flag = false;
        }
    });
        this.checkAll = flag;
    },
    closex() {
        this.dialogVisible = false;
    },
    //删除
    gotodel() {
        if (this.pgList.every((item) => !item.check)) {
            this.$message({
                type: "warning",
                message: "请选择删除乘客",
            });
            return;
        }
        this.$confirm("您确定要删除该记录?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(async () => {
            const promiseArr = this.pgList
                .filter((item) => item.check)
    .map((item) => {
            return delairorder({
                aorpgId: item.aorpgId,
                liushuino: item.liushuiNo,
            });
    });
        Promise.all(promiseArr).then((res) => {
            let successCount = 0;
        let failCount = 0;
        res.map((item) => {
            if (item.data.code !== 200) {
            failCount++;
        } else {
            successCount++;
        }
    });
        if (failCount === 0) {
            this.$message({
                type: "success",
                message: "删除成功!",
            });
        } else {
            this.$message({
                type: "error",
                message: `${failCount}条删除失败，${successCount}条删除成功`,
            });
        }
        this.$nextTick(() => {
            this.getDetail();
    });
    });
    })
    .catch(() => {
            //     this.$message({
            //     type: 'info',
            //     message: '已取消删除'
            // });
        });
    },
    //退票关闭窗口
    handleCloseTuipiao() {
        this.dialogVisible = false;
    },
    //退票
    gototuipiao() {
        if (this.pgList.every((item) => !item.check)) {
            this.$message({
                type: "warning",
                message: "请选择退票乘客",
            });
            return;
        }
        this.dialogVisible = true;
    },
    handleClosegaiqian() {
        this.dialoggaiqianVisible = false;
    },
    //改签弹窗
    gotogaiqian() {
        if (this.pgList.filter((item) => item.check).length !== 1) {
            this.$message({
                type: "warning",
                message: "请选择一个乘客改签",
            });
            return;
        }

        this.dialoggaiqianVisible = true;
    },
    //修改弹窗
    gotoupdate(item) {
        this.updateId = item.aorpgId;
        this.upateLiushui = item.liushuiNo;
        this.form.user = item.xinming
        this.form.rytype = item.renyuanLeixing
        this.form.idtype = item.cardType
        this.form.id = item.cardId
        this.form.isbaoxian = item.isBuyBaoxian
        // this.form.zuoxi = item.xiwei
        this.dialogupdateVisible = true;
    },
    async confirmUpdate() {
        const { user, rytype, idtype, id, isbaoxian } = this.form;
        if (!user || !rytype || !idtype || !id) {
            this.$message({
                type: "warning",
                message: "请填写完整信息",
            });
            return;
        }

        const data = await updateairorderpg({
            aorpgId: this.updateId,
            liushuino: this.upateLiushui,
            xinming: user,
            renyuanLeixing: rytype,
            cardType: idtype,
            cardId: id,
            isbuybaoxian: isbaoxian,
        });
        if (data.data.code !== 200) {
            this.$message({
                type: "error",
                message: data.data.msg,
            });
        } else {
            this.$message({
                type: "success",
                message: "修改成功",
            });
            this.dialogupdateVisible = false;
            this.getDetail();
        }
    },
    async handleGaiqian() {
        const promiseArr = this.pgList
            .filter((item) => item.check)
    .map((item) => {
            return updateairgaiqianordstatus({
                liushuiNo: item.liushuiNo,
                departureStation: this.form.sendcity, // this.form.sendcity,
                changeStatus: "3",
                arrivalStation: this.form.arrivalcity, //this.form.arrivalcity,
                sendDate: this.form.senddate,
                arrivalDate: this.form.arrivaldate,
                aorpgId: item.aorpgId,
            });
    });

        try {
            await Promise.all(promiseArr);
            this.$message.success("改签成功");
            this.dialoggaiqianVisible = false;
            // this.getDetailId();
            this.getDetail()
        } catch (error) {
            this.$message.error("改签失败");
        }
    },
    async handleTuipiao() {
        const promiseArr = this.pgList
            .filter((item) => item.check)
    .map((item) => {
            return updatetairpordstatus({
                liushuino: item.liushuiNo,
                refundStatus: "3",
                aorpgId: item.aorpgId,
            });
    });

        try {
            await Promise.all(promiseArr);
            this.$message.success("退票成功");
            this.dialogVisible = false;
            // this.getDetailId();
            this.$router.push({
                path: "/airticketorder",
            });
        } catch (error) {
            this.$message.error("退票失败");
        }
    },
    async handelCancle() {
        this.$confirm("是否确认取消订单?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(async () => {
            const data = await updateairorderstatus({
                                                        aOrderId: this.baseData.aOrderId,
            cancelStatus: 5,
    });
        if (data.data.code === 200) {
            this.$message({
                type: "success",
                message: "操作成功!",
            });
            this.$router.push({
                path: "/airticketorder",
            })
        }
    })
    .catch(() => { });
    },
    //转向预定申请
    forwardyuding() {
        this.$router.push('/airticketorder');
    },

    handleCloseupdate() {
        this.dialogupdateVisible = false;
    },
    },
    name: "airticketgaiqiantuipiaoRight-right"
    }
</script>

<style scoped>
    .tpstylegqtwo{
        margin-left:2.2vw;
        font-size:13px;
        margin-top:-3vh;
    }
    .tpstylegq{
        margin-left:2.2vw;
        font-size:13px;
        margin-top:1vh;
        padding-bottom:10px;
    }
    .tpstyle{
        margin-left:2.2vw;
        font-size:13px;
        margin-top:-2vh;
    }
    .divleft{
        padding-top:10px;
        width:5vw;
        text-align: center;
    }
    .dltitle{
        padding-bottom:10px;
        padding-top:10px;
    }
    .xuhao {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #000000;
        color: #ffffff;
        text-align: center;
    }
    .remarkinfo {
        width: calc(88% - 0px - 10px) !important;

    }

    .textarea-box {
        width: calc(100% - 0px - 0px) !important;
        background-color: #f1f1f1;
        margin-top: 2vh;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }

    .textarea-box /deep/ .el-textarea__inner {
        background-color: #f1f1f1;
    }

    .remark {
        font-weight: bolder;
        margin-top:2vh;
    }
    .linemargin {
        margin-top: 30px;
        margin-bottom: 30px;

    }

    .tptitle {
        justify-content: center;
        display: flex;
        font-size: 18px;
    }

    .tpinfo /deep/ .el-dialog {
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
    }

    .tpinfo /deep/ .el-card {
        border: 1px solid #FFFFFF;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
    }

    .tpinfo /deep/ .el-card__body {
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
    }

    .tpinfo /deep/ .el-dialog__body {
        padding: 0;
        color: #606266;
        font-size: 14px;
        /*word-break: break-all;*/
    }

    .lineicon {
        font-size: 1px;
    }

    .pgmleft {
        text-align: center;
    }

    .pgmargintop {
        margin-top: 20px;
        justify-content: center;
        display: flex;
    }

    .btnstylesinfo {
        margin-top: 30px;
        justify-content: center;
        display: flex;
        text-align: center;
    }

    .gqdesc {
        margin-left: 68px;
    }

    .formstyleyd .inputstyle {
        width: 10vw;
    }

    .confirmbtn {
        width: calc(48% - 0px - 10px) !important;
        padding-left: 100px;
        padding-right: 100px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }

    .pgbtns {
        margin-top: 20px;
        width: calc(100% - 0px - 0px) !important;
        justify-content: center;
        text-align: center;
    }

    .pgtop {
        margin-top: 10px;
    }

    .pgleft {
        margin-left: 10px;
    }

    .fmtop {}

    .weiziiconst {
        width: 24px;
        height: 20px;
    }

    .cdiv {}

    .obtn {
        margin-top: 5vh;
        margin-left: 18vw;
    }

    .cancelbtn {
        background: #969696;
        padding-left: 20px;
        padding-right: 20px;
        color: #FFFFFF;
        width: 230px;
        border-radius: 10px;
    }

    .paybtn {
        background: #42B134;
        padding-left: 20px;
        padding-right: 20px;
        color: #FFFFFF;
        width: 230px;
        margin-left: 2vw;
        border-radius: 10px;
    }

    .xiadanperson {
        width: 54vw;
    }

    .ftbottom {
        margin-top: 2vh;
        width: calc(88% - 0px - 10px) !important;
    }

    .moneycolor {
        color: #FF7342;
    }

    .mleftinfo .row-name /deep/ .el-checkbox__label {
        font-size: 16px;
        color: black;
    }

    .hengxian {
        width: calc(100% - 0px - 10px) !important;
        border: 1px dashed #999999;
        margin-bottom: 18px;
    }

    .imgmargin {}

    .selck {}

    .cstyle {
        margin-left: 1.5vw;
    }

    .cstyle .weiziicon {
        width: auto;
    }

    .mleftinfo {
        margin-left: 1vw;
    }

    .graycolor {
        color: #646464;
    }

    .ttop {
        margin-left: 2vw;
    }

    .xiadandate {
        margin-left: 8vw;
    }

    .fachedate {
        margin-left: 8vw;
    }

    .shuoming {
        font-size: 13px;
        color: #444444;
        line-height: 25px;
    }

    .textarea-box {
        width: 68vw;
        background-color: #F1F1F1;
        margin-top: 2vh;
        border-radius: 10px;
    }

    .remark {
        font-weight: bolder;
    }

    .orderbtn {
        width: 200px;
        height: 35px;
        border: 1px solid #42B134;
        color: #FFFFFF;
        padding: 10px;
        margin-top: 5vh;
        background-color: #42B134;
    }

    .pgbtn {
        width: 200px;
        height: 35px;
        border: 1px solid #000000;
        color: #000000;
        padding: 10px;
        margin-top: 5vh;
    }

    .addpg {
        margin-left: 25vw;
        margin-right: 25vw;
    }

    .xuhao {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #000000;
        color: #FFFFFF;
        text-align: center;

    }

    .pginfotitle {
        width: 61vw;
        font-weight: bolder;
        margin-left: 1vw;
    }

    .pginfo {
        margin-top: 2vh;
    }

    .sfsbbtn {
        border: 1px solid #000000;
        color: #000000;
        margin-left: 1vw;
        margin-top: 4vh;
    }

    .btnoper {}

    .bluecolor {
        color: #42B134;
    }

    .startweight {
        font-weight: bolder;
        font-size: 20px;
    }

    .iconstyles {}

    .addpgstyle {
        font-weight: bolder;
        margin-top: 2vh;
    }

    .copyright {
        width: calc(88% - 0px - 10px) !important;
        font-size: 12px;
        text-align: center;
        margin-top: 5vh;
        color: #B6B6B6;
    }

    .mleftmargin {
        margin-left: 1vw;
    }

    .pager {
        margin-top: 5vh;
        margin-left: 10vw;
    }

    .shouye {}

    .sybtn {
        width: 100px;
        height: 35px;
        border: 1px solid #666666;
        text-align: center;
    }

    .btnnodiv {
        margin-left: 1vw;
    }

    .btnno {
        width: 50px;
        height: 35px;
        border: 1px solid #666666;
        text-align: center;
    }

    .commonmtop {
        margin-top: 2vh;
    }

    .btnmargin {
        margin-top: 1vh;
    }

    .btnstyle {
        width: 100px;
        height: 35px;
        background-color: #42B134;
        color: #FFFFFF;
        border-radius: 5px;
    }

    .operbtn {
        margin-left: 8vw;
    }

    .checizuowei {
        margin-left: 5vw;
    }

    .colorstyle {
        color: #F49200;
        font-weight: bolder;
    }

    .checileixing {
        margin-left: 5vw;
    }

    .checimoney {
        margin-left: 5vw;
    }

    .zwleixing {
        margin-top: 0.1vh;
    }

    .cmtopzw {
        margin-top: 2vh;
    }

    .cxinfo {
        /* margin-left:12vw; */
        text-align: center;
    }

    .endcheci {
        /* margin-left:3vw; */
    }

    .sleft {
        /* margin-left:4vw; */
        text-align: center;
    }

    .scheci {
        /* margin-left:2vw; */

    }

    .startzhan {
        margin-left: 0.5vw;
    }

    .scdiv {
        margin-top: 2vh;
        margin-left: 1vw;
    }

    .sccdate {
        font-size: 20px;
        font-weight: bolder;

    }

    .haoshi {
        /* margin-left:3vw; */
        flex: 1;
    }

    .tcontent {
        background-color: #F1F1F1;
        /*height:340px;*/
        margin-top: 2vh;
        width: calc(88% - 0px - 10px) !important;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        padding-top: 4vh;
        padding-bottom: 20px;
    }

    .mleftd {
        margin-left: -2vw;
    }

    .querybtn {
        background-color: #42B134;
        color: #FFFFFF;
        border-radius: 5px;
        margin-left: -1vw;
    }

    .formstyletrainord {
        padding-top: 2.2vh;

        width: 100%;
        display: flex;
        padding: 0 20px;
        box-sizing: border-box;
        align-items: center;
    }

    .no-align /deep/ .el-form-item__content {
        justify-content: start !important;
    }

    .formstyletrainord /deep/ .el-form-item .el-form-item__content {
        /* flex: 1; */
        display: flex;
        /* justify-content: center; */
        align-items: center;
    }

    .formstyletrainord /deep/ .el-form-item:nth-child(2) {
        flex: 1;
    }

    .container {}

    .header {
        font-size: 12px;
    }

    .flex {
        display: flex;
    }

    .weiziicon {
        width: 100%;
        font-size: 0;
    }

    .iconstyles .weiziicon {
        width: auto;
    }

    .pleft {
        margin-left: 0.5vw;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
    }

    .hcorder {
        background-color: #F1F1F1;
        width: calc(88% - 0px - 10px) !important;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        /*height: 250px;*/
        margin-top: 2vh;
    }

    .hc {
        background-color: #F1F1F1;
        width: 68vw;
        height: 14vh;
        margin-top: 2vh;
    }

    .hcpg {
        background-color: #F1F1F1;
        width: 68vw;
        height: 27vh;
        margin-top: 2vh;
    }

    button:hover {
        background-color: #42B134;
        color: #FFFFFF;
    }

    .tcontentxc {
        background-color: #F1F1F1;
        /* height:100px; */
        margin-top: 2vh;
        /* width: calc(88% - 0px - 10px) !important; */
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        justify-content: center;
        display: flex;
        align-items: center;
        padding: 0 30px;
        box-sizing: border-box;
    }
</style>
