<template>
  <div id="app">
    <el-backtop target="#app"></el-backtop>
    <keep-alive><router-view></router-view></keep-alive>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  height: 100%;
  overflow: auto;
}

.el-backtop, .el-calendar-table td.is-today{
  color: #42b134 !important;
}
</style>
