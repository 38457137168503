<template>
  <!-- 机票列表 -->
  <div class="portList" :style="{ minHeight: calcHeight }">
    <div class="title" v-if="queryObj.radio === '1'">
      <div class="left">
        <span>单程：</span>
        <span class="addr">{{ queryObj.city1 }}->{{ queryObj.city2 }}</span
        >&nbsp; <span>{{ queryObj.goTime }}</span
        >&nbsp;
        <span class="des">所有起飞 / 到达时间均为当地时间</span>
      </div>
      <!-- <div class="right">航司比价表</div> -->
    </div>
    <div class="box-card">
      <!-- 单程 -->
      <div v-show="queryObj.radio === '1'">
        <div class="screen">
          <div class="left">
            <el-checkbox class="mr" v-model="checked">直飞</el-checkbox>
            <el-popover
              class="mr"
              placement="bottom"
              width="300"
              trigger="click"
            >
              <h3>中转</h3>
              <el-checkbox v-model="checked">中转</el-checkbox>
              <el-button size="small" slot="reference"
                >中转 <i class="el-icon-arrow-down"></i
              ></el-button>
            </el-popover>

            <el-popover
              class="mr"
              placement="bottom"
              width="300"
              trigger="click"
            >
              <h3>航空公司</h3>
              <el-checkbox v-model="checked">航空公司</el-checkbox>
              <el-button size="small" slot="reference"
                >航空公司 <i class="el-icon-arrow-down"></i
              ></el-button>
            </el-popover>

            <el-popover
              class="mr"
              placement="bottom"
              width="300"
              trigger="click"
            >
              <h3>起抵时间</h3>
              <el-checkbox v-model="checked">起抵时间</el-checkbox>
              <el-button size="small" slot="reference"
                >起抵时间 <i class="el-icon-arrow-down"></i
              ></el-button>
            </el-popover>

            <el-popover
              class="mr"
              placement="bottom"
              width="300"
              trigger="click"
            >
              <h3>机场</h3>
              <el-checkbox v-model="checked">机场</el-checkbox>
              <el-button size="small" slot="reference"
                >机场 <i class="el-icon-arrow-down"></i
              ></el-button>
            </el-popover>

            <el-popover
              class="mr"
              placement="bottom"
              width="300"
              trigger="click"
            >
              <h3>舱位</h3>
              <el-checkbox v-model="checked">舱位</el-checkbox>
              <el-button size="small" slot="reference"
                >舱位 <i class="el-icon-arrow-down"></i
              ></el-button>
            </el-popover>

            <el-popover
              class="mr"
              placement="bottom"
              width="300"
              trigger="click"
            >
              <h3>更多</h3>
              <el-checkbox v-model="checked">更多</el-checkbox>
              <el-button size="small" slot="reference"
                >更多 <i class="el-icon-arrow-down"></i
              ></el-button>
            </el-popover>
          </div>
          <div class="right">
            <el-button class="mr btnclass" size="small" plain
              >低价优先</el-button
            >
            <el-button class="mr btnclass" size="small" plain
              >准点率高-低</el-button
            >
            <el-button class="mr btnclass" size="small" plain
              >起飞时间早-晚</el-button
            >
            <el-popover
              class="mr"
              placement="bottom"
              width="100"
              trigger="hover"
            >
              <div class="tips">起飞晚-早</div>
              <div class="tips">到达早-晚</div>
              <div class="tips">到达晚-早</div>
              <div class="tips">耗时短-长</div>
              <div class="tips">耗时长-短</div>
              <div class="tips">价格高-低</div>
              <el-button class="mr btnclass" size="small" slot="reference"
                >更多排序 <i class="el-icon-arrow-down"></i
              ></el-button>
            </el-popover>
          </div>
        </div>

        <div
          class="list-airpost"
          v-for="(item, index) in airPortList"
          :key="index"
          @click="openDesc(item)"
        >
          <el-row>
            <el-col :span="5">
              <div class="cont">
                <div class="l">
                  <el-image
                    v-if="item.imageUrl"
                    style="width: 66px; height: 66px"
                    :src="item.imageUrl"
                    fit="cover"
                  ></el-image>
                  <div v-else class="zanwu">暂无图片</div>
                </div>
                <div class="r">
                  <span>{{ item.airlineCn }}</span>
                  <span class="desc">{{ item.flightNo }}
                    &nbsp;
                    {{ item.planeStyle }}
                    &nbsp;&nbsp;
                    {{item.share ? '共享' : ''}}
                  </span>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="timeRange">
                <span class="t1">
                  <span>{{ timeFunt(item.depTime) }}</span>
                  <span class="name"
                    >{{ item.orgAirportCn }}{{ item.orgAirportTerminal }}</span
                  >
                </span>
                <span class="t2">
                  &nbsp;&nbsp;
                  <el-image
                    style="width: 32px; height: 32px"
                    :src="require('../assets/icon-03.png')"
                    fit="cover"
                  >
                  </el-image>
                  &nbsp;&nbsp;
                </span>
                <span class="t3">
                  <span>{{ timeFunt(item.arrTime) }}</span>
                  <span class="name"
                    >{{ item.dstAirportCn }}{{ item.dstAirportTerminal }}</span
                  ></span
                >
              </div>
            </el-col>
            <el-col :span="3">
              <div class="allTime" :title="timeChineseFunt(item.flyTime)">
                {{ timeChineseFunt(item.flyTime) }}
              </div>
            </el-col>
            <el-col :span="3">
              <div class="tag">
                <!-- <el-tag size="mini" v-if="arrowFlag" type="success"
                >赠租车600元满减券</el-tag
              > -->
              {{item.meal ? '含餐' : '不含餐'}}
              <el-divider direction="vertical"></el-divider>
              里程:{{item.tpm}}
              </div>
            </el-col>
            <el-col :span="5">
              <div class="price">
                <div class="l">
                  <span class="num" v-if="item.dstCity"
                    ><span style="font-size: 18px">¥</span>{{ item.minPrice
                    }}<span style="font-size: 12px">起</span></span
                  >
                  <!-- <span class="desc" v-if="arrowFlag">经济舱5.5折</span> -->
                </div>
                <div class="r">
                  <el-button
                    v-if="item.dstCity"
                    class="search"
                    size="small"
                    @click.stop="openDesc(item)"
                    >订票&nbsp;<i class="el-icon-arrow-down"></i
                  ></el-button>
                  <el-button
                    v-if="!item.dstCity"
                    class="arrow-up"
                    size="small"
                    @click.stop="openDesc(item)"
                    >收起&nbsp;<i class="el-icon-arrow-up"></i
                  ></el-button>
                </div>
              </div>
            </el-col>
          </el-row>
          <airportList-desc v-if="!item.dstCity" :list="item.flightCabins" />
        </div>
      </div>

      <!-- 往返 -->
      <div v-show="queryObj.radio === '2'">
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane label="选择去程" name="1">
            <div class="screen">
              <div class="left">
                <el-checkbox class="mr" v-model="checked">直飞</el-checkbox>
                <el-popover
                  class="mr"
                  placement="bottom"
                  width="300"
                  trigger="click"
                >
                  <h3>中转</h3>
                  <el-checkbox v-model="checked">中转</el-checkbox>
                  <el-button size="small" slot="reference"
                    >中转 <i class="el-icon-arrow-down"></i
                  ></el-button>
                </el-popover>

                <el-popover
                  class="mr"
                  placement="bottom"
                  width="300"
                  trigger="click"
                >
                  <h3>航空公司</h3>
                  <el-checkbox v-model="checked">航空公司</el-checkbox>
                  <el-button size="small" slot="reference"
                    >航空公司 <i class="el-icon-arrow-down"></i
                  ></el-button>
                </el-popover>

                <el-popover
                  class="mr"
                  placement="bottom"
                  width="300"
                  trigger="click"
                >
                  <h3>起抵时间</h3>
                  <el-checkbox v-model="checked">起抵时间</el-checkbox>
                  <el-button size="small" slot="reference"
                    >起抵时间 <i class="el-icon-arrow-down"></i
                  ></el-button>
                </el-popover>

                <el-popover
                  class="mr"
                  placement="bottom"
                  width="300"
                  trigger="click"
                >
                  <h3>机场</h3>
                  <el-checkbox v-model="checked">机场</el-checkbox>
                  <el-button size="small" slot="reference"
                    >机场 <i class="el-icon-arrow-down"></i
                  ></el-button>
                </el-popover>

                <el-popover
                  class="mr"
                  placement="bottom"
                  width="300"
                  trigger="click"
                >
                  <h3>舱位</h3>
                  <el-checkbox v-model="checked">舱位</el-checkbox>
                  <el-button size="small" slot="reference"
                    >舱位 <i class="el-icon-arrow-down"></i
                  ></el-button>
                </el-popover>

                <el-popover
                  class="mr"
                  placement="bottom"
                  width="300"
                  trigger="click"
                >
                  <h3>更多</h3>
                  <el-checkbox v-model="checked">更多</el-checkbox>
                  <el-button size="small" slot="reference"
                    >更多 <i class="el-icon-arrow-down"></i
                  ></el-button>
                </el-popover>
              </div>
              <div class="right">
                <el-button class="mr btnclass" size="small" plain
                  >低价优先</el-button
                >
                <el-button class="mr btnclass" size="small" plain
                  >准点率高-低</el-button
                >
                <el-button class="mr btnclass" size="small" plain
                  >起飞时间早-晚</el-button
                >
                <el-popover
                  class="mr"
                  placement="bottom"
                  width="100"
                  trigger="hover"
                >
                  <div class="tips">起飞晚-早</div>
                  <div class="tips">到达早-晚</div>
                  <div class="tips">到达晚-早</div>
                  <div class="tips">耗时短-长</div>
                  <div class="tips">耗时长-短</div>
                  <div class="tips">价格高-低</div>
                  <el-button class="mr btnclass" size="small" slot="reference"
                    >更多排序 <i class="el-icon-arrow-down"></i
                  ></el-button>
                </el-popover>
              </div>
            </div>
            <div
              class="list-airpost"
              v-for="(item, index) in airPortList1"
              :key="index"
            >
              <el-row>
                <el-col :span="5">
                  <div class="cont">
                    <div class="l">
                      <el-image
                        v-if="item.imageUrl"
                        style="width: 66px; height: 66px"
                        :src="item.imageUrl"
                        fit="cover"
                      ></el-image>
                      <div v-else class="zanwu">暂无图片</div>
                    </div>
                    <div class="r">
                      <span>{{ item.airlineCn }}</span>
                      <span class="desc">{{ item.flightNo }}</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="timeRange">
                    <span class="t1">
                      <span>{{ timeFunt(item.depTime) }}</span>
                      <span class="name"
                        >{{ item.orgAirportCn
                        }}{{ item.orgAirportTerminal }}</span
                      >
                    </span>
                    <span class="t2">
                      &nbsp;&nbsp;
                      <el-image
                        style="width: 32px; height: 32px"
                        :src="require('../assets/icon-03.png')"
                        fit="cover"
                      >
                      </el-image>
                      &nbsp;&nbsp;
                    </span>
                    <span class="t3">
                      <span>{{ timeFunt(item.arrTime) }}</span>
                      <span class="name"
                        >{{ item.dstAirportCn
                        }}{{ item.dstAirportTerminal }}</span
                      ></span
                    >
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="allTime" :title="timeChineseFunt(item.flyTime)">
                    {{ timeChineseFunt(item.flyTime) }}
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="tag"></div>
                </el-col>
                <el-col :span="5">
                  <div class="price">
                    <div class="l">
                      <span class="num" v-if="item.dstCity"
                        ><span style="font-size: 18px">¥</span>{{ item.minPrice
                        }}<span style="font-size: 12px">起</span></span
                      >
                    </div>
                    <div class="r">
                      <el-button
                        class="search"
                        size="small"
                        @click="chooseGo(item)"
                        >选为去程</el-button
                      >
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane label="选择返程" name="2" :disabled="arrddisabled">
            <div class="screen">
              <div class="left">
                <el-checkbox class="mr" v-model="checked">直飞</el-checkbox>
                <el-popover
                  class="mr"
                  placement="bottom"
                  width="300"
                  trigger="click"
                >
                  <h3>中转</h3>
                  <el-checkbox v-model="checked">中转</el-checkbox>
                  <el-button size="small" slot="reference"
                    >中转 <i class="el-icon-arrow-down"></i
                  ></el-button>
                </el-popover>

                <el-popover
                  class="mr"
                  placement="bottom"
                  width="300"
                  trigger="click"
                >
                  <h3>航空公司</h3>
                  <el-checkbox v-model="checked">航空公司</el-checkbox>
                  <el-button size="small" slot="reference"
                    >航空公司 <i class="el-icon-arrow-down"></i
                  ></el-button>
                </el-popover>

                <el-popover
                  class="mr"
                  placement="bottom"
                  width="300"
                  trigger="click"
                >
                  <h3>起抵时间</h3>
                  <el-checkbox v-model="checked">起抵时间</el-checkbox>
                  <el-button size="small" slot="reference"
                    >起抵时间 <i class="el-icon-arrow-down"></i
                  ></el-button>
                </el-popover>

                <el-popover
                  class="mr"
                  placement="bottom"
                  width="300"
                  trigger="click"
                >
                  <h3>机场</h3>
                  <el-checkbox v-model="checked">机场</el-checkbox>
                  <el-button size="small" slot="reference"
                    >机场 <i class="el-icon-arrow-down"></i
                  ></el-button>
                </el-popover>

                <el-popover
                  class="mr"
                  placement="bottom"
                  width="300"
                  trigger="click"
                >
                  <h3>舱位</h3>
                  <el-checkbox v-model="checked">舱位</el-checkbox>
                  <el-button size="small" slot="reference"
                    >舱位 <i class="el-icon-arrow-down"></i
                  ></el-button>
                </el-popover>

                <el-popover
                  class="mr"
                  placement="bottom"
                  width="300"
                  trigger="click"
                >
                  <h3>更多</h3>
                  <el-checkbox v-model="checked">更多</el-checkbox>
                  <el-button size="small" slot="reference"
                    >更多 <i class="el-icon-arrow-down"></i
                  ></el-button>
                </el-popover>
              </div>
              <div class="right">
                <el-button class="mr btnclass" size="small" plain
                  >低价优先</el-button
                >
                <el-button class="mr btnclass" size="small" plain
                  >准点率高-低</el-button
                >
                <el-button class="mr btnclass" size="small" plain
                  >起飞时间早-晚</el-button
                >
                <el-popover
                  class="mr"
                  placement="bottom"
                  width="100"
                  trigger="hover"
                >
                  <div class="tips">起飞晚-早</div>
                  <div class="tips">到达早-晚</div>
                  <div class="tips">到达晚-早</div>
                  <div class="tips">耗时短-长</div>
                  <div class="tips">耗时长-短</div>
                  <div class="tips">价格高-低</div>
                  <el-button class="mr btnclass" size="small" slot="reference"
                    >更多排序 <i class="el-icon-arrow-down"></i
                  ></el-button>
                </el-popover>
              </div>
            </div>

            <div class="goHeader">
              <p>
                已选去程：{{ goObj.orgAirportCn }}{{ goObj.orgAirportTerminal }}
                &nbsp;
                <el-image
                  style="width: 20px; height: 8px"
                  :src="require('../assets/icon-03.png')"
                  fit="cover"
                >
                </el-image>
                &nbsp;
                {{ goObj.dstAirportCn
                }}{{ goObj.dstAirportTerminal }}&nbsp;&nbsp;{{
                  goObj.depDate
                }}&nbsp;&nbsp; {{ goObj.depTime }} - {{ goObj.arrTime }}
                &nbsp;&nbsp;
                <span style="color: #999">{{ goObj.flyTime }}</span>
                <el-divider direction="vertical"></el-divider>
                <el-image
                  style="width: 28px; height: 28px"
                  :src="goObj.imageUrl"
                  fit="cover"
                >
                </el-image>
                <span style="color: #999">{{ goObj.airlineCn }}</span>
              </p>

              <el-button class="search" size="small" @click="activeName = '1'"
                >修改去程</el-button
              >
            </div>
            <div
              class="list-airpost"
              v-for="(item, index) in airPortList2"
              :key="index"
            >
              <el-row>
                <el-col :span="5">
                  <div class="cont">
                    <div class="l">
                      <el-image
                        v-if="item.imageUrl"
                        style="width: 66px; height: 66px"
                        :src="item.imageUrl"
                        fit="cover"
                      ></el-image>
                      <div v-else class="zanwu">暂无图片</div>
                    </div>
                    <div class="r">
                      <span>{{ item.airlineCn }}</span>
                      <span class="desc">{{ item.flightNo }}</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="timeRange">
                    <span class="t1">
                      <span>{{ timeFunt(item.depTime) }}</span>
                      <span class="name"
                        >{{ item.orgAirportCn
                        }}{{ item.orgAirportTerminal }}</span
                      >
                    </span>
                    <span class="t2">
                      &nbsp;&nbsp;
                      <el-image
                        style="width: 32px; height: 32px"
                        :src="require('../assets/icon-03.png')"
                        fit="cover"
                      >
                      </el-image>
                      &nbsp;&nbsp;
                    </span>
                    <span class="t3">
                      <span>{{ timeFunt(item.arrTime) }}</span>
                      <span class="name"
                        >{{ item.dstAirportCn
                        }}{{ item.dstAirportTerminal }}</span
                      ></span
                    >
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="allTime" :title="timeChineseFunt(item.flyTime)">
                    {{ timeChineseFunt(item.flyTime) }}
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="tag">
                    <!-- <el-tag size="mini" v-if="arrowFlag" type="success"
                >赠租车600元满减券</el-tag
              > -->
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="price">
                    <div class="l">
                      <span class="num" v-if="item.dstCity"
                        ><span style="font-size: 18px">¥</span>{{ item.minPrice
                        }}<span style="font-size: 12px">起</span></span
                      >
                      <!-- <span class="desc" v-if="arrowFlag">经济舱5.5折</span> -->
                    </div>
                    <div class="r">
                      <el-button
                        v-if="item.dstCity"
                        class="search"
                        size="small"
                        @click.stop="openDesc(item)"
                        >订票&nbsp;<i class="el-icon-arrow-down"></i
                      ></el-button>
                      <el-button
                        v-if="!item.dstCity"
                        class="arrow-up"
                        size="small"
                        @click.stop="openDesc(item)"
                        >收起&nbsp;<i class="el-icon-arrow-up"></i
                      ></el-button>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <airportList-desc
                v-if="!item.dstCity"
                :list="item.flightCabins"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import airportListDesc from './airportListDesc.vue';
import { getInternationalTicketList, getAirlineCompanyList } from '../api/index';
import Cookie from 'js-cookie';
export default {
  components: {
    airportListDesc,
  },
  data () {
    return {
      arrowFlag: true,
      checked: false,
      airPortList: [],
      airPortList1: [],
      airPortList2: [],
      num: 1,
      queryObj: {},
      changeFlag: true,

      airCompanyList: [],

      activeName: '1',
      arrddisabled: true,
      goObj: {},
    }
  },
  watch: {
    queryObj: {
      handler () {
        //console.log('查询条件变化了');
        this.changeFlag = true;
      },
      deep: true,
    }
  },
  methods: {
    openDesc(item) {
      item.dstCity = !item.dstCity;
    },
    chooseGo (item) {
      this.goObj = item;
      this.activeName = '2';
      this.arrddisabled = false;
    },
    timeFunt (str) {
      return str.slice(0, 2) + ":" + str.slice(2);
    },
    timeChineseFunt (str) {
      const h = str.split(':')[0]
      const m = str.split(':')[1]
      const a = h + '小时' + m + '分'
      return a;
    },
    getDataFunt (item) {
      this.queryObj = item;
      this.airPortList = [];
      if (this.changeFlag) {
        this.$emit('loadingFunt', true);
        if (item.radio === '1') {//单程
          const params = {
            depCity: item.city1Code,
            dstCity: item.city2Code,
            szDate: item.goTime,
            flightType: Number(item.radio),
            memAccount: Cookie.get('phone') || ''
          }
          // console.log(params.szDate, '日期选择组件测试');
          // this.$emit('loadingFunt', false);
          // return;
          const p = this.$querystring.stringify(params);
          getInternationalTicketList(p).then(res => {
            if (res.data.code !== 200) {
              this.$message.error(res.data.message);
              this.$emit('loadingFunt', false);
            } else {
              if (res.data.code && res.data.code === 200) {
                const arr = res.data.result.records;
                const vv = this.getImgUrl(arr);
                this.airPortList = vv;
                localStorage.setItem('ticketList', JSON.stringify(arr));
                this.$emit('loadingFunt', false);
                this.changeFlag = false;
              }

            }
          })
        } else {//查往返
          const params1 = {
            depCity: item.city1Code,
            dstCity: item.city2Code,
            szDate: item.timeRange[0],
            flightType: Number(item.radio),
            memAccount: Cookie.get('phone') || ''
          }
          const params2 = {
            depCity: item.city2Code,
            dstCity: item.city1Code,
            szDate: item.timeRange[1],
            flightType: Number(item.radio),
            memAccount: Cookie.get('phone') || ''
          }
          const p1 = this.$querystring.stringify(params1);
          const p2 = this.$querystring.stringify(params2);
          const reslist = Promise.all([getInternationalTicketList(p1), getInternationalTicketList(p2)]);
          reslist.then(res => {
            //console.log(res, '往返的结果');
            if (res && res[0].data.code === 200) {
              const vv1 = this.getImgUrl(res[0].data.result.records);
              const vv2 = this.getImgUrl(res[1].data.result.records);
              this.airPortList1 = vv1;
              this.airPortList2 = vv2;
              localStorage.setItem('ticketList1', JSON.stringify(vv1));
              localStorage.setItem('ticketList2', JSON.stringify(vv2));
              this.$emit('loadingFunt', false);
              this.changeFlag = false;
            } else {
              this.$message.error(res[0].data.message)
              this.$emit('loadingFunt', false);
            }
          })
        }
      } else {
        const arr = JSON.parse(localStorage.getItem('ticketList'));
        const vv = this.getImgUrl(arr);
        this.airPortList = vv;

        const arr1 = JSON.parse(localStorage.getItem('ticketList1'));
        const arr2 = JSON.parse(localStorage.getItem('ticketList2'));
        const vv1 = this.getImgUrl(arr1);
        const vv2 = this.getImgUrl(arr2);
        this.airPortList1 = vv1;
        this.airPortList2 = vv2;
      }
    },
    getImgUrl (arr) {
      arr.forEach(v => {
        this.airCompanyList.forEach(k => {
          if (v.airline === k.acyTwoCode) {
            v.imageUrl = `http://lqplane.whxrkj.com:6003/jeecg-boot/sys/common/static/${k.acyLogo}`
          }
        })
      });
      return arr;
    }
  },
  created () {
    getAirlineCompanyList().then(res => {
      this.airCompanyList = res.data.result;
    })
  },
  computed: {
    calcHeight () {
      return window.innerHeight - 366 + 'px';
    }
  }
}
</script>
<style lang="less" scoped>
.portList {
  margin-top: 30px;
  width: 100%;
  /deep/.el-tabs__header {
    margin: 0;
  }
  /deep/.el-tabs__nav-scroll {
    background: #fff;
    font-size: 18px;
  }
  /deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    font-size: 18px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    color: #333;
    .addr {
      font-size: 24px;
      font-weight: 700;
    }
    .des {
      color: #999;
      font-size: 14px;
    }
    .right {
      font-size: 14px;
      cursor: pointer;
    }
  }
  .box-card {
    margin-top: 10px;
    border-radius: 10px 10px 0 0;
    // padding: 0;
    box-sizing: border-box;
    background-color: #eef1f6;
    overflow: hidden;
    .screen {
      height: 64px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      .left,
      .right {
        display: flex;
        align-items: center;
        .mr {
          margin-right: 10px;
        }
      }
      .right {
        .tips {
          width: 100%;
          text-align: center;
          line-height: 20px;
        }
        .btnclass {
          border: 0;
          &:hover {
            background: #f2f9fe;
            border-radius: 6px;
            color: #000;
          }
        }
      }
    }
    .goHeader {
      display: flex;
      background: #fff;
      margin: 5px 0;
      justify-content: space-between;
      align-items: center;
      padding-left: 75px;
      padding-right: 30px;
      font-size: 14px;
      p {
        display: flex;
        align-items: center;
      }
    }
    .list-airpost {
      margin-top: 3px;
      background-color: #fff;
      &:hover {
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.18);
        z-index: 3;
      }
      .cont {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height: 96px;
        .l {
          display: flex;
          justify-content: center;
          align-items: center;
          .zanwu {
            width: 66px;
            height: 66px;
            text-align: center;
            line-height: 66px;
            color: #999;
            border: 1px solid #ccc;
            font-size: 10px;
          }
        }
        .r {
          line-height: 24px;
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          font-size: 14px;
          .desc {
            font-size: 12px;
            color: #0086f6;
          }
        }
      }
      .timeRange {
        display: flex;
        font-size: 30px;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        .t1,
        .t3 {
          display: flex;
          flex-direction: column;
          .name {
            font-size: 12px;
          }
        }
        .t2 {
          font-size: 16px;
          i {
            width: 200px;
          }
        }
      }

      .allTime {
        line-height: 96px;
      }
      .tag {
        height: 96px;
        line-height: 96px;
      }
      .price {
        display: flex;
        height: 96px;
        align-items: center;
        justify-content: flex-end;
        .l {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .num {
            color: #ff7d13;
            font-size: 30px;
          }
          .desc {
            color: #919ca2;
            font-size: 12px;
          }
        }
        .r {
          margin: 0 20px 0 10px;
        }
        .search {
          border: none;
          background: linear-gradient(-90deg, #f70, #ffa50a);
          color: #fff;
        }
        .arrow-up {
          color: #ff9913 !important;
          border-color: #ff9913;
          &:focus,
          &:hover {
            background: transparent;
          }
        }
        .el-button--small {
          padding: 9px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-popover {
  .tips {
    width: 100%;
    text-align: center;
    line-height: 30px;
  }
}
</style>