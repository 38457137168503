<template>
    <div>
        <el-container>
            <el-header height="auto"><common-header /></el-header>
            <el-container class="page-content">
                <el-aside class="no-top" width="auto">
                    <common-asideVue />
                </el-aside>
                <el-main class="el-main">
                    <common-right />
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    import ElContainer from "element-ui/packages/container/src/main";
    import CommonAsideVue from '@/components/CommonAside.vue'
    import CommonHeader from '@/components/CommonHeader.vue'
    import CommonRight from '@//components/CommonRight.vue'
    export default {
        components: {ElContainer,CommonHeader,CommonAsideVue,CommonRight},
        name: "pageindex",
        data () {
            return {}
        },
        methods: {

        }
    }
</script>

<style >
    .el-header{
        padding:0;
        background: #42B134;
    }

    .no-top /deep/ .el-menu{
        margin-top: 0;
    }
    .page-content{
        width: 1200px;
        margin: auto;
    }
    .el-main{
        padding: 20px;
        padding-right: 0;
    }
</style>