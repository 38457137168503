let list = [];
export default {
  getMockDataList: () => {
    list = [];
    list.push({
      "orgAirportTerminal": "T2",
      "arrDate": "2024-05-09",
      "mealType": "B",
      "orgCity": "PEK",
      "realFlightNo": "MU5100",
      "dstAirportCn": "上海浦东国际机场",
      "depDate": "2024-05-09",
      "orgAirportCn": "北京首都国际机场",
      "realAirlineCn": "东方航空",
      "dstCityCn": "上海浦东",
      "planeStyle": "32L",
      "updateBy": null,
      "dstAirportTerminal": "T1",
      "realAirline": "MU",
      "share": true,
      "id": null,
      "airline": "HO",
      "orgCityCn": "北京首都",
      "stdPrice": 2150,
      "airlineCn": "吉祥航空",
      "depTime": "0700",
      "flightCabins": [{
          "cabininfo": "A",
          "fare": "2150",
          "changeRuleCn": "航班起飞前7天（不含）前,免收变更费；航班起飞前7天（含）到48小时（不含),收取5%变更费；航班起飞前48小时（含）到4小时,收取5%变更费；航班起飞前4小时（含）后，收取10%变更费。",
          "cabinRate": "1.00",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班起飞前7天（不含）前,收取5%退票费；航班起飞前7天（含）到48小时（不含),收取5%退票费；航班起飞前48小时（含）到4小时,收取10%退票费；航班起飞前4小时（含）后，收取20%退票费。",
          "cabinName": "Y"
        },
        {
          "cabininfo": "A",
          "fare": "2130",
          "changeRuleCn": "航班起飞前7天（不含）前,收取5%变更费；航班起飞前7天（含）到48小时（不含),收取5%变更费；航班起飞前48小时（含）到4小时,收取5%变更费；航班起飞前4小时（含）后，收取10%变更费。",
          "cabinRate": "0.99",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班起飞前7天（不含）前,收取5%退票费；航班起飞前7天（含）到48小时（不含),收取5%退票费；航班起飞前48小时（含）到4小时,收取10%退票费；航班起飞前4小时（含）后，收取20%退票费。",
          "cabinName": "B"
        },
        {
          "cabininfo": "A",
          "fare": "2040",
          "changeRuleCn": "航班起飞前7天（不含）前,收取5%变更费；航班起飞前7天（含）到48小时（不含),收取10%变更费；航班起飞前48小时（含）到4小时,收取25%变更费；航班起飞前4小时（含）后，收取35%变更费。",
          "cabinRate": "0.95",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班起飞前7天（不含）前,收取10%退票费；航班起飞前7天（含）到48小时（不含),收取15%退票费；航班起飞前48小时（含）到4小时,收取30%退票费；航班起飞前4小时（含）后，收取40%退票费。",
          "cabinName": "M"
        },
        {
          "cabininfo": "A",
          "fare": "1830",
          "changeRuleCn": "航班起飞前7天（不含）前,收取5%变更费；航班起飞前7天（含）到48小时（不含),收取10%变更费；航班起飞前48小时（含）到4小时,收取25%变更费；航班起飞前4小时（含）后，收取35%变更费。",
          "cabinRate": "0.85",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班起飞前7天（不含）前,收取10%退票费；航班起飞前7天（含）到48小时（不含),收取15%退票费；航班起飞前48小时（含）到4小时,收取30%退票费；航班起飞前4小时（含）后，收取40%退票费。",
          "cabinName": "U"
        },
        {
          "cabininfo": "A",
          "fare": "1610",
          "changeRuleCn": "航班起飞前7天（不含）前,收取5%变更费；航班起飞前7天（含）到48小时（不含),收取15%变更费；航班起飞前48小时（含）到4小时,收取30%变更费；航班起飞前4小时（含）后，收取40%变更费。",
          "cabinRate": "0.75",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班起飞前7天（不含）前,收取10%退票费；航班起飞前7天（含）到48小时（不含),收取20%退票费；航班起飞前48小时（含）到4小时,收取40%退票费；航班起飞前4小时（含）后，收取50%退票费。",
          "cabinName": "H"
        },
        {
          "cabininfo": "A",
          "fare": "1510",
          "changeRuleCn": "航班起飞前7天（不含）前,收取5%变更费；航班起飞前7天（含）到48小时（不含),收取15%变更费；航班起飞前48小时（含）到4小时,收取30%变更费；航班起飞前4小时（含）后，收取40%变更费。",
          "cabinRate": "0.70",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班起飞前7天（不含）前,收取10%退票费；航班起飞前7天（含）到48小时（不含),收取20%退票费；航班起飞前48小时（含）到4小时,收取40%退票费；航班起飞前4小时（含）后，收取50%退票费。",
          "cabinName": "Q"
        },
        {
          "cabininfo": "A",
          "fare": "1400",
          "changeRuleCn": "航班起飞前7天（不含）前,收取5%变更费；航班起飞前7天（含）到48小时（不含),收取15%变更费；航班起飞前48小时（含）到4小时,收取30%变更费；航班起飞前4小时（含）后，收取40%变更费。",
          "cabinRate": "0.65",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班起飞前7天（不含）前,收取10%退票费；航班起飞前7天（含）到48小时（不含),收取20%退票费；航班起飞前48小时（含）到4小时,收取40%退票费；航班起飞前4小时（含）后，收取50%退票费。",
          "cabinName": "V"
        },
        {
          "cabininfo": "A",
          "fare": "1180",
          "changeRuleCn": "航班起飞前7天（不含）前,收取10%变更费；航班起飞前7天（含）到48小时（不含),收取20%变更费；航班起飞前48小时（含）到4小时,收取50%变更费；航班起飞前4小时（含）后，收取70%变更费。",
          "cabinRate": "0.55",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班起飞前7天（不含）前,收取20%退票费；航班起飞前7天（含）到48小时（不含),收取30%退票费；航班起飞前48小时（含）到4小时,收取70%退票费；航班起飞前4小时（含）后，收取90%退票费。",
          "cabinName": "W"
        },
        {
          "cabininfo": "A",
          "fare": "1080",
          "changeRuleCn": "航班起飞前7天（不含）前,收取10%变更费；航班起飞前7天（含）到48小时（不含),收取20%变更费；航班起飞前48小时（含）到4小时,收取50%变更费；航班起飞前4小时（含）后，收取70%变更费。",
          "cabinRate": "0.50",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班起飞前7天（不含）前,收取20%退票费；航班起飞前7天（含）到48小时（不含),收取30%退票费；航班起飞前48小时（含）到4小时,收取70%退票费；航班起飞前4小时（含）后，收取90%退票费。",
          "cabinName": "S"
        }
      ],
      "updateTime": null,
      "airportTax": 50,
      "tpm": 1178,
      "meal": true,
      "fuelTax": 70,
      "stopNumber": "0",
      "mealType_dictText": "",
      "createBy": null,
      "flightNo": "HO5375",
      "createTime": null,
      "flyTime": "2:20",
      "minPrice": 1080,
      "sysOrgCode": null,
      "arrTime": "0920",
      "dstCity": "PVG"
    }, {
      "orgAirportTerminal": "T2",
      "arrDate": "2024-05-09",
      "mealType": "B",
      "orgCity": "PEK",
      "realFlightNo": "",
      "dstAirportCn": "上海浦东国际机场",
      "depDate": "2024-05-09",
      "orgAirportCn": "北京首都国际机场",
      "realAirlineCn": "",
      "dstCityCn": "上海浦东",
      "planeStyle": "32L",
      "updateBy": null,
      "dstAirportTerminal": "T1",
      "realAirline": "",
      "share": false,
      "id": null,
      "airline": "MU",
      "orgCityCn": "北京首都",
      "stdPrice": 2150,
      "airlineCn": "东方航空",
      "depTime": "0700",
      "flightCabins": [{
          "cabininfo": "2",
          "fare": "8390",
          "changeRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前免费;航班规定离站时间前7天（含）至航班规定离站时间前48小时免费;航班规定离站时间前48小时（含）至航班规定离站时间前4小时5%;航班规定离站时间前4小时（含）至航班起飞后5%",
          "cabinRate": "3.90",
          "updateTime": null,
          "basicCabinTypeCn": "公务舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "C",
          "refundRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前免费;航班规定离站时间前7天（含）至航班规定离站时间前48小时5%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时5%;航班规定离站时间前4小时（含）至航班起飞后10%",
          "cabinName": "J"
        },
        {
          "cabininfo": "2",
          "fare": "5380",
          "changeRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前5%;航班规定离站时间前7天（含）至航班规定离站时间前48小时10%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时10%;航班规定离站时间前4小时（含）至航班起飞后15%",
          "cabinRate": "2.50",
          "updateTime": null,
          "basicCabinTypeCn": "公务舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "C",
          "refundRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前5%;航班规定离站时间前7天（含）至航班规定离站时间前48小时10%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时15%;航班规定离站时间前4小时（含）至航班起飞后20%",
          "cabinName": "C"
        },
        {
          "cabininfo": "2",
          "fare": "3870",
          "changeRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前5%;航班规定离站时间前7天（含）至航班规定离站时间前48小时10%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时10%;航班规定离站时间前4小时（含）至航班起飞后15%",
          "cabinRate": "1.80",
          "updateTime": null,
          "basicCabinTypeCn": "公务舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "C",
          "refundRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前5%;航班规定离站时间前7天（含）至航班规定离站时间前48小时10%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时15%;航班规定离站时间前4小时（含）至航班起飞后20%",
          "cabinName": "D"
        },
        {
          "cabininfo": "1",
          "fare": "1760",
          "changeRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前5%;航班规定离站时间前7天（含）至航班规定离站时间前48小时10%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时10%;航班规定离站时间前4小时（含）至航班起飞后15%",
          "cabinRate": "0.82",
          "updateTime": null,
          "basicCabinTypeCn": "公务舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "C",
          "refundRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前5%;航班规定离站时间前7天（含）至航班规定离站时间前48小时10%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时15%;航班规定离站时间前4小时（含）至航班起飞后20%",
          "cabinName": "Q"
        },
        {
          "cabininfo": "A",
          "fare": "2150",
          "changeRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前免费;航班规定离站时间前7天（含）至航班规定离站时间前48小时5%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时5%;航班规定离站时间前4小时（含）至航班起飞后10%",
          "cabinRate": "1.00",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前免费;航班规定离站时间前7天（含）至航班规定离站时间前48小时5%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时10%;航班规定离站时间前4小时（含）至航班起飞后15%",
          "cabinName": "Y"
        },
        {
          "cabininfo": "A",
          "fare": "2130",
          "changeRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前免费;航班规定离站时间前7天（含）至航班规定离站时间前48小时5%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时5%;航班规定离站时间前4小时（含）至航班起飞后10%",
          "cabinRate": "0.99",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前免费;航班规定离站时间前7天（含）至航班规定离站时间前48小时5%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时10%;航班规定离站时间前4小时（含）至航班起飞后15%",
          "cabinName": "B"
        },
        {
          "cabininfo": "A",
          "fare": "1830",
          "changeRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前5%;航班规定离站时间前7天（含）至航班规定离站时间前48小时10%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时15%;航班规定离站时间前4小时（含）至航班起飞后20%",
          "cabinRate": "0.85",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前10%;航班规定离站时间前7天（含）至航班规定离站时间前48小时15%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时25%;航班规定离站时间前4小时（含）至航班起飞后30%",
          "cabinName": "M"
        },
        {
          "cabininfo": "A",
          "fare": "1680",
          "changeRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前5%;航班规定离站时间前7天（含）至航班规定离站时间前48小时10%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时15%;航班规定离站时间前4小时（含）至航班起飞后20%",
          "cabinRate": "0.78",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前10%;航班规定离站时间前7天（含）至航班规定离站时间前48小时15%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时25%;航班规定离站时间前4小时（含）至航班起飞后30%",
          "cabinName": "E"
        },
        {
          "cabininfo": "A",
          "fare": "1530",
          "changeRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前5%;航班规定离站时间前7天（含）至航班规定离站时间前48小时15%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时25%;航班规定离站时间前4小时（含）至航班起飞后35%",
          "cabinRate": "0.71",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前10%;航班规定离站时间前7天（含）至航班规定离站时间前48小时20%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时35%;航班规定离站时间前4小时（含）至航班起飞后45%",
          "cabinName": "K"
        },
        {
          "cabininfo": "A",
          "fare": "1300",
          "changeRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前5%;航班规定离站时间前7天（含）至航班规定离站时间前48小时15%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时25%;航班规定离站时间前4小时（含）至航班起飞后35%",
          "cabinRate": "0.60",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前10%;航班规定离站时间前7天（含）至航班规定离站时间前48小时20%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时35%;航班规定离站时间前4小时（含）至航班起飞后45%",
          "cabinName": "L"
        },
        {
          "cabininfo": "A",
          "fare": "1150",
          "changeRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前5%;航班规定离站时间前7天（含）至航班规定离站时间前48小时15%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时25%;航班规定离站时间前4小时（含）至航班起飞后35%",
          "cabinRate": "0.53",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前10%;航班规定离站时间前7天（含）至航班规定离站时间前48小时20%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时35%;航班规定离站时间前4小时（含）至航班起飞后45%",
          "cabinName": "N"
        },
        {
          "cabininfo": "A",
          "fare": "1000",
          "changeRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前10%;航班规定离站时间前7天（含）至航班规定离站时间前48小时20%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时45%;航班规定离站时间前4小时（含）至航班起飞后55%",
          "cabinRate": "0.47",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前20%;航班规定离站时间前7天（含）至航班规定离站时间前48小时30%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时65%;航班规定离站时间前4小时（含）至航班起飞后70%",
          "cabinName": "R"
        },
        {
          "cabininfo": "A",
          "fare": "900",
          "changeRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前10%;航班规定离站时间前7天（含）至航班规定离站时间前48小时20%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时45%;航班规定离站时间前4小时（含）至航班起飞后55%",
          "cabinRate": "0.42",
          "updateTime": null,
          "basicCabinTypeCn": "经济舱",
          "createBy": null,
          "flightNo": null,
          "createTime": null,
          "updateBy": null,
          "sysOrgCode": null,
          "id": null,
          "basicCabinType": "Y",
          "refundRuleCn": "航班规定离站时间30天之前免费;航班规定离站时间30天（含）至航班规定离站时间7天之前20%;航班规定离站时间前7天（含）至航班规定离站时间前48小时30%;航班规定离站时间前48小时（含）至航班规定离站时间前4小时65%;航班规定离站时间前4小时（含）至航班起飞后70%",
          "cabinName": "S"
        }
      ],
      "updateTime": null,
      "airportTax": 50,
      "tpm": 1178,
      "meal": true,
      "fuelTax": 70,
      "stopNumber": "0",
      "mealType_dictText": "",
      "createBy": null,
      "flightNo": "MU5100",
      "createTime": null,
      "flyTime": "2:20",
      "minPrice": 900,
      "sysOrgCode": null,
      "arrTime": "0920",
      "dstCity": "PVG"
    })
    return {
      result: list,
      "success": true,
      "message": "操作成功！",
      "code": 200,
      "total": 76,
      "size": 76,
      "current": 1,
      "orders": [],
      "optimizeCountSql": true,
      "hitCount": false,
      "countId": null,
      "maxLimit": null,
      "searchCount": true,
      "pages": 1
    }
  }
}