<template>
  <div class="content-class">
    <div v-if="false">
      <el-row :gutter="20" class="search-row">
        <el-col :span="4" style="text-align: left">
          <img
            src="https://www.baidu.com/img/flexible/logo/pc/result.png"
            style="height: 30px; width: auto"
          />
        </el-col>
        <el-col :span="12" style="text-align: left">
          <el-input
            placeholder="请输入内容"
            v-model="searchInput"
            class="input-with-select"
            style="width: 80%"
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="8" style="text-align: right">
          <span> 电话号码：12345678920 </span>
        </el-col>
      </el-row>
    </div>
    <div class="menu-class" v-if="false">
      <el-menu
        :default-active="activeIndex2"
        class="el-menu-demo"
        menu-trigger="hover"
        mode="horizontal"
        @select="handleSelect"
        background-color="#2577e3"
        text-color="#fff"
        active-text-color="#fff"
      >
        <el-submenu index="1" :popper-append-to-body="false">
          <template slot="title">汽车·船</template>
        </el-submenu>
        <el-menu-item index="2" :popper-append-to-body="false">
          <template slot="title">机票</template>
        </el-menu-item>
        <el-menu-item index="3" :popper-append-to-body="false">
          <template slot="title">目的地</template>
        </el-menu-item>
        <el-menu-item index="4" :popper-append-to-body="false">
          <template slot="title">更多</template>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="menu-btn" v-if="false">
      <el-link icon="el-icon-truck">汽车票</el-link>
      <el-link icon="el-icon-truck">汽车票</el-link>
      <el-link icon="el-icon-truck">汽车票</el-link>
      <el-link icon="el-icon-truck">汽车票</el-link>
    </div>
    <div class="ticket-sale" v-if="false">
      <el-form
        ref="ticketForm"
        :inline="true"
        :model="ticketForm"
        label-position="left"
        class="demo-form-inline"
        label-width="100px"
      >
        <el-form-item label="出发城市">
          <!-- <el-input v-model="ticketForm.start" placeholder="请输入出发城市"></el-input> -->
          <el-select
            v-model="ticketForm.start"
            :popper-append-to-body="false"
            filterable
            remote
            reserve-keyword
            default-first-option
            placeholder="请选择出发城市"
            :remote-method="remoteMethodStart"
            :loading="loading"
            @change="searchStart"
          >
            <el-option
              v-for="item in startList"
              :key="item.depotId"
              :label="item.depotName"
              :value="item.depotName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <img class="change-image" src="../assets/img/change.png" /> -->
        <el-form-item label="到达城市">
          <!-- <el-input v-model="ticketForm.end" placeholder="请输入到达城市"></el-input> -->
          <el-select
            v-model="ticketForm.end"
            :popper-append-to-body="false"
            filterable
            remote
            reserve-keyword
            default-first-option
            placeholder="请选择到达城市"
            :remote-method="remoteMethodEnd"
            :loading="loading"
            @change="searchEnd"
            style="width: 252px"
          >
            <el-option
              v-for="item in endList"
              :key="item.depotId"
              :label="item.depotName"
              :value="item.depotName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择日期">
          <!-- <el-input v-model="ticketForm.date" placeholder="请选择日期"></el-input> -->
          <el-date-picker
            class="date-choose"
            @change="getDateChange"
            v-model="ticketForm.date"
            :clearable="false"
            type="date"
            placeholder="选择日期"
            :picker-options="datePicker"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="ticket-case">
      <el-tabs
        type="border-card"
        v-model="activeName"
        :stretch="true"
        @tab-click="handleClick"
        :before-leave="beforeLeave"
      >
        <el-tab-pane
          class="forward-class"
          name="toForward"
          :disabled="disabled"
        >
          <span slot="label">
            <i class="el-icon-caret-left"></i>
          </span>
        </el-tab-pane>
        <el-tab-pane
          v-for="(item, index) in list"
          :key="index"
          :name="item.time"
        >
          <span slot="label" @click="chooseCurrent(item)">
            <div>
              {{ item.time.split("-")[1] + "-" + item.time.split("-")[2] }}
            </div>
            <div>{{ item.week }}</div>
          </span>
          <!-- 这里面是内容 -->
          <!-- {{item.time}} -->
          <div v-if="false">
            <div class="choose-time">
              <span>出发时间</span>
              <el-checkbox-button
                class="no-limit"
                v-model="noLimitTime"
                @change="limitTime()"
                >不限
              </el-checkbox-button>
              <el-checkbox-group
                class="time-check"
                v-model="checkedTime"
                @change="timeChange()"
              >
                <el-checkbox
                  v-for="item in timeOption"
                  :label="item"
                  :key="item"
                  >{{ item }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="begin-station">
              <span>出发车站</span>
              <el-checkbox-button
                class="no-limit"
                v-model="noLimitBegin"
                @change="limitBegin()"
                >不限
              </el-checkbox-button>
              <el-checkbox-group
                class="time-check"
                v-model="checkedBegin"
                @change="beginChange()"
              >
                <el-checkbox
                  v-for="item in beginOption"
                  :label="item"
                  :key="item"
                  >{{ item }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="end-station">
              <span>到达车站</span>
              <el-checkbox-button
                class="no-limit"
                v-model="noLimitEnd"
                @change="limitEnd()"
                >不限
              </el-checkbox-button>
              <el-checkbox-group
                class="time-check"
                v-model="checkedEnd"
                @change="endChange()"
              >
                <el-checkbox
                  v-for="item in endOption"
                  :label="item"
                  :key="item"
                  >{{ item }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
            <el-table
              :data="tableData"
              style="width: 100%"
              :default-sort="{ prop: 'date', order: 'descending' }"
              :header-cell-style="{ color: '#000000D8', background: '#f0f0f0' }"
              :cell-style="{ height: '50px', padding: '4px 0' }"
            >
              <el-table-column
                prop="time"
                label="发/到时间"
                sortable
                width="180"
              >
              </el-table-column>
              <el-table-column label="发/到站" width="180">
                <template slot-scope="scope">
                  <div class="">
                    <i class="el-icon-setting"></i
                    ><span style="margin-left: 5px">{{ scope.row.begin }}</span>
                  </div>
                  <div class="">
                    <i class="el-icon-star-off"></i
                    ><span style="margin-left: 5px">{{ scope.row.end }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="consuming" label="车型/耗时">
              </el-table-column>
              <el-table-column prop="price" label="票价" sortable width="180">
              </el-table-column>
              <el-table-column align="center">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="warning"
                    @click="handleEdit(scope.$index, scope.row)"
                  >
                    订票</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane name="toNext">
          <span slot="label">
            <i class="el-icon-caret-right"></i>
          </span>
        </el-tab-pane>
      </el-tabs>
      <span class="more" @click="showRili = !showRili"
        ><i class="el-icon-date"></i>&nbsp;&nbsp;更多日期</span
      >
      <div class="ticket-tips" v-if="false">
        <img
          style="width: 300px"
          src="https://pages.c-ctrip.com/bus-resource/ditui/wxbanner-2.png"
        />
        <div class="ticket-point">
          <div class="point-title"><span>购票指南</span></div>
          <div>
            <div class="point-content">
              <div class="point-content-title">1.在线预订汽车票流程</div>
              <span class="point-content-detail">
                输入出发到达城市及日期→选择合适的车次并点击预订→填写乘车人和取票人信息→提交订单→支付订单→出票
              </span>
            </div>
            <div class="point-content">
              <div class="point-content-title">2.在线预订汽车票流程</div>
              <span class="point-content-detail">
                输入出发到达城市及日期→选择合适的车次并点击预订→填写乘车人和取票人信息→提交订单→支付订单→出票
              </span>
            </div>
            <div class="point-content">
              <div class="point-content-title">3.在线预订汽车票流程</div>
              <span class="point-content-detail">
                输入出发到达城市及日期→选择合适的车次并点击预订→填写乘车人和取票人信息→提交订单→支付订单→出票
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-calendar v-show="showRili" v-model="riliData" @input="chooseRili">
      <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
      <template slot="dateCell" slot-scope="{ date, data }">
        <p :class="data.isSelected ? 'is-selected' : ''">
          {{ data.day.split("-").slice(1).join("-") }}
          {{ data.isSelected ? "✔️" : "" }}
        </p>
      </template>
    </el-calendar>
  </div>
</template>

<script>
import airPortList from '../components/airPortList';
// import {getQuickMenuList} from '@/api/ticket/ticketApi.js'
const checkedTimeArr = ['00:00-06:00', '06:00-12:00', '12:00-18:00', '18:00-24:00']
const beginArr = ['福州客运站', '福州西站']
const endArr = ['宁德北站', '宁德南站']
import moment from 'moment'
export default {
  components: {
    airPortList
  },
  data () {
    return {
      riliData: '',
      showRili: false,
      searchInput: '',
      activeIndex2: '1',
      activeName: '',
      ticketForm: {
        start: '',
        end: '',
        date: ''
      },
      list: [],
      disabled: false,
      noLimitTime: true,
      noLimitBegin: true,
      noLimitEnd: true,
      checkedTime: [],
      checkedBegin: [],
      checkedEnd: [],
      timeOption: checkedTimeArr,
      beginOption: beginArr,
      endOption: endArr,
      tableData: [{
        time: "15:00",
        begin: "福州",
        end: "厦门",
        consuming: "约13小时",
        price: "￥280"
      }, {
        time: "15:00",
        begin: "福州",
        end: "厦门",
        consuming: "约13小时",
        price: "￥280"
      }, {
        time: "15:00",
        begin: "福州",
        end: "厦门",
        consuming: "约13小时",
        price: "￥280"
      }, {
        time: "15:00",
        begin: "福州",
        end: "厦门",
        consuming: "约13小时",
        price: "￥280"
      }],
      // 起始站数据
      startList: [{
        depotId: 1,
        depotName: '福州站'
      }, {
        depotId: 2,
        depotName: '霞浦站'
      }, {
        depotId: 3,
        depotName: '宁德站'
      }],
      loading: false,
      // 到达站数据
      endList: [{
        depotId: 1,
        depotName: '福安站'
      }, {
        depotId: 2,
        depotName: '厦门站'
      }, {
        depotId: 3,
        depotName: '福州站'
      }],
      datePicker: this.getDatePicker(),
    }
  },
  created () {
    // 登录首页传过来的数据
    let currentData = this.$route.params.currentData;
      console.log(currentData);
    if (currentData) {
      this.ticketForm.start = currentData.startStation;
      this.ticketForm.end = currentData.endStation;
      this.ticketForm.date = currentData.date;
    }
    // 页面刚刚进来的时候，购票时间轴
    this.getTimeList();
    // 传过来的值为空
    if (this.ticketForm.date == "") {
      this.activeName = this.list[0].time;
      console.log("进入1");
      console.log(this.activeName);
    } else {
      // 传过来的时间为当前日期
      if (this.ticketForm.date == moment(new Date()).format("YYYY-MM-DD")) {
        this.activeName = this.list[0].time;
      } else { // 传过来的时间不是当前日期
        this.activeName = this.list[1].time;
      }
        console.log("进入2");
        console.log(this.activeName);
    }
    // 如果时间轴第一个位置是当天日期，则向后一天那个按钮不能点击
    if (this.list[0].time == moment(new Date()).format("YYYY-MM-DD")) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  },
  methods: {
    chooseRili() {
      const data1 = moment(this.riliData).format("YYYY-MM-DD")
      const data2 = moment(new Date()).format("YYYY-MM-DD")
      if(new Date(data1) < new Date(data2)) {
        this.$message.warning('请选择今天或之后的日期！')
        this.riliData = new Date();
      } else {
        const data = moment(this.riliData).format("YYYY-MM-DD")
        this.handleClick({ name: data})
        this.showRili = false;
      }
    },
    chooseCurrent (item) {
      console.log(item, 233);
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath);
    },
    // 起始站 - 模糊搜索
    remoteMethodStart (query) {
      // if (query !== '') {
      // 	this.loading = true;
      // 	let data = {
      // 		inputStr: query
      // 	}
      // 	this.startList = [];
      // 	getStartData(data).then(res => {
      // 		if (res.code == 200) {
      // 			this.loading = false;
      // 			this.startList.push(...res.data.records);
      // 		} else {
      // 			this.loading = false;
      // 		}
      // 	}).catch(err => {
      // 		console.log(err);
      // 	})
      // } else {
      // 	this.startList = [];
      // }
    },
    // 到达站 - 模糊搜索
    remoteMethodEnd (query) {
      // if (query !== '') {
      // 	this.loading = true;
      // 	let data = {
      // 		inputStr: query
      // 	}
      // 	this.endList = [];
      // 	getStartData(data).then(res => {
      // 		if (res.code == 200) {
      // 			this.loading = false;
      // 			this.endList.push(...res.data.records);
      // 		} else {
      // 			this.loading = false;
      // 		}
      // 	}).catch(err => {
      // 		console.log(err);
      // 	})
      // } else {
      // 	this.endList = [];
      // }
    },
    searchStart (e) {
      console.log("你选中的是", e);
      console.log("sdkajdasljd", this.saleForm.startStation);
    },
    searchEnd (e) {
      console.log("你选中的是", e);
    },
    // tab选项卡点击事件
    handleClick (tab, event) {
      // console.log(tab, event);
      let arrTime = [];
      let arrWeek = [];
      let newArray = [];
      // 左边的按钮
      if (tab.name == "toForward") {
        console.log(tab.name, 'tab.name')
        // 如果数组第一个是当天日期，则左边的按钮不能点击
        if (this.list[0].time == moment(new Date()).format("YYYY-MM-DD")) {
          this.activeName = this.list[0].time;
          this.disabled = true;
        } else {
          // 如果不是当天日期，则可以点击，并且是想前减一天
          this.disabled = false;
          let listFirst = this.list[0].time;
          for (var index = -1; index < 6; index++) {
            arrTime.push(this.getNextDate(listFirst, index));
            arrWeek.push(this.getWeek(listFirst, index));
          }
          for (var i = 0; i < arrTime.length; i++) {
            let obj = {
              time: arrTime[i],
              week: arrWeek[i]
            };
            newArray.push(obj)
          }
          this.list = newArray;
          this.activeName = this.list[1].time;
        }
        // 右边的按钮，点击加一天
      } else if (tab.name == "toNext") {
        console.log("toNext", this.activeName, this.list[0].time, this.list[1].time);
        this.disabled = false;
        if (this.activeName == this.list[0].time) {
          this.activeName = this.list[1].time
        } else {
          let listFirst = this.list[1].time;
          for (var index = 0; index < 7; index++) {
            arrTime.push(this.getNextDate(listFirst, index));
            arrWeek.push(this.getWeek(listFirst, index));
          }
          for (var i = 0; i < arrTime.length; i++) {
            let obj = {
              time: arrTime[i],
              week: arrWeek[i]
            };
            newArray.push(obj)
          }
          this.list = newArray;
          this.activeName = this.list[1].time;
        }
        // 如果点击的是当天的日期，则显示在第一个，否则其他的都是显示在第二个tab
      } else if (tab.name == moment(new Date()).format("YYYY-MM-DD")) {
        // this.getTimeList();
        this.activeName = this.list[0].time;
        this.disabled = true;
      } else {
        this.disabled = false;
        for (var index = -1; index < 6; index++) {
          arrTime.push(this.getNextDate(tab.name, index));
          arrWeek.push(this.getWeek(tab.name, index));
        }
        for (var i = 0; i < arrTime.length; i++) {
          let obj = {
            time: arrTime[i],
            week: arrWeek[i]
          };
          newArray.push(obj)
        }
        this.list = newArray;
        this.activeName = this.list[1].time;
      }
      this.$emit('dataChange', this.activeName);
      // console.log(this.activeName, 'this.activeName');
    },
    /* 活动标签切换时触发 */
    beforeLeave (currentName, oldName) {
      var self = this;
      //重点，如果name是add，则什么都不触发
      if (currentName == "toForward") {
        return false
      } else if (currentName == "toNext") {
        return false
      } else {
        // this.currentIndex = currentName;
      }
    },
    // 获取七天的月日
    getNextDate (date, item) {
      var dd = new Date(date);
      dd.setDate(dd.getDate() + item);
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      let day = dd.getDay();
      // let weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
      // let week = weeks[day];
      return y + "-" + m + "-" + d;
    },
    // 获取周几
    getWeek (dateTime, index) {
      let time = new Date(dateTime);
      // 天数
      time.setDate(time.getDate() + index)
      let day = time.getDay();
      let weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
      let week = weeks[day];
      return week;
    },
    // 获取时间列表
    getTimeList () {
      let timeArr = [];
      let weekArr = [];
      let newArr = [];
      // 如果传过来的数据为空，则默认从当天日期开始排七天
      if (this.ticketForm.date == "") {
        for (var index = 0; index < 7; index++) {
          var date = moment(new Date()).format("YYYY-MM-DD");
          // console.log(date, 'datedatedatedate')
          timeArr.push(this.getNextDate(date, index));
          weekArr.push(this.getWeek(date, index));
        }
      } else {
        // 传过来的数据不为空，并且是当天日期的，也是从当天日期开始排七天
        if (this.ticketForm.date == moment(new Date()).format("YYYY-MM-DD")) {
          for (var index = 0; index < 7; index++) {
            var date = this.ticketForm.date;
            timeArr.push(this.getNextDate(date, index));
            weekArr.push(this.getWeek(date, index));
          }
          // 传过来的数据不为空，并且不是当天日期的，从这个日期的前一天到后面的六天
        } else {
          for (var index = -1; index < 6; index++) {
            var date = this.ticketForm.date;
            timeArr.push(this.getNextDate(date, index));
            weekArr.push(this.getWeek(date, index));
          }
        }
      }
      // console.log(timeArr);
      // console.log(weekArr);
      for (var i = 0; i < timeArr.length; i++) {
        let obj = {
          time: timeArr[i],
          week: weekArr[i]
        };
        newArr.push(obj)
      }
      this.list = newArr;
      // this.activeName = this.list[1].time
    },
    // 选择时间不限
    limitTime () {
      if (this.noLimitTime) this.checkedTime = [];
    },
    // 选择时间
    timeChange () {
      console.log("选择的时间是", this.checkedTime);
      let value = this.checkedTime
      if (value.length == "") {
        this.noLimitTime = true;
      } else {
        this.noLimitTime = false;
      }
    },
    // 选择出发不限
    limitBegin () {
      if (this.noLimitBegin) this.checkedBegin = [];
    },
    // 选择出发
    beginChange () {
      let value = this.checkedBegin;
      if (value.length == "") {
        this.noLimitBegin = true;
      } else {
        this.noLimitBegin = false;
      }
    },
    // 选择到达不限
    limitEnd () {
      if (this.noLimitEnd) this.checkedEnd = [];
    },
    // 选择到达
    endChange () {
      let value = this.checkedEnd;
      if (value.length == "") {
        this.noLimitEnd = true;
      } else {
        this.noLimitEnd = false;
      }
    },
    // 订票按钮
    handleEdit (index, row) {
      console.log(index, row);
    },
    // 选择时间
    getDateChange () {

    },
    // 查询
    onSubmit () {
      // this.getTimeList();
      let timeArr = [];
      let weekArr = [];
      let newArr = [];
      // 点击查询按钮是你选择的日期
      let dateForm = moment(this.ticketForm.date).format("YYYY-MM-DD")
      console.log("时间", dateForm);
      // 如果选择的日期是当天日期，则从第一个开始，否则从第二个开始
      if (dateForm == moment(new Date()).format("YYYY-MM-DD")) {
        this.disabled = true;
        this.list = [];
        console.log("日期选择今天的", this.list);
        for (var index = 0; index < 7; index++) {
          timeArr.push(this.getNextDate(dateForm, index));
          weekArr.push(this.getWeek(dateForm, index));
        }
        for (var i = 0; i < timeArr.length; i++) {
          let obj = {
            time: timeArr[i],
            week: weekArr[i]
          };
          newArr.push(obj)
        }
        this.list = newArr;
        this.activeName = this.list[0].time
      } else {
        this.disabled = false;
        for (var index = -1; index < 6; index++) {
          timeArr.push(this.getNextDate(dateForm, index));
          weekArr.push(this.getWeek(dateForm, index));
        }
        for (var i = 0; i < timeArr.length; i++) {
          let obj = {
            time: timeArr[i],
            week: weekArr[i]
          };
          newArr.push(obj)
        }
        this.list = newArr;
        this.activeName = this.list[1].time
      }
    },
    // 小于今天的时间不能选
    getDatePicker () {
      return {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7 //开始时间不选时，结束时间最大值小于等于当天 如果没有后面的-8.64e7就是不可以选择今天的
        }
      }
    }
  }
}
</script>

<style lang="less" scoped="scoped">
/deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background: rgb(0, 134, 246);
  color: #ffffff;
}
/deep/ .el-tabs--border-card {
  border: unset;
  box-shadow: unset;
}

/deep/ .el-tabs--border-card > .el-tabs__header {
  // border: 1px solid #dcdfe6 !important;
}

/deep/ .el-tabs--border-card > .el-tabs__content {
  padding: 0;
}

.content-class {
  position: relative;
}

.search-row {
  margin: 0 auto !important;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 980px;
  max-width: 1180px;
  text-align: center;
}

.menu-class {
  background-color: #2577e3 !important;
  margin-top: 20px;
  height: 40px;
  line-height: 40px;
}

.el-menu-demo {
  min-width: 980px !important;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0;
  font-size: 15px;
  background-color: #2577e3 !important;
}

/deep/ .el-menu--horizontal > .el-menu-item {
  height: 40px;
  line-height: 40px;
}

/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 40px;
  line-height: 40px;
}

/deep/ .el-submenu__title i {
  color: #ffffff;
}

.menu-btn {
  min-width: 980px !important;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0;
  font-size: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  border: 1px solid #2577e3;
  border-top: none !important;
}

.sale-content {
  min-width: 980px !important;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.sale-image {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/deep/ .el-link {
  margin-left: 20px;
}

.ticket-sale {
  min-width: 980px !important;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0;
  font-size: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.ticket-case {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  background-color: #fff;
  .more {
    margin-left: 2%;
    cursor: pointer;
    &:hover {
      color: #2577e3;
    }
  }
  // min-width: 980px !important;
  // max-width: 1180px;
  // margin: 0 auto;
  // padding: 0;
  // font-size: 15px;
  // padding-bottom: 10px;
  // display: flex;
  // justify-content: space-between;
}

.change-image {
  width: 20px;
  cursor: pointer;
  padding-top: 12px;
  margin-right: 10px;
}

/deep/ .el-tabs {
  width: 90%;
}

/deep/ .el-tabs__item {
  border-right: 1px solid #dcdfe6 !important;
  height: 55px;
  line-height: 25px;
}

/deep/ #tab-toForward {
  background: #ffffff;
  border-right: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  display: flex;
  align-items: center;
  padding: 0 10px;
  flex: none;
}

/deep/ #tab-toNext {
  background: #ffffff;
  border-left: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  display: flex;
  align-items: center;
  padding: 0 10px;
  flex: none;
}

.choose-time {
  display: flex;
  align-items: center;
}

.begin-station {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.end-station {
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.no-limit {
  margin-left: 10px;
}

.no-limit /deep/ .el-checkbox-button__inner {
  padding: 2px 5px;
  border: none;
}

.time-check {
  margin-left: 10px;
}

.time-check /deep/ .el-checkbox {
  width: 120px;
}

/deep/.el-table .el-table__cell {
  padding: 4px 0;
  height: 40px;
}

.ticket-tips {
  margin-left: 10px;
}

.ticket-point {
  width: 300px;
  border: 1px solid #dcdfe6;
}

.point-title {
  font-size: 16px;
  background-color: #f4fcf8;
  padding: 10px 5px;
}

.point-content {
  padding: 5px 10px;
  font-size: 12px;
}

.point-content-detail {
  margin-top: 5px;
  display: block;
  color: #666;
}
</style>

