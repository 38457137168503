<template>
  <div>
    <el-tabs v-model="activeName1">
      <el-tab-pane label="ABCDEF" name="1">
        <div class="citys">
          <div class="title">A</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList1.A[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
        <div class="citys">
          <div class="title">B</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList1.B[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>

        <div class="citys">
          <div class="title">C</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList1.C[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>

        <div class="citys">
          <div class="title">D</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList1.D[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>

        <div class="citys">
          <div class="title">E</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList1.E[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>

        <div class="citys">
          <div class="title">F</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList1.F[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="GHIJ" name="2">
        <div class="citys">
          <div class="title">G</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList2.G[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>

        <div class="citys">
          <div class="title">H</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList2.H[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>

        <div class="citys">
          <div class="title">I</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList2.I[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>

        <div class="citys">
          <div class="title">J</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList2.J[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="KLMN" name="3">
        <div class="citys">
          <div class="title">K</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList3.K[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>

        <div class="citys">
          <div class="title">L</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList3.L[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>

        <div class="citys">
          <div class="title">M</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList3.M[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>

        <div class="citys">
          <div class="title">N</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList3.N[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="PQRSTUVW" name="4">
        <div class="citys">
          <div class="title">P</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList4.P[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
        <div class="citys" v-if="groupList4.Q.length !== 0">
          <div class="title">Q</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList4.Q[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
        <div class="citys" v-if="groupList4.R.length !== 0">
          <div class="title">R</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList4.R[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
        <div class="citys">
          <div class="title">S</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList4.S[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
        <div class="citys">
          <div class="title">T</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList4.T[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
        <div class="citys">
          <div class="title">U</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList4.U[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
        <div class="citys" v-if="groupList4.V.length !== 0">
          <div class="title">V</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList4.V[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
        <div class="citys">
          <div class="title">W</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList4.W[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="XYZ" name="5">
        <div class="citys">
          <div class="title">X</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList5.X[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
        <div class="citys">
          <div class="title">Y</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList5.Y[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
        <div class="citys">
          <div class="title">Z</div>
          <div class="list">
            <span
              v-for="(item, index) in groupList5.Z[0].List"
              :key="index"
              @click="chooseCity(item)"
              :title="item.airportName"
              >{{ item.cityName }}</span
            >
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { getData233 } from '../api';
export default {
  data () {
    return {
      groupList1: {},
      groupList2: {},
      groupList3: {},
      groupList4: {},
      groupList5: {},
      activeName1: '1',
    }
  },
  mounted () {
    const arr = JSON.parse(localStorage.getItem('allCityList'));
    if (arr) {
      this.pinyinFunt(arr)
    } else {
      getData233().then(res => {
        this.pinyinFunt(res.data.result)
        localStorage.setItem('allCityList', JSON.stringify(res.data.result));
      })
    }

  },
  methods: {
    chooseCity (item) {
      // console.log(item, 'city');
      this.$emit('choose', item)
    },
    pinyinFunt (arr) {
      let dataArr = [];
      arr.map((mapItem) => {
        let index = dataArr.findIndex((item) => item.name === mapItem.citySzmPinyin);
        if (index === -1) {
          dataArr.push({
            name: mapItem.citySzmPinyin,
            List: [mapItem],
          });
        } else {
          dataArr[index].List.push(mapItem);
        }
      });
      let newArr = dataArr;
      this.fenzu(newArr);
    },
    fenzu (newArr) {
      const groupIds1 = ['A', 'B', 'C', 'D', 'E', 'F'];
      const groupIds2 = ['G', 'H', 'I', 'J'];
      const groupIds3 = ['K', 'L', 'M', 'N'];
      const groupIds4 = ['P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W'];
      const groupIds5 = ['X', 'Y', 'Z'];

      // 使用reduce方法进行分组
      const groupedItems1 = groupIds1.reduce((acc, groupId) => {
        acc[groupId] = newArr.filter(item => item.name === groupId);
        return acc;
      }, {});
      const groupedItems2 = groupIds2.reduce((acc, groupId) => {
        acc[groupId] = newArr.filter(item => item.name === groupId);
        return acc;
      }, {});
      const groupedItems3 = groupIds3.reduce((acc, groupId) => {
        acc[groupId] = newArr.filter(item => item.name === groupId);
        return acc;
      }, {});
      const groupedItems4 = groupIds4.reduce((acc, groupId) => {
        acc[groupId] = newArr.filter(item => item.name === groupId);
        return acc;
      }, {});
      const groupedItems5 = groupIds5.reduce((acc, groupId) => {
        acc[groupId] = newArr.filter(item => item.name === groupId);
        return acc;
      }, {});
      // console.log(groupedItems1, groupedItems2, groupedItems3, groupedItems4, groupedItems5);
      localStorage.setItem('group1', JSON.stringify(groupedItems1));
      localStorage.setItem('group2', JSON.stringify(groupedItems2));
      localStorage.setItem('group4', JSON.stringify(groupedItems3));
      localStorage.setItem('group4', JSON.stringify(groupedItems4));
      localStorage.setItem('group5', JSON.stringify(groupedItems5));
      this.groupList1 = groupedItems1;
      this.groupList2 = groupedItems2;
      this.groupList3 = groupedItems3;
      this.groupList4 = groupedItems4;
      this.groupList5 = groupedItems5;
    },
  }
}
</script>
<style lang="less" scoped>
.citys {
  width: 100%;
  display: flex;
  .title {
    font-weight: bold;
    padding-top: 5px;
    color: #000;
  }
  .list {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
    span {
      text-align: center;
      width: 80px;
      height: 30px;
      line-height: 30px;
      &:hover {
        background: rgba(0, 134, 246, 0.05);
        border-radius: 4px;
        color: #0086f6;
      }
    }
  }
}
</style>