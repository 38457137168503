<template>
  <div class="orderlist">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="全部订单" name="first">
        <el-card>
          <el-form
            size="small"
            :inline="true"
            ref="form"
            :model="form"
            label-width="80px"
          >
            <el-form-item label="订单号">
              <el-input v-model="form.orderId"></el-input>
            </el-form-item>
            <el-form-item label="预订日期">
              <el-col :span="11">
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  v-model="form.date1"
                  style="width: 100%"
                ></el-date-picker>
              </el-col>
              <el-col class="line" :span="1">-</el-col>
              <el-col :span="11">
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  v-model="form.date2"
                  style="width: 100%"
                ></el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="form.name" placeholder="订单状态">
                <el-option label="全部" value="0"></el-option>
                <el-option label="有效" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-button type="primary">查询</el-button>
            </el-form-item>
          </el-form>

          <div class="cc">
            <div class="order-list">
              <div class="header">
                订单号：32120266941 预订日期：2024-05-06 <a>删除订单</a>
              </div>
              <div class="content">
                <h3>武汉 — 上海</h3>
                <p>出发日期：2024-05-14 08:20 至 10:00 CZ3823</p>
                <p>出行人：张小文</p>
              </div>
            </div>
            <div class="order-list">
              <div class="header">
                订单号：32120266941 预订日期：2024-05-06 <a>删除订单</a>
              </div>
              <div class="content">
                <h3>武汉 — 上海</h3>
                <p>出发日期：2024-05-14 08:20 至 10:00 CZ3823</p>
                <p>出行人：张小文</p>
              </div>
            </div>
            <div class="order-list">
              <div class="header">
                订单号：32120266941 预订日期：2024-05-06 <a>删除订单</a>
              </div>
              <div class="content">
                <h3>武汉 — 上海</h3>
                <p>出发日期：2024-05-14 08:20 至 10:00 CZ3823</p>
                <p>出行人：张小文</p>
              </div>
            </div>
            <div class="order-list">
              <div class="header">
                订单号：32120266941 预订日期：2024-05-06 <a>删除订单</a>
              </div>
              <div class="content">
                <h3>武汉 — 上海</h3>
                <p>出发日期：2024-05-14 08:20 至 10:00 CZ3823</p>
                <p>出行人：张小文</p>
              </div>
            </div>
            <div class="order-list">
              <div class="header">
                订单号：32120266941 预订日期：2024-05-06 <a>删除订单</a>
              </div>
              <div class="content">
                <h3>武汉 — 上海</h3>
                <p>出发日期：2024-05-14 08:20 至 10:00 CZ3823</p>
                <p>出行人：张小文</p>
              </div>
            </div>
          </div>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="待出票" name="second">待出票</el-tab-pane>
      <el-tab-pane label="已出票" name="third">已出票</el-tab-pane>
      <!-- <el-tab-pane label="待点评" name="fourth">待点评</el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
export default {
  data () {
    return {
      activeName: 'first',
      form: {
        orderId: '',
        name: '',
        date1: '',
        date2: '',
        status: false,
      }
    };
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event);
    }
  }
};
</script>
<style lang="less" scoped>
.cc {
  height: 900px;
  overflow: auto;
}
.order-list {
  max-height: 190px;
  width: 98%;
  border: 1px solid #d9d9d9;
  margin-bottom: 20px;
  &:hover {
    border-color: #2577e3;
    background: #f7fbff;
  }
  .header {
    font-family: Tahoma;
    font-weight: 400;
    color: #333;
    padding-left: 10px;
    padding-top: 8px;
    height: 26px;
    background-color: #f6f6f6;
  }
  .content {
    cursor: pointer;
    padding-left: 30px;
    h3 {
      margin: 8px 0;
    }
    p {
      margin: 5px 0;
    }
  }
}
</style>