<template>
  <div class="main">
    <el-container>
      <!-- <el-aside width="auto">
        <common-asideVue />
      </el-aside> -->
      <el-container>
        <!--
        <el-header><common-header /></el-header>
        -->
        <el-main style="background-color: #eef1f6;margin:0;padding:0;border:none;">
          <router-view></router-view
        ></el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import CommonAsideVue from '@/components/CommonAside.vue'
import CommonHeader from '@/components/CommonHeader.vue'
export default {
  components: {
    CommonAsideVue,
    CommonHeader,
  },
  data () {
    return {}
  }
}
</script>
<style lang="scss" scoped>

</style>

