<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')" fit="cover"></el-image>
            </div>
            <div class="pleft">
                当前位置：<span>首页</span><span>>站内消息</span><span>>站内消息详情</span>
            </div>
        </div>
        <div class="hcgg">
            <!-- <div class="ggtitle">{{ message }}</div> -->
            <div class="ggdate flex">
                <div>发布时间：{{ time }}</div>
                <div class="liulancount">阅读状态：<span v-if="read_status==1">已读</span><span v-else>未读</span></div>
            </div>
            <div class="hengxian"></div>
            <div class="ggcontent" v-html="message"></div>
        </div>

        <div class="copyright">工业和信息化部备案管理系统网站 豫B1-20080191-28</div>
    </div>
</template>

<script>
    import Cookie from "js-cookie";
    import ElImage from "element-ui/packages/image/src/main";
    import { queryxiaoxibyId , updatexiaoxi } from "../api/index";
    export default {
        components: {
            ElImage,
        },
        activated() {
            this.idstr = this.$route.query.xxId;
            this.message = this.$route.query.message;
            this.time = this.$route.query.time;
            this.userinfo = JSON.parse(Cookie.get('userinfo'));
            this.queryxiaoxidetails();
        },
        data() {
            return {
                objxx: {},
                ggContentstr: "",
                form: {
                    date1: "",
                    sendcity: "",
                    arrivalcity: "",
                },
                rules: {
                    mobilephone: [
                        { required: true, message: "请输入手机号", trigger: "blur" },
                        { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确", trigger: "blur" },
                    ],

                    pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
                },
                idstr:"",
                userinfo:"",
                message:"",
                time:"",
                read_status:0
            };
        },
        methods: {
            //根据ID修改公告状态未已读
            updatexiaoxiinfo(idstr) {
                const params = { xxId: idstr };
                updatexiaoxi(params).then(() => { });
            },
            //根据ID查询站内消息详情
            queryxiaoxidetails() {
                // const params = { ggId: idstr };
                queryxiaoxibyId({
                    user_id:this.userinfo.id,
                    messages_id:this.idstr
                }).then((res) => {
                    try {
                        console.log("%c Line:80 🥖 res", "color:#ea7e5c", res);
                        this.read_status=1
                    } catch (error) {
                    console.log("%c Line:70 🍯", "color:#3f7cff", error);
                    }

            });
            },
        },
        name: "gonggaodetails-right",
    };
</script>

<style scoped>
    .weiziiconst {
        width: 24px;
        height: 24px;
    }

    .cdiv {}

    .ggcontent {
        margin-top: 2vh;
        line-height: 30px;
    }

    .hengxian {
        width: 63vw;
        border: 1px solid rgba(0, 0, 0, 0.3);
        margin-top: 2vh;
    }

    .liulancount {
        margin-left: 10vw;
    }

    .ggtitle {
        font-size: 20px;
    }

    .ggdate {
        color: #999999;
        margin-top: 2vh;
    }

    .copyright {
        width: 63vw;
        font-size: 12px;
        text-align: center;
        margin-top: 5vh;
        color: #b6b6b6;
    }

    .mleftmargin {
        margin-left: 1vw;
    }

    .pager {
        margin-top: 5vh;
        margin-left: 10vw;
    }

    .shouye {}

    .sybtn {
        width: 100px;
        height: 35px;
        border: 1px solid #666666;
        text-align: center;
    }

    .btnnodiv {
        margin-left: 1vw;
    }

    .btnno {
        width: 50px;
        height: 35px;
        border: 1px solid #666666;
        text-align: center;
    }

    .commonmtop {
        margin-top: 2vh;
    }

    .btnmargin {
        margin-top: 1vh;
    }

    .btnstyle {
        width: 100px;
        height: 35px;
        background-color: #42b134;
        color: #ffffff;
        border-radius: 5px;
    }

    .operbtn {
        margin-left: 8vw;
    }

    .checizuowei {
        margin-left: 5vw;
    }

    .colorstyle {
        color: #f49200;
        font-weight: bolder;
    }

    .checileixing {
        margin-left: 5vw;
    }

    .checimoney {
        margin-left: 5vw;
    }

    .zwleixing {
        margin-top: 0.1vh;
    }

    .cmtopzw {
        margin-top: 2vh;
    }

    .cxinfo {
        margin-left: 2vw;
    }

    .endcheci {
        margin-left: 3vw;
    }

    .sleft {
        margin-left: 1vw;
    }

    .scheci {
        margin-left: 2vw;
    }

    .startzhan {
        margin-left: 0.5vw;
    }

    .scdiv {
        margin-top: 2vh;
    }

    .sccdate {
        font-size: 20px;
        font-weight: bolder;
        margin-left: 1vw;
    }

    .haoshi {
        margin-left: 3vw;
    }

    .tcontent {
        background-color: #f1f1f1;
        height: 20vh;
        margin-top: 2vh;
        width: 63vw;
        padding-top: 4vh;
    }

    .mleftd {
        margin-left: -2vw;
    }

    .querybtn {
        background-color: #42b134;
        color: #ffffff;
        border-radius: 5px;
        margin-left: -1vw;
    }

    .formstyle {
        padding-top: 2.2vh;
    }

    .container {}

    .header {
        font-size: 12px;
    }

    .flex {
        display: flex;
    }

    .weiziicon {}

    .pleft {
        margin-left: 0.5vw;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
    }

    .hcgg {
        width: calc(88% - 0px - 10px) !important;
        margin-top: 2vh;
    }
</style>
