<template>
  <!-- 机票列表订票详情列表 -->
  <div class="airportListDesc">
    <el-tabs :value="'Y'">
      <el-tab-pane
        :label="
          itemW.basicCabinType === 'C'
            ? '公务舱'
            : itemW.basicCabinType === 'Y'
            ? '经济舱'
            : itemW.basicCabinType === 'F'
            ? '头等舱'
            : itemW.basicCabinType === 'S'
            ? '超级经济舱'
            : itemW.basicCabinType
        "
        :name="itemW.basicCabinType"
        v-for="(itemW, index) in computedResult"
        :key="index"
      >
        <!-- {{ itemW.List }} -->
        <div
          class="list-airposts"
          v-for="(item, index) in itemW.List"
          :key="index"
        >
          <el-row>
            <el-col :span="5">
              <div class="cont">
                <div class="l"></div>
                <div class="r"></div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="timeRange">
                <span class="help" :title="item.refundRuleCn">退票规定</span>
                <el-divider direction="vertical"></el-divider>
                <span class="help" :title="item.changeRuleCn">改签规定</span>
                <el-divider direction="vertical"></el-divider>
                托运行李额20KG&nbsp;&nbsp;行程单
              </div>
            </el-col>
            <el-col :span="3">
              <div class="allTime">
                {{ item.basicCabinTypeCn }}({{ item.cabinName }}舱)
                {{ item.cabinRate }}折
              </div>
            </el-col>
            <el-col :span="3">
              <div class="tag">
                <!-- <el-tag size="mini" type="success">赠租车600元满减券</el-tag>
            <el-tag size="mini" type="success">赠租车600元满减券w2</el-tag> -->
              </div>
            </el-col>
            <el-col :span="5">
              <div class="price">
                <div class="l">
                  <span class="num"
                    ><span style="font-size: 18px">¥</span>{{ item.fare }}</span
                  >
                  <!-- <span class="desc">+¥43出行礼包</span> -->
                </div>
                <div class="r">
                  <el-button class="search" size="small" @click="yuding"
                    >预订</el-button
                  >
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- <div v-for="(item, index) in computedResult" :key="index">
      {{ item.basicCabinType }}
    </div> -->
  </div>
</template>
<script>
import Cookie from 'js-cookie';
export default {
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  computed: {
    computedResult () {
      return this.jisuan(this.list)
    }
  },
  data () {
    return {
      jisuan (arr) {
        let dataArr = [];
        arr.map((mapItem) => {
          let index = dataArr.findIndex((item) => item.basicCabinType === mapItem.basicCabinType);
          if (index === -1) {
            dataArr.push({
              basicCabinType: mapItem.basicCabinType,
              List: [mapItem],
            });
          } else {
            dataArr[index].List.push(mapItem);
          }
        });
        let newArr = dataArr;
        newArr.forEach(v => {
          // console.log(v.List, 665);
          v.List.reverse();
        })
        // console.log(newArr, 7788)
        return newArr;
      }
    }
  },
  methods: {
      //点击预订
    yuding () {
      if (!Cookie.get('phone')) {
        this.$store.commit('collapseFunt');
      } else {
        const params = {
          test: '测试参数'
        }
        this.$router.push({
          name: 'page1',
          params,
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.airportListDesc {
  .el-tabs {
    padding-left: 120px;
  }
}
.list-airposts {
  // margin-top: 30px;
  height: 62px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  font-size: 12px;
  z-index: 3;
  &:hover {
    background-color: #ebf6ff;
    // z-index: 1;
  }
  .cont {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 62px;
    .l {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .r {
      line-height: 24px;
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      font-size: 14px;
      .desc {
        font-size: 12px;
        color: #0086f6;
      }
    }
  }
  .timeRange {
    height: 62px;
    line-height: 62px;
    .help {
      cursor: pointer;
    }
  }

  .allTime {
    line-height: 62px;
  }
  .tag {
    height: 62px;
    // line-height: 29px;
  }
  .price {
    display: flex;
    height: 62px;
    align-items: center;
    justify-content: flex-end;
    .l {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .num {
        color: #ff7d13;
        font-size: 22px;
      }
      .desc {
        color: #ff7d13;
        font-size: 12px;
      }
    }
    .r {
      margin: 0 20px 0 10px;
    }
    .search {
      border: none;
      background: linear-gradient(-90deg, #f70, #ffa50a);
      color: #fff;
    }
    .arrow-up {
      color: #ff9913 !important;
      border-color: #ff9913;
      &:focus,
      &:hover {
        background: transparent;
      }
    }
    .el-button--small {
      padding: 9px;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>