<template>
	<div class="cdiv">
		<div class="slider">
			<el-carousel class="carstyle" :interval="5000">
				<el-carousel-item class="citem" v-for="image in listbanner" :key="image.id">
					<img class="bannerstyle" :src="image.image" alt="" />
				</el-carousel-item>
			</el-carousel>
		</div>

		<div class="gonggao flex">
			<div class="ggleft"><span class="shuxian">|</span><span class="ggspan">公告</span></div>
			<div class="more" title="查看更多" @click="forwardgglist">查看更多</div>
		</div>
		<div class="ggcontent">
			<div class="ggc flex" v-for="(item, index) in listgg" :key="index" @click="forwardggdetails(item.gg_id)">
				<div class="ggcleft">
					{{ item.gg_title }}
				</div>
				<div class="ggcdate">发布时间：{{ item.gg_date.substr(0, 10) }}</div>
				<div class="ggccount">浏览次数：{{ item.gg_count }}</div>
			</div>
		</div>
		<div class="copyright">工业和信息化部备案管理系统网站 豫B1-20080191-28</div>
		<div class="kefudiv" @click="gotokefuurl">
			<div class="kfimg">
				<img class="kfimgs" src="../assets/img/kefu.png" alt="" />
			</div>
			<div class="kfzx kfzxmargin">客服</div>
			<div class="kfzx">咨询</div>
		</div>
	</div>
</template>

<script>
import Cookie from "js-cookie";
import ElImage from "element-ui/packages/image/src/main";
import ElCard from "element-ui/packages/card/src/main";
import { queryslider, querygg, querymemphone, querywebsite } from "../api/index";
export default {
	components: {
		ElCard,
		ElImage,
	},
	data() {
		return {
			listgg: [],
			listbanner: [],
			listdanwei: [],
			objws: {},
			danweistr: "",
			// images: [{ id: 1, url: require('../assets/img/bananer-001.png') },
			// { id: 2, url: require('../assets/img/bananer-001.png') }]
		};
	},
	activated() {
		this.querysliderinfo();
		this.querygonggao();
		// this.querydanwei();
		// this.querywebsiteinfo();
	},
	methods: {
		//查询网站信息
		querywebsiteinfo() {
			const params = {};
			querywebsite(params).then((res) => {
				this.objws = res.data.data.ret[0];
			});
		},
		//打开客服网址
		gotokefuurl() {
			const kefuurl = this.objws.kefuUrl;
			window.open(kefuurl);
		},
		//查询所属单位
		querydanwei() {
			const phonestr = Cookie.get("phoneKey");
			const params = { mobilePhone: phonestr };
			querymemphone(params).then((res) => {
				this.listdanwei = res.data;
				//获取单位名称
				const danweinamestr = res.data.data.ret[0].danwei;
				const deptstr = res.data.data.ret[0].deptName;
				Cookie.set("danweinameKey", danweinamestr);
				Cookie.set("deptKey", deptstr);
			});
		},
		//查询公告信息
		querygonggao() {
			const params = { page: 1 };
			// const p = this.$querystring.stringify(params);
			querygg(params).then((res) => {
				this.listgg = res.data.data.data;
			});
		},
		//查询轮播图
		querysliderinfo() {
			const params = {};
			queryslider(params).then((res) => {
				this.listbanner = res.data.data.toSorted((a, b) => Number(a.weigh) - Number(b.weigh));
			});
		},
		//转向公告详细页面
		forwardggdetails(idstr) {
			//定义一个路径
			const href = this.$router.resolve({
				path: "/gonggaodetails",
				query: { ggId: idstr },
			}).href;
			//打开新的页面 到 href 这个页面
			window.open(href, "_blank");
			// this.$router.push({ path: "/gonggaodetails", query: { ggId: idstr } });
		},
		//查看更多
		forwardgglist() {
			this.$router.push("/gonggaolist");
		},
	},
	name: "common-right",
};
</script>

<style scoped>
.kfzxmargin {
	margin-top: 1vh;
}

.kefudiv {
	position: fixed;
	top: 60vh;
	right: 0px;
	width: 72px;
	height: 136px;
	background-color: rgba(66, 177, 52, 1);
	z-index: 30000;
	text-align: center;
}

.kfimgs {
	margin-top: 2vh;
	width: 44px;
	height: 44px;
}

.kfzx {
	color: #ffffff;
	font-family: MicrosoftYaHei;
	font-size: 16px;
}

.shuxian {
	width: 6px;
	height: 18px;
	background: #000000;
}

.cdiv {
	/* margin-top: calc(4.6vh - 20px); */
	/*    margin-top: -20px;
    margin-left: -20px; */
}

.slider {
	margin-top: -20px;
	margin-left: -20px;
	height: 450px;
}

.carstyle {
	height: 450px;
}

.citem {
	width: 100%;
	height: 450px;
}

.bannerstyle {
	width: 100%;
	height: 450px;
}

.gonggao {
	margin-top: 10px;
	margin-left: 0px;
	margin-right: 10px;
}

.flex {
	display: flex;
}

.ggspan {
	margin-left: 0.5vw;
	font-family: MicrosoftYaHei;
	font-weight: bold;
	font-size: 20px;
	color: #000000;
}

.ggleft {
	margin-right: 10px;
	flex: 1;
}

.more {
	font-family: MicrosoftYaHei;
	font-weight: 400;
	font-size: 16px;
	color: #000000;
	justify-content: right;
}

.ggcontent {
	margin-top: 2vh;
}

.ggc {
	background-color: #f1f1f1;
	margin-left: 0px;
	width: calc(100% - 0px - 0px) !important;
	height: 8vh;
	line-height: 8vh;
	vertical-align: middle;
	font-size: 13px;
	margin-top: 1vh;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	padding: 0 20px;
	box-sizing: border-box;
}

.ggcleft {
	padding-left: 1vw;
	font-family: MicrosoftYaHei;
	font-weight: 400;
	font-size: 16px;
	color: #000000;
	overflow: hidden;
	width: 68%;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ggcdate {
	color: #999999;
	font-family: MicrosoftYaHei;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
	width: 20%;
}

.ggccount {
	width: 12%;
	font-family: MicrosoftYaHei;
	font-weight: 400;
	font-size: 14px;
	color: #999999;
}

.copyright {
	width: calc(88% - 0px - 10px) !important;
	font-size: 12px;
	text-align: center;
	margin-top: 2vh;
	color: #b6b6b6;
}
</style>
