<template>
  <div class="header">
    <div class="l-content">
      <!-- <el-button
        icon="el-icon-menu"
        size="small"
        @click="collapseChange"
      ></el-button> -->
      <span class="breadcrumb" @click="goHome">
           <el-image class="logobai" :src="require('../assets/img/logo_bai.png')"
                     fit="cover"></el-image>
      </span>
    </div>
    <div class="r-content">
      <el-dropdown class="r-contents" @command="handleClick" trigger="click" v-show="user">
        <el-button style="color:#FFFFFF;" type="text"
          >您好！{{ user }} <i class="el-icon-arrow-down"></i
        ></el-button>
        <el-dropdown-menu slot="dropdown">
          <!--<el-dropdown-item>个人中心</el-dropdown-item>-->
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <!-- <el-button class="login-btn" size="small" round icon="el-icon-s-custom">请登录</el-button> -->
      <div v-show="!user" class="mbtn">
        <el-button type="text" class="btnstyle" title="马上登录" @click="forwardlogin" ref="login">马上登录</el-button>

        <el-button type="text" class="btnstyle" title="立即注册" @click="forwardReg">立即注册</el-button>

      </div>

      <el-dialog
        title="登录"
        v-show="dialogVisible || isCollapse"
        :visible="dialogVisible || isCollapse"
        width="50%"
        top="80px"
        :before-close="handleCloseLogin"
      >
        <el-card class="box-card">
          <el-form ref="form" :rules="rules" :model="form" label-width="80px">
            <el-form-item label="账号" prop="user">
              <el-input
                style="width: 90%"
                v-model="form.user"
                placeholder="请输入手机号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                style="width: 90%"
                v-model="form.password"
                placeholder="请输入密码"
                clearable
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                class="login-btn"
                type="primary"
                @click="handleLogin"
                :loading="logLoading"
                >登录</el-button
              >
              <!--
              <el-tooltip
                class="item"
                effect="dark"
                content="没有账号？去注册"
                placement="top-start"
              >
                <el-button type="text" @click="gotoReg"
                  >注册<i class="el-icon-question"></i
                ></el-button>
              </el-tooltip>
              -->
            </el-form-item>
          </el-form>
        </el-card>
      </el-dialog>

      <el-dialog
        title="注册"
        v-show="dialogVisible2"
        :visible.sync="dialogVisible2"
        width="50%"
        top="80px"
        :before-close="handleClose"
      >
        <el-card class="box-card">
          <el-form
            ref="form2"
            :rules="rules2"
            :model="form2"
            label-width="80px"
          >
            <el-form-item label="手机号" prop="tel">
              <el-input
                style="width: 90%"
                v-model="form2.tel"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input style="width: 90%" v-model="form2.code" clearable>
                <template slot="append">
                  <el-button
                    @click.prevent="getCode()"
                    class="code-btn"
                    :disabled="!show"
                  >
                    <span v-show="show">获取验证码</span>
                    <span v-show="!show" class="count">{{ count }} s</span>
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password1">
              <el-input
                style="width: 90%"
                v-model="form2.password1"
                clearable
                show-password
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="确认密码" prop="password2">
              <el-input
                style="width: 90%"
                v-model="form2.password2"
                clearable
                show-password
              ></el-input>
            </el-form-item> -->
            <el-form-item>
              <el-button
                class="login-btn"
                type="primary"
                @click="handleReg"
                :loading="regLoading"
                >提交</el-button
              >
              <el-tooltip
                class="item"
                effect="dark"
                content="已有账号？去登录"
                placement="top-start"
              >
                <el-button type="text" @click="gotoLog"
                  >去登录<i class="el-icon-question"></i
                ></el-button>
              </el-tooltip>
            </el-form-item>
          </el-form>
        </el-card>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Cookie from 'js-cookie';
import { register, smsCode, memlogininfo,queryEmp } from '../api/index';
export default {
  mounted () {
    // const user = Cookie.get('phoneKey');
    // if (user) {
    //   this.user = user;
    // }
  },
    activated(){
        const user = Cookie.get('phoneKey');
        if (user) {
            this.user = user;
        }
    },
  computed: {
    isCollapse () {
      return this.$store.state.tab.isCollapse;
    }

  },
  data () {
    return {
      user: '',
      regLoading: false,
      logLoading: false,
      timer: null,
      show: true,
      count: 0,
      dialogVisible: false,
      dialogVisible2: false,
      form: {
        user: null,
        password: null,
      },
      form2: {
        tel: null,
        code: null,
        password1: null,
        password2: null,
      },
      rules: {
        user: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },
      rules2: {
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        password1: [
          { required: true, message: '请设置密码', trigger: 'blur' },
        ],
        password2: [
          { required: true, message: '请再次确认密码', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
      //转向登录
      forwardlogin(){
          this.$router.push('/login');
      },
      //转向注册
      forwardReg(){
          this.$router.push('/home');
      },
    gotoReg () {
      this.dialogVisible2 = true;
      this.dialogVisible = false;
    },
    gotoLog () {
      this.dialogVisible = true;
      this.dialogVisible2 = false;
    },
    getCode () {
      //axios请求
      if (!this.form2.tel) {
        this.$message.warning('请输入手机号后再试！');
      } else {
        smsCode(this.form2.tel).then(res => {
          if (res.data.code === 200) {
            this.$message.success('发送验证码成功！')
            // this.form2.code = res.data.result;
          } else {
            this.$message.error(res.data.message);
          }
        })
      }
      // 调用定时器
      this.timerFunt();
    },
      //登录弹框
    log () {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      })
    },
      //注册
    reg () {
      this.dialogVisible2 = true;
      this.$nextTick(() => {
        this.$refs.form2.resetFields();
      })
    },
      //登录操作验证
    handleLogin () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.logLoading = true;
          const params = {
            mobilePhone: this.form.user,
              pwd: this.form.password,
          }
          const paramEmp={
              mobilePhone: this.form.user
          }
          const empStr=this.$querystring.stringify(paramEmp);
          const p = this.$querystring.stringify(params);
          memlogininfo(p).then(res => {
            if (res.data.code === 200) {
              this.logLoading = false;
              this.$message.success(res.data.result);
              this.dialogVisible = false;
              this.$store.commit('collapseFuntchose');
              // const user = this.form.user;
              //根据手机号查询员工信息
                queryEmp(empStr).then(res => {
                    if (res.data.code === 200) {
                    var dt=res.data.result[0];
                    var xingming=dt.empXingming;
                    var dbphone=dt.mobilePhone;
                    Cookie.set('user', xingming);
                    Cookie.set('phone',dbphone);
                    this.user = xingming;
                } else {
                    this.$message.error(res.data.message);
                }
              });

              //Cookie.set('user', user);
              //this.user = user;
            } else {
              this.$message.error(res.data.message);
              this.logLoading = false;
              this.$store.commit('collapseFuntchose');
            }
          })
        } else {
              this.$message.error("提交失败");
               return false;
        }
      });
    },
    handleReg () {

      this.$refs.form2.validate((valid) => {
        if (valid) {
          this.regLoading = true;
          const p = {
            mobilePhone: this.form2.tel,
            smsCode: this.form2.code,
            memPwd: this.form2.password1,
          }
          register(p).then(res => {
            if (res.data.code === 200) {
              this.$message.success(res.data.result);
              // const userTel = this.form2.tel;
              this.dialogVisible2 = false;
              this.regLoading = false;
              setTimeout(() => {
                this.dialogVisible = true;
              }, 1000);
              // this.form2.code = res.data.result;
            } else {
              this.$message.error(res.data.message);
              this.regLoading = false;
            }
          }).catch(() => {
            this.regLoading = false;
          })
          // 获取token
          // const token = Mock.Random.guid();
          // 将token存入 用于通信
          // Cookie.set('token', token);
          // this.$router.push('/home');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    phone (tel) {
      const strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (strTemp.test(tel)) {
        return true;
      } else {
        return false;
      }
    },
    timerFunt () {
      // 验证码倒计时
      if (!this.timer && this.phone(this.form2.tel)) {
        this.count = 60;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    handleClose () {
      this.show = true;
      clearInterval(this.timer);
      this.timer = null;
      this.dialogVisible2 = false;
      this.$refs.form2.resetFields();
    },
    handleCloseLogin () {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.$store.commit('collapseFuntchose');
    },
    collapseChange () {
      this.$store.commit('collapseFunt');
    },
    goHome () {
      if (this.$route.path !== '/pageindex') {
        this.$router.push('/pageindex');
      }
    },
    handleClick (command) {
      if (command === 'logout') {
        this.$confirm('确定要退出吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if (this.$route.path === '/page1') {
            this.$router.push('/home');
            Cookie.remove('user');
              Cookie.remove('phoneKey');
              Cookie.remove('danweinameKey');
              Cookie.remove('deptKey');
            this.user = '';
          } else {
                  this.$router.push('/login');
                 //this.$message.success('退出成功！')
                  Cookie.remove('phoneKey');
                  Cookie.remove('danweinameKey');
                  Cookie.remove('deptKey');
                  this.user = '';
          }
        }).catch(() => { });
      } else {
        if (this.$route.path !== '/user') {
          this.$router.push('/user');
        }
      }
    },
    logout () {
    }
  }
}
</script>
<style lang="less" scoped>
    .mbtn{
        
    }
    .btnstyle{
        width:100px;
        height:40px;

        font-family: MicrosoftYaHei;
        font-weight: 400;
        font-size: 14px;
        color:#FFFFFF;
        border:1px solid #FFFFFF;

    }
    .logobai{
        padding:10px;
        width:160px;
        height:61px;

    }
.header {
  margin: auto;
  max-width: 1200px;
  padding: 0px;
  background-color: #42B134;
  height: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .r-content {

  }
  .r-contents{
    margin-right: 10.7vw;
  }
  .breadcrumb {
      
  }
  .el-dropdown-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      margin-left: 8px;
      color: #fff;
    }
  }
}
</style>