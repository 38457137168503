<template>
  <div
    v-loading="loading"
    element-loading-text="正在查询中,请稍后..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-card class="box-card">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-row :gutter="40">
          <el-col :span="19">
            <el-form-item label="">
              <el-radio v-model="formInline.radio" label="1">单程</el-radio>
              <el-radio v-model="formInline.radio" label="2">往返</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="5" style="text-align: right">
            <el-form-item v-if="false">
              <el-checkbox v-model="formInline.checked">仅看直飞</el-checkbox>
            </el-form-item>
            <el-form-item v-if="false">
              <el-select
                v-model="formInline.type"
                style="width: 120px; border: 0"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item> -->
        </el-row>

        <el-row :gutter="16">
          <el-col :span="12">
            <el-card class="card-nopadding"
              ><div style="width: 100%">
                <span class="title-small">出发地</span>
                <el-popover
                  class="mr"
                  placement="bottom"
                  width="400"
                  trigger="click"
                >
                  <city-select @choose="getCityGo1"></city-select>
                  <el-input
                    v-model="formInline.city1"
                    placeholder="可输入城市或机场"
                    style="width: 100%"
                    slot="reference"
                    clearable
                  ></el-input>
                </el-popover>
              </div>
              <div
                class="image"
                @click="changeCity"
                :class="formInline.city1 && formInline.city2 ? 'disable' : ''"
              >
                <el-image
                  style="width: 32px; height: 32px"
                  :src="require('../assets/icon-01.png')"
                  fit="cover"
                ></el-image>
              </div>
              <el-divider direction="vertical" class="line"></el-divider>
              <div style="width: 100%">
                <span class="title-small">目的地</span
                ><el-popover
                  class="mr"
                  placement="bottom"
                  width="400"
                  trigger="click"
                >
                  <city-select @choose="getCityGo2"></city-select>
                  <el-input
                    v-model="formInline.city2"
                    placeholder="可输入城市或机场"
                    style="width: 100%"
                    slot="reference"
                    clearable
                  ></el-input>
                </el-popover></div
            ></el-card>
          </el-col>
          <el-col :span="12">
            <el-card class="card-nopadding2"
              ><span class="title-small">选择日期</span>
              <el-date-picker
                v-show="formInline.radio === '1'"
                v-model="formInline.goTime"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
                style="width: 100%"
              >
              </el-date-picker>
              <el-date-picker
                v-show="formInline.radio === '2'"
                v-model="formInline.timeRange"
                type="daterange"
                align="right"
                unlink-panels
                range-separator=""
                start-placeholder="出发日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                end-placeholder="返回日期"
                :picker-options="pickerOptions"
                style="width: 100%"
              >
              </el-date-picker>
            </el-card>
          </el-col>
          <!-- <el-col :span="5">
            <el-card class="card3">
              <span class="title-small">乘客类型</span>
              <div class="check">
                <el-checkbox v-model="checked1">带儿童</el-checkbox>
                <el-checkbox v-model="checked2">带婴儿</el-checkbox>
              </div>
            </el-card>
          </el-col> -->
        </el-row>
      </el-form>

      <el-button icon="el-icon-search" round class="search" @click="search"
        >搜索</el-button
      >
    </el-card>
    <date-picker
      v-if="this.formInline.radio === '1'"
      @dataChange="dataChange"
    ></date-picker>
    <air-port-list @loadingFunt="loadingFunt" ref="airPortList"></air-port-list>
  </div>
</template>
<script>
import airPortList from '../components/airPortList.vue';
import DatePicker from '../components/DatePicker.vue';
import CitySelect from '@/components/citySelect.vue';
export default {
  components: {
    airPortList,
    DatePicker,
    CitySelect,
  },
  watch: {
    'formInline.radio': function () {
      this.formInline.goTime = '';
      this.formInline.timeRange = [];
    }
  },
  data () {
    return {
      loading: false,
      activeName1: '1',
      checked1: false,
      checked2: false,
      formInline: {
        radio: '1',
        checked: false,
        type: '1',
        city1: null,
        city2: null,
        city1Code: null,
        city2Code: null,
        timeRange: null,
        goTime: null,
      },
      options: [{
        value: '1',
        label: '经济/超经舱'
      }, {
        value: '2',
        label: '公务/头等舱'
      }, {
        value: '3',
        label: '公务舱'
      }, {
        value: '4',
        label: '头等舱'
      }],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  mounted () {
    //console.log(this.$route.params, "路由跳转携带的参数");
    if (this.$route.params) {
      this.formInline = { ...this.$route.params };
      this.formInline.city1 = this.$route.params.city1;
      this.formInline.city2 = this.$route.params.city2;
      this.formInline.city1Code = this.$route.params.city1Code;
      this.formInline.city2Code = this.$route.params.city2Code;
      this.$refs.airPortList.getDataFunt(this.formInline);
    }
  },
  methods: {
    dataChange (time) {
      //console.log(time, this.formInline, 'timechange233');
      //console.log(this.$refs.airPortList);
      this.formInline.goTime = time;
      this.$nextTick(() => {
        this.$refs.airPortList.getDataFunt(this.formInline);
      })
    },
    changeCity () {
      if (this.formInline.city1 && this.formInline.city2) {
        const c1 = this.formInline.city1;
        const c2 = this.formInline.city2;
        this.formInline.city1 = c2;
        this.formInline.city2 = c1;

        const cc1 = this.formInline.city1Code;
        const cc2 = this.formInline.city2Code;
        this.formInline.city1Code = cc2;
        this.formInline.city2Code = cc1;
      } else {
        this.$message.warning('请选择出发地及到达地!')
      }
    },
    getCityGo1 (item) {
      this.formInline.city1 = `${item.cityName}(${item.threeCharacterCode})`
      this.formInline.city1Code = item.threeCharacterCode
    },
    getCityGo2 (item) {
      this.formInline.city2 = `${item.cityName}(${item.threeCharacterCode})`
      this.formInline.city2Code = item.threeCharacterCode
    },
    search () {
      this.$refs.airPortList.getDataFunt(this.formInline);
    },
    loadingFunt (f) {
      this.loading = f;
    }
  }
}
</script>
<style lang="less" scoped>
.box-card {
  position: relative;
  margin-bottom: 60px;
  overflow: visible;
  .search {
    width: 176px;
    padding: 14px 0;
    position: absolute;
    border: none;
    bottom: -60px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(-90deg, #f70, #ffa50a);
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    border-radius: 28px;
    &:active {
      //  -webkit-transform: translate(1px);
      bottom: -61px;
    }
  }
}
.card-nopadding {
  position: relative;
  .image {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    background: #fff;
    .el-image {
      &:active {
        animation: rotate 2.5s cubic-bezier(0, 0, 0, 1.29) 1;
      }
      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
  .line {
    margin: 0 20px;
  }
  /deep/.el-card__body {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
      flex-direction: column;
    }
  }
  /deep/.el-divider--vertical {
    height: 72px;
    // margin-left: 18px;
  }
  .title-small {
    color: #999;
    margin-bottom: 5px;
  }
}
.card-nopadding2,
.card3 {
  height: 92px;
  .check {
    display: flex;
    .el-checkbox {
      margin-top: 14px;
      width: 40%;
    }
  }
  /deep/.el-card__body {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
  }
  .title-small {
    color: #999;
    margin-bottom: 5px;
  }
}
</style>