import { render, staticRenderFns } from "./Trainorderdetailstwo.vue?vue&type=template&id=1975d396&scoped=true"
import script from "./Trainorderdetailstwo.vue?vue&type=script&lang=js"
export * from "./Trainorderdetailstwo.vue?vue&type=script&lang=js"
import style0 from "./Trainorderdetailstwo.vue?vue&type=style&index=0&id=1975d396&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1975d396",
  null
  
)

export default component.exports