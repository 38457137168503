import { render, staticRenderFns } from "./TrainorderdetailstwoRight.vue?vue&type=template&id=5d8049db&scoped=true"
import script from "./TrainorderdetailstwoRight.vue?vue&type=script&lang=js"
export * from "./TrainorderdetailstwoRight.vue?vue&type=script&lang=js"
import style0 from "./TrainorderdetailstwoRight.vue?vue&type=style&index=0&id=5d8049db&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d8049db",
  null
  
)

export default component.exports