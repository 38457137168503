<template>
	<div class="cdiv">
		<div class="header flex">
			<div>
				<el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')" fit="cover"></el-image>
			</div>
			<div class="pleft">当前位置：<span>首页</span><span>>个人中心</span><span>>人员审核</span></div>
		</div>
		<div class="hc flex">
			<div class="hcleft">Hi~ {{ user }}</div>
			<div class="flex" @click="gotoupdateziliao">
				<div class="updatezl">去修改基本资料</div>
				<div class="zlicon">
					<el-image class="weiziicon" :src="require('../assets/img/ziliao.png')" fit="cover"></el-image>
				</div>
			</div>
		</div>
		<el-tabs class="tabwidth" v-model="activeName" type="card" @tab-click="handleClick">
			<el-tab-pane label="站内消息" name="first">
				<div class="tcontent flex" v-for="(item, index) in listgg" :key="index" @click="forwardggdetails(item.xxId)">
					<div class="scheci">
						{{ item.xxTitle }}
					</div>

					<div class="checimoney" style="color: #999999">发布时间：{{ item.xxDate.slice(0, 10) }}</div>

					<div class="operbtn" v-if="item.xxIsRead == 0">未读</div>
					<div class="operydbtn" v-else>已读</div>
				</div>

				<div class="pager flex">
					<div class="shouye">
						<el-button :class="indexbtn ? 'cgbtn' : 'sybtn'" @click="forwardindex">首页</el-button>
					</div>
					<div class="btnnodiv flex" v-for="(item1, index1) in totalpages" :key="index1" @click="forwardpage(item1, index1)">
						<el-button v-if="btnflag == false" :class="isActive == index1 ? 'defaultbtn' : 'btnno'">{{ item1 }}</el-button>
						<el-button class="btnno" v-else>{{ item1 }}</el-button>
					</div>

					<div class="shouye mleftmargin">
						<el-button :class="lastbtn ? 'cgbtn' : 'sybtn'" @click="forwardlast">末页</el-button>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane class="cltabs" label="修改密码" name="updatepwd">
				<div class="tcontentpwd">
					<el-form size="small" :inline="true" ref="form" :model="form" label-width="100px" class="formstylegrzx">
						<el-form-item label="" class="fsizestyle" prop="mobilephone">
							<span class="spanstyles"> 手机号码： </span>
							<el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.mobilephone" clearable></el-input>
						</el-form-item>
						<el-form-item label="" class="fsizestyle" prop="checkcode">
							<span class="spanstyles"> 验证码： </span>
							<el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.checkcode" clearable></el-input>
						</el-form-item>
						<el-form-item>
							<el-col :span="25">
								<el-button
									class="sendcodebtn"
									style="background: #42b134; color: #ffffff"
									@click="getCode"
									:disabled="!login.canGet"
									type="button"
								>
									<span v-show="!login.canGet">{{ login.waitTime + "s" }}后重发</span>
									<span v-show="login.canGet">{{ tempLogin.text }}</span>
								</el-button>
								<!--<el-button class="sendcodebtn" >发送验证码</el-button>-->
							</el-col>
						</el-form-item>
					</el-form>

					<el-form size="small" :inline="true" ref="form" :model="form" label-width="80px" class="formstylegrzx">
						<el-form-item label="" class="fsizestyle" prop="newspwd">
							<span class="spanstyles"> 新密码： </span>
							<el-input show-password class="inputstyle heightstyle" placeholder="请输入" v-model="form.newspwd" clearable></el-input>
						</el-form-item>
						<el-form-item label="" class="fsizestyle" prop="confirmpwd">
							<span class="spanstyles"> 确认密码： </span>
							<el-input show-password class="inputstyle heightstyle" placeholder="请输入" v-model="form.confirmpwd" clearable></el-input>
						</el-form-item>
					</el-form>
				</div>

				<div class="savebtndiv">
					<el-button class="savebtn" @click="saveupdatepwd">保存</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane class="cltabs" label="解绑手机" name="jiebangphone">
				<div class="tcontentpwd flex">
					<el-form size="small" :inline="true" ref="form" :model="formjbp" label-width="100px" class="formstylegrzx">
						<el-form-item label="手机号码" class="fsizestyle" prop="mobilephone">
							<el-col :span="20">
								<el-input class="inputstyle heightstyle" placeholder="请输入" v-model="formjbp.mobilephone" clearable></el-input>
							</el-col>
						</el-form-item>

						<el-form-item label="验证码" class="fsizestyle" prop="checkcode">
							<el-col :span="20">
								<el-input class="inputstyle heightstyle" placeholder="请输入" v-model="formjbp.checkcode" clearable></el-input>
							</el-col>
						</el-form-item>
						<el-form-item>
							<el-col :span="25">
								<el-button
									class="sendcodebtn"
									style="background: #42b134; color: #ffffff"
									@click="sendunbincode"
									:disabled="!login1.canGet1"
									type="button"
								>
									<span v-show="!login1.canGet1">{{ login1.waitTime1 + "s" }}后重发</span>
									<span v-show="login1.canGet1">{{ tempLogin1.text }}</span>
								</el-button>
								<!--<el-button class="sendcodebtn" @click="sendunbincode">发送验证码</el-button>-->
							</el-col>
						</el-form-item>
					</el-form>
				</div>
				<div class="savebtndiv">
					<el-button class="savebtn" @click="unbindphone">保存</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane class="cltabjsd" label="人员管理" name="emp">
				<div class="tcontentdt flex">
					<div class="jiesuandantitle">人员审核</div>
				</div>
				<div class="cpgcontent">
					<el-row :gutter="20">
						<el-col :span="12" v-for="(item4, index4) in passengerList" :key="index4">
							<div class="pgcontent">
								<div class="pcdiv flex">
									<div>部门：</div>
									<div>{{ item4.deptname }}</div>
								</div>
								<div class="pcdiv flex">
									<div>姓名：</div>
									<div>{{ item4.xingming }}</div>
								</div>
								<div class="pcdiv flex">
									<div>手机号码：</div>
									<div>{{ item4.pg_phone }}</div>
								</div>
								<div class="pcdiv flex">
									<div>性别：</div>
									<div>{{ item4.xingbie == "1" ? "男" : item4.xingbie == "2" ? "女" : "" }}</div>
								</div>
								<div class="pcdiv flex">
									<div>人员类型：</div>
									<div>{{ item4.person_type == "1" ? "成人" : item4.person_type == "2" ? "儿童" : "" }}</div>
								</div>
								<div class="pcdiv flex">
									<div>证件类型：</div>
									<div>{{ item4.id_type == "1" ? "身份证" : item4.id_type == "2" ? "护照" : "" }}</div>
								</div>
								<div class="pcdiv flex">
									<div>证件号码：</div>
									<div>{{ item4.id_number }}</div>
								</div>
								<div class="pcdiv flex">
									<div>证件照片：</div>
									<div v-if="item4.id_img_zhengmian != null" class="imgzhengmian">
										<el-image class="weiziiconimgs" :src="item4.id_img_zhengmian" fit="cover"  :preview-src-list="[item4.id_img_zhengmian,item4.id_img_fanmian]"></el-image>
									</div>
									<div v-if="item4.id_img_fanmian != null" class="imgfanmian">
										<el-image class="weiziiconimgs" :src="item4.id_img_fanmian" :preview-src-list="[item4.id_img_fanmian,item4.id_img_zhengmian]" fit="cover"></el-image>
									</div>
							
								</div>
								<div class="pcdiv flex flex-end">
									<div class="delbtn" @click="gotoshenhery(item4.pg_id)">审核</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="pager flex">
					<el-pagination
						background
						layout="prev, pager, next"
						@current-change="handleCurrentChangePassenger"
						:total="passengerPagination.total"
					></el-pagination>
				</div>
			</el-tab-pane>
			<el-tab-pane class="cltab" label="部门管理" name="dept">
				<div class="deptdiv" @click="adddept">
					<div class="addbtns">+新增</div>
				</div>
				<div>
					<el-table :data="deptList" stripe style="width: 100%">
						<el-table-column prop="dptName" label="名称" min-width="200px" align="center"> </el-table-column>
						<el-table-column prop="sortId" label="排序值" min-width="100px" align="center"> </el-table-column>
						<el-table-column prop="deptPersonCount" label="人数" min-width="100px" align="center"> </el-table-column>

						<el-table-column prop="mingxi" label="操作" min-width="100px" align="center">
							<template slot-scope="scope">
								<button class="btn-clz1" @click="handleUpdateDept(scope.row.dptId)">修改</button>
								<button class="btn-clzgray" @click="handelDelDept(scope.row.dptId)">删除</button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="pager flex">
					<el-pagination
						background
						layout="prev, pager, next"
						@current-change="handleCurrentChangeDept"
						:total="deptPagination.total"
					></el-pagination>
				</div>
			</el-tab-pane>
			<el-tab-pane class="cltabjsd" label="结算单" name="jiesuandan" v-if="companystr != null && companystr != ''">
				<div>
					<div class="">
						<el-form size="small" :inline="true" ref="form" :model="formAccountmingxi" label-width="80px" class="formstylegrzx">
							<el-form-item label="会员手机号" class="" prop="memPhone">
								<el-col :span="20">
									<el-input class="inputstyle heightstyle" placeholder="请输入" v-model="formAccountmingxi.memPhone" clearable></el-input>
								</el-col>
							</el-form-item>
							<el-form-item label="流水号" class="fsizestyle" prop="orderLiushuiNo">
								<el-col :span="20">
									<el-input
										class="inputstyle heightstyle"
										placeholder="请输入"
										v-model="formAccountmingxi.orderLiushuiNo"
										clearable
									></el-input>
								</el-col>
							</el-form-item>
							<el-form-item label="日期区间" class="" prop="mactDate">
								<el-col :span="20">
									<el-date-picker type="date" placeholder="选择日期" v-model="formAccountmingxi.mactDate" class="inputstyle"></el-date-picker>
								</el-col>
							</el-form-item>
						</el-form>
					</div>
					<div class="savebtndiv">
						<el-button class="savebtn" @click="searchAccountmingxi">搜索</el-button>
					</div>
				</div>
				<div>
					<el-table :data="accountmingxiList" stripe style="width: 100%" @cell-click="gotojiesuandandetails">
						<el-table-column prop="mactId" label="ID" min-width="150"> </el-table-column>
						<el-table-column prop="orderLiushuiNo" label="流水号ID" min-width="400"> </el-table-column>
						<el-table-column prop="orderLeixing" label="订单类型" min-width="150"> </el-table-column>
						<el-table-column prop="payLeixing" label="支付类型" min-width="150"> </el-table-column>
						<el-table-column prop="orderMingxi" label="金额" min-width="150"> </el-table-column>
						<el-table-column prop="memPhone" label="会员手机号" min-width="150"> </el-table-column>
						<el-table-column prop="mactDate" label="时间" min-width="200"> </el-table-column>
					</el-table>
				</div>
				<div class="pager flex">
					<el-pagination
						background
						layout="prev, pager, next"
						@current-change="handleCurrentChangeAccountmingxi"
						:total="accountmingxiPagination.total"
					></el-pagination>
				</div>
			</el-tab-pane>
		</el-tabs>

		<el-dialog
			title="审核"
			v-show="dialogVisible || isCollapse"
			:visible="dialogVisible || isCollapse"
			width="500px"
			top="80px"
			:before-close="handleCloseLogin"
		>
			<el-card class="box-card">
				<el-form ref="form" :rules="rules" :model="form" label-width="80px">
					<div>
						<div class="remarkinfo">
							<el-input
								type="textarea"
								v-model="form.textareaValue"
								show-word-limit
								resize="none"
								:rows="5"
								class="textarea-box"
								placeholder="这里显示审核意见，通过时选填，拒绝时必填"
							></el-input>
						</div>
					</div>
					<div class="btnstylesinfo">
						<el-button class="login-btn clearbtn" @click="gotoclearBtn"> 拒绝 </el-button>
						<el-button class="login-btn" type="primary" @click="handleLogin" style="background-color: rgba(66, 177, 52, 1)" :loading="logLoading"
							>通过</el-button
						>
					</div>
				</el-form>
			</el-card>
		</el-dialog>

		<div class="copyright">工业和信息化部备案管理系统网站 豫B1-20080191-28</div>
	</div>
</template>

<script>
import ElImage from "element-ui/packages/image/src/main";
import ElCard from "element-ui/packages/card/src/main";
import ElButton from "element-ui/packages/button/src/button";
import {
	querygg,
	smsCode,
	updatepwdByPhoneInfo,
	querycompanybyphone,
	queryPassengerlistdaishenhe,
	queryPassengerlist,
	delPassenger,
	queryDept,
	delDept,
	queryAccountmingxi,
	queryxiaoxi,
	querydeptinfobyphone,
	updateunbindPhone,
	updateshenhe,
} from "../api/index";
import Cookie from "js-cookie";
export default {
	components: {
		ElButton,
		ElCard,
		ElImage,
	},
	data() {
		return {
			userinfo: "",
			dialogVisible: false,
			companystr: "",
			lastbtn: false,
			indexbtn: false,
			btnflag: false,
			isActive: 0,
			loading: false,
			listgg: [],
			totalpages: 0,
			pagesize: 10,
			tempLogin: {
				// 定义一个临时对象
				canGet: true,
				timer: null,
				waitTime: 60,
				text: "发送验证码",
			},
			tempLogin1: {
				// 定义一个临时对象
				canGet1: true,
				timer1: null,
				waitTime1: 60,
				text: "发送验证码",
			},
			dialogVisible: false,
			accountmingxiList: [],
			accountmingxiPagination: {
				curpage: 1,
				pagesize: 10,
				total: 0,
			},
			formAccountmingxi: {},
			deptList: [],
			user: "",
			activeName: "first",
			form: {
				date1: "",
				sendcity: "",
				arrivalcity: "",
				mobilephone: "",
				checkcode: "",
				newspwd: "",
				confirmpwd: "",
				textareaValue: "",
			},
			formjbp: {
				mobilephone: "",
				checkcode: "",
			},
			formry: {
				deptname: "",
				inputname: "",
			},
			passengerList: [],
			passengerPagination: {
				curpage: 1,
				pagesize: 10,
				total: 0,
			},
			deptPagination: {
				curpage: 1,
				pagesize: 10,
				total: 0,
			},
			pgidstr: "",
			listdt: [],
			rules: {
				mobilephone: [
					{ required: true, message: "请输入手机号", trigger: "blur" },
					{ pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确", trigger: "blur" },
				],

				pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
			},
		};
	},
	computed: {
		login() {
			// 最终对象
			if (!this.tempLogin.canGet) {
				return this.timeCountdown({
					...this.tempLogin,
					text: this.tempLogin.waitTime === 0 ? "重新发送" : this.tempLogin.text,
				});
			} else {
				return this.tempLogin;
			}
		},
		login1() {
			// 最终对象
			if (!this.tempLogin1.canGet1) {
				return this.timeCountdown1({
					...this.tempLogin1,
					text: this.tempLogin1.waitTime1 === 0 ? "重新发送" : this.tempLogin1.text,
				});
			} else {
				return this.tempLogin1;
			}
		},
	},
	activated() {
		const user = Cookie.get("phoneKey");
		const userinfo = JSON.parse(Cookie.get("userinfo"));
		this.userinfo = userinfo;
		if (user) {
			this.user = user;
		}
		// this.querygonggao(0, this.pagesize);
		// this.querycompanymember(user);
		this.getPassengerList();

		// this.getDeptList();
		//根据手机号查询部门
		// this.querydeptbyphone(user);
		this.form.mobilephone = "";
		this.form.checkcode = "";
		this.form.newspwd = "";
		this.form.confirmpwd = "";
		const tab = this.$route.query.tab || "emp";
		this.activeName = tab;
	},
	name: "gerenzx-right",
	methods: {
		//审核通过
		handleLogin() {
			const pgids = this.pgidstr;
			const shyjcontent = this.form.textareaValue;
			const params = {
				pg_id: pgids,
				shenhe_yijian: shyjcontent,
				shenhe_status: "normal",
				user_id: this.userinfo.id,
				gs_id: this.userinfo.gs_id,
			};
			updateshenhe(params).then((res) => {
				if (res.data.code && res.data.code == 1) {
					this.$message.success("审核成功");
					this.getPassengerList();
				} else {
					var msgstr = res.data.msg;
					this.$message.error("审核失败:" + msgstr);
				}
			});
			this.dialogVisible = false;
		},
		//审核拒绝
		gotoclearBtn() {
			const pgids = this.pgidstr;
			const shyjcontent = this.form.textareaValue;
			if ("" == shyjcontent || null == shyjcontent) {
				this.$message.warning("请输入审核意见！");
				return;
			}
			const params = {
				pg_id: pgids,
				shenhe_yijian: shyjcontent,
				shenhe_status: "refuse",
				user_id: this.userinfo.id,
				gs_id: this.userinfo.gs_id,
			};
			updateshenhe(params).then((res) => {
				if (res.data.code && res.data.code == 1) {
					this.$message.success("审核成功");
					this.getPassengerList();
				} else {
					var msgstr = res.data.msg;
					this.$message.error("审核失败:" + msgstr);
				}
			});
			this.dialogVisible = false;
		},
		//待审核弹窗
		gotoshenhery(pgId) {
			this.pgidstr = pgId;
			this.dialogVisible = true;
		},
		//发送解绑短信验证码
		sendunbincode() {
			var mobilephonestr = this.formjbp.mobilephone;
			if ("" == mobilephonestr || null == mobilephonestr) {
				this.$message.warning("请输入手机号！");
				return;
			}
			const params = {
				mobilePhone: mobilephonestr,
				sendType: "4",
			};
			//调用短信发送接口
			smsCode(params).then((res) => {
				if (res.data.code && res.data.code === 200) {
					this.$message.success("发送成功");
				} else {
					var msgstr = res.data.msg;
					this.$message.error("发送失败:" + msgstr);
				}
			});
			this.timeCountdown1(this.login1); // 参数为最终对象
		},
		timeCountdown1(obj) {
			// obj包括timer、waitTime 、canGet
			const TIME_COUNT1 = 60; // 默认倒计时秒数
			if (!obj.timer1) {
				obj.waitTime1 = TIME_COUNT1;
				obj.canGet1 = false;
				obj.timer1 = setInterval(() => {
					if (obj.waitTime1 > 0 && obj.waitTime1 <= TIME_COUNT1) {
						obj.waitTime1--;
					} else {
						obj.canGet1 = true;
						clearInterval(obj.timer1); // 清空定时器
						obj.timer1 = null;
					}
				}, 1000);
			}
			return {
				timer1: obj.timer1,
				canGet1: obj.canGet1,
				waitTime1: obj.waitTime1,
			};
		},
		//解绑手机
		unbindphone() {
			const phonestr = this.formjbp.mobilephone;
			const checkcode = this.formjbp.checkcode;
			if ("" == phonestr || null == phonestr) {
				this.$message.warning("请输入手机号！");
				return;
			}
			if (!/^1[3-9]\d{9}$/.test(phonestr)) {
				this.$message.warning("手机号格式错误");
				return;
			}
			if ("" == checkcode || null == checkcode) {
				this.$message.warning("请输入验证码！");
				return;
			}
			const params = {
				mobilePhone: phonestr,
				checkCode: checkcode,
				sendType: 4,
			};
			updateunbindPhone(params).then((res) => {
				if (res.data.code && res.data.code === 200) {
					this.$message.success("解绑手机成功");
					this.formjbp.mobilephone = "";
					this.formjbp.checkcode = "";
				} else {
					var msgstr = res.data.msg;
					this.$message.error("解绑手机失败:" + msgstr);
				}
			});
		},
		//根据手机号查询部门
		querydeptbyphone(user) {
			querydeptinfobyphone(user).then((res) => {
				try {
					this.listdt = res.data.data.ret;
				} catch (error) {
					//this.companystr = "";
				}
			});
		},
		handleClick(val) {
			this.$router.push("/gerenzx?tab=" + val.name);
			if ("emp" == val.name) {
				this.getPassengerList();
			}
		},
		handleCurrentChangeAccountmingxi(val) {
			this.accountmingxiPagination.curpage = val;
			this.getDeptList();
		},
		searchAccountmingxi() {
			this.accountmingxiPagination.curpage = 1;
			this.getAccountmingxiList();
		},
		async getAccountmingxiList() {
			try {
				const data = await queryAccountmingxi({
					...this.accountmingxiPagination,
					...this.formAccountmingxi,
				});
				this.accountmingxiList = data.data.data.data;
				this.accountmingxiPagination.total = data.data.data.total;
			} catch (error) {
				this.accountmingxiList = [];
			}
		},
		handleCurrentChangeDept(val) {
			this.deptPagination.curpage = val;
			this.getDeptList();
		},
		searchDept() {
			this.deptPagination.curpage = 1;
			this.getDeptList();
		},
		async getDeptList() {
			try {
				const data = await queryDept({ ...this.deptPagination });
				this.deptList = data.data.data.data;
				this.deptPagination.total = data.data.data.total;
			} catch (error) {
				this.deptList = [];
			}
		},
		async handleUpdateDept(id) {
			//this.$router.push({path:'/updatedept',query:{id:id}});
			this.$router.push("/updatedept?id=" + id);
		},
		async handelDelDept(id) {
			this.$confirm("是否确认删除?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const data = await delDept(id);
					if (data.data.code === 200) {
						this.$message({
							type: "success",
							message: "操作成功!",
						});
						this.getDeptList();
					}
				})
				.catch(() => {});
		},
		handleCurrentChangePassenger(val) {
			this.passengerPagination.curpage = val;
			this.getPassengerList();
		},
		//搜索
		searchPassenger() {
			//const deptnameinfo=this.formry.deptname;
			this.passengerPagination.curpage = 1;
			this.getPassengerList();
		},
		async getPassengerList() {
			//获取手机号：
			const mobilePhone = Cookie.get("phoneKey");
			// try {
			const res = await queryPassengerlist({
				page: this.passengerPagination.curpage,
				user_id: this.userinfo.id,
				type: 3,
			});
			this.passengerList = res.data.data.data;

			this.passengerPagination.total = res.data.data.total;
			// } catch (error) {
			//     this.passengerList = [];
			// }
		},
		async handelDelPassenger(id) {
			this.$confirm("是否确认删除?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const data = await delPassenger({ id: id });
					if (data.data.code === 200) {
						this.$message({
							type: "success",
							message: "操作成功!",
						});
						this.getPassengerList();
					}
				})
				.catch(() => {});
		},
		//根据手机号查询会员是否是企业会员
		querycompanymember(phonestr) {
			const params = {
				mobilePhone: phonestr,
			};
			querycompanybyphone(params).then((res) => {
				try {
					this.companystr = res.data.data.ret[0].gsName;
				} catch (error) {
					this.companystr = "";
				}
			});
		},
		//发送短信验证码
		getCode() {
			var mobilephonestr = this.form.mobilephone;
			if ("" == mobilephonestr || null == mobilephonestr) {
				this.$message.warning("请输入手机号！");
				return;
			}
			const params = {
				mobilePhone: mobilephonestr,
				sendType: "3",
			};
			//调用短信发送接口
			smsCode(params).then((res) => {
				if (res.data.code && res.data.code === 200) {
					this.$message.success("发送成功");
				} else {
					var msgstr = res.data.msg;
					this.$message.error("发送失败:" + msgstr);
				}
			});
			this.timeCountdown(this.login); // 参数为最终对象
		},
		timeCountdown(obj) {
			// obj包括timer、waitTime 、canGet
			const TIME_COUNT = 60; // 默认倒计时秒数
			if (!obj.timer) {
				obj.waitTime = TIME_COUNT;
				obj.canGet = false;
				obj.timer = setInterval(() => {
					if (obj.waitTime > 0 && obj.waitTime <= TIME_COUNT) {
						obj.waitTime--;
					} else {
						obj.canGet = true;
						clearInterval(obj.timer); // 清空定时器
						obj.timer = null;
					}
				}, 1000);
			}
			return {
				timer: obj.timer,
				canGet: obj.canGet,
				waitTime: obj.waitTime,
			};
		},
		//修改密码
		saveupdatepwd() {
			var phonestr = this.form.mobilephone;
			var checkcodestr = this.form.checkcode;
			var newspwdstr = this.form.newspwd;
			var confirmpwd = this.form.confirmpwd;
			if ("" == phonestr || null == phonestr || undefined == phonestr) {
				this.$message.warning("请输入手机号");
				return;
			}
			if (!/^1[3-9]\d{9}$/.test(phonestr)) {
				this.$message.warning("手机号格式错误");
				return;
			}
			if ("" == checkcodestr || null == checkcodestr || undefined == checkcodestr) {
				this.$message.warning("请输入验证码");
				return;
			}
			if ("" == newspwdstr || null == newspwdstr || undefined == newspwdstr) {
				this.$message.warning("请输入新密码");
				return;
			}
			if ("" == confirmpwd || null == confirmpwd || undefined == confirmpwd) {
				this.$message.warning("请输入确认密码");
				return;
			}
			if (newspwdstr != confirmpwd) {
				this.$message.warning("两次密码不一致");
				return;
			}
			const params = {
				mobilePhone: phonestr,
				checkCode: checkcodestr,
				memPwd: newspwdstr,
				sendType: 3,
			};
			updatepwdByPhoneInfo(params).then((res) => {
				if (res.data.code && res.data.code === 200) {
					this.$message.success("修改成功");
					this.form.mobilephone = "";
					this.form.checkcode = "";
					this.form.newspwd = "";
					this.form.confirmpwd = "";
					// this.$router.push('/gerenzx');
				} else {
					var msgstr = res.data.msg;
					this.$message.error("修改失败:" + msgstr);
				}
			});
		},
		//转向公告详细页面
		forwardggdetails(idstr) {
			this.$router.push({ path: "/xiaoxidetails", query: { xxId: idstr } });
		},
		//转向首页
		forwardindex() {
			this.btnflag = true;
			this.indexbtn = true;
			this.lastbtn = false;
			this.querygonggao(1, this.pagesize);
		},
		//转向尾页
		forwardlast() {
			this.indexbtn = false;
			this.btnflag = true;
			this.lastbtn = true;
			this.querygonggao(this.totalpages, this.pagesize);
		},
		//转向跳转页数
		forwardpage(page, index1) {
			this.lastbtn = false;
			this.btnflag = false;
			this.indexbtn = false;
			this.isActive = index1;
			if (page > this.totalpages) {
				this.$message.warning("当前页应小于总页数");
			} else {
				this.querygonggao(page, this.pagesize);
			}
		},
		//查询公告信息
		querygonggao(curpagestr, pagesizestr) {
			const params = {
				curpage: curpagestr,
				pagesize: pagesizestr,
			};
			const p = this.$querystring.stringify(params);
			this.loading = true;
			queryxiaoxi(p).then((res) => {
				console.log("%c Line:703 🍣 res", "color:#465975", res);
				this.loading = false;
				this.listgg = res.data.data.data;
				this.totalpages = res.data.data.totalpage;
			});
		},
		//转向新增人员
		addemp() {
			this.$router.push("/addemp?tab=emp");
		},
		//转向新增部门
		adddept() {
			this.$router.push("/adddept?tab=dept");
		},
		//转向结算单详情
		gotojiesuandandetails() {
			this.$router.push("/jiesuandan");
		},
		//转向待审核页面
		gotodaishenhe() {
			// this.dialogVisible=true;
			this.$router.push("/renyuandaishenhe?tab=emp");
		},
		handleCloseLogin() {
			this.dialogVisible = false;
			this.$refs.form.resetFields();
			this.$store.commit("collapseFuntchose");
		},
		//转向去修改基本资料
		gotoupdateziliao() {
			this.$router.push("/updateziliao");
		},
	},
};
</script>

<style scoped>
.cgbtn {
	width: 100px;
	height: 35px;
	background-color: #42b134;
	color: #ffffff;
	border: 1px solid #666666;
	text-align: center;
}

.login-btn {
	padding-left: 40px;
	padding-right: 40px;
}

.clearbtn {
	background-color: rgba(181, 181, 181, 1);
	color: #ffffff;
}

.btnstylesinfo {
	margin-top: 20px;
	text-align: center;
}

.tbtable {
	margin-right: 10px;
	width: calc(98% - 0px - 10px) !important;
}

.btn-clzgray {
	padding-top: 5px;
	border-bottom-left-radius: 10px;
	color: #ffffff;
	padding-left: 10px;
	font-size: 13px !important;
	padding-bottom: 5px;
	border-top-right-radius: 10px;
	margin-right: 10px;
	background-color: rgba(137, 137, 137, 1);
	margin-left: 10px;
	overflow: hidden;
	width: calc(50% - 10px - 10px) !important;
	border-top-left-radius: 10px;
	margin-top: 10px;
	border-bottom-right-radius: 10px;
	margin-bottom: 10px;
	text-align: center;
	padding-right: 10px;
	font-family: MicrosoftYaHei;
	font-weight: 400;
}

.btn-clz1 {
	padding-top: 5px;
	border-bottom-left-radius: 10px;
	color: #ffffff;
	padding-left: 10px;
	font-size: 13px !important;
	padding-bottom: 5px;
	border-top-right-radius: 10px;
	margin-right: 10px;
	background-color: #000000;
	margin-left: 10px;
	overflow: hidden;
	width: calc(50% - 10px - 10px) !important;
	border-top-left-radius: 10px;
	margin-top: 10px;
	border-bottom-right-radius: 10px;
	margin-bottom: 10px;
	text-align: center;
	padding-right: 10px;
	font-family: MicrosoftYaHei;
	font-weight: 400;
}

.mleftpg {
	margin-left: 10px;
}

.mtinfo {
	margin-top: 5px;
}

.addbtn {
	width: 150px;
	height: 45px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

.defaultbtn {
	background-color: #42b134;
	color: #ffffff;
}

.weiziiconst {
	width: 24px;
	height: 24px;
}
.weiziiconimgs {
	width: 80px;
	height: 80px;
}

.inputstyle {
	width: 10vw;
}

.cdiv {
}

.deptdiv {
	width: calc(100% - 0px - 0px) !important;
	background-color: #f1f1f1;
	text-align: center;
	border-radius: 20px;
}

.addbtns {
	padding: 10px;
	border-radius: 20px;
}

.mleftmargin {
	margin-left: 1vw;
}

.pager {
	margin-top: 2vh;
	width: calc(100% - 0px - 0px) !important;
	display: flex;
	justify-content: center;
}

.shouye {
}

.sybtn {
	width: 100px;
	height: 35px;
	border: 1px solid #666666;
	text-align: center;
}

.btnnodiv {
	margin-left: 1vw;
}

.btnno {
	width: 50px;
	height: 35px;
	border: 1px solid #666666;
	text-align: center;
}

.cpgcontent {
	margin: 1vh 0 2vh 0vh;
}

.delbtn {
	border: 1px solid #000000;
	border-radius: 10px;
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
	width: 5vw;
	text-align: center;
	margin-top: 1vh;
}

.imgzhengmian {
}

.imgfanmian {
	margin-left: 1vw;
}

.updatebtn {
	border: 1px solid #000000;
	border-radius: 10px;
	padding: 5px;
	margin-left: 1vw;
	padding-left: 10px;
	padding-right: 10px;
}

.pgcontent {
	background-color: #f1f1f1;
	padding: 2vw;
	padding-top: 1vh;
	padding-bottom: 2vh;
	margin-bottom: 2vh;
	font-size: 13px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

.pcdiv {
	padding-top: 1vh;
}

.clleft {
	margin-left: 1vw;
}

.operatbtn {
}

.addbtn {
}

.savebtndiv {
	/* width: calc(88% - 0px - 10px) !important; */
	margin-top: 20px;
	text-align: center;
}

.savebtn {
	background-color: #42b134;
	color: #ffffff;
	width: 400px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

.sendcodebtn {
	background-color: #42b134;
	color: #ffffff;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

.tabwidth {
	margin-top: 2vh;
}

.cltabjsd {
}

.cltabs {
	width: calc(100% - 0px - 0px) !important;
}

.cltab {
	margin-left: 0;
	margin-right: 0;
}

.zlicon {
	display: flex;
	justify-content: center;
	/* 水平居中 */
	align-items: center;
	height: 10vh;
}

.updatezl {
	font-size: 17px;
	color: #000000;
	line-height: 10vh;
	vertical-align: middle;
}

.hcleft {
	flex: 1;
	font-size: 20px;
	color: #000000;
	line-height: 10vh;
	vertical-align: middle;
	font-weight: bolder;
}

.copyright {
	font-size: 12px;
	text-align: center;
	margin-top: 5vh;
	color: #b6b6b6;
}

.mleftmargin {
	margin-left: 1vw;
}

.shouye {
}

.sybtn {
	width: 100px;
	height: 35px;
	border: 1px solid #666666;
	text-align: center;
}

.btnnodiv {
	margin-left: 1vw;
}

.btnno {
	width: 50px;
	height: 35px;
	border: 1px solid #666666;
	text-align: center;
}

.commonmtop {
	margin-top: 2vh;
}

.btnmargin {
	margin-top: 1vh;
}

.btnstyle {
	width: 100px;
	height: 35px;
	background-color: #42b134;
	color: #ffffff;
	border-radius: 5px;
}

.operbtn {
	color: #e10000;
	flex: 1;
}

.operydbtn {
	color: #999999;
	flex: 1;
}

.checizuowei {
	margin-left: 5vw;
}

.colorstyle {
	color: #f49200;
	font-weight: bolder;
}

.checileixing {
	margin-left: 5vw;
}

.zwleixing {
	margin-top: 0.1vh;
}

.cmtopzw {
	margin-top: 2vh;
}

.cxinfo {
	margin-left: 2vw;
}

.endcheci {
	margin-left: 3vw;
}

.sleft {
	margin-left: 1vw;
}

.scheci {
	flex: 8;
}

.checimoney {
	flex: 3;
}

.operbtn {
}

.startzhan {
	margin-left: 0.5vw;
}

.scdiv {
	margin-top: 2vh;
}

.sccdate {
	font-size: 20px;
	font-weight: bolder;
	margin-left: 1vw;
}

.haoshi {
	margin-left: 3vw;
}

.tcontent {
	background-color: #f1f1f1;
	height: 60px;
	margin-bottom: 1vh;
	/* width: calc(88% - 0px - 10px) !important; */
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	align-items: center;
	padding: 0 20px;
	box-sizing: border-box;
}

.tbcontentinfo {
}

.tcontentpwd {
	background-color: #f1f1f1;
	margin-top: 2vh;
	/* width: calc(88% - 0px - 10px) !important; */
	padding-top: 18px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

.mleftd {
	margin-left: -2vw;
}

.querybtn {
	background-color: #42b134;
	color: #ffffff;
	border-radius: 5px;
	margin-left: -1vw;
}

.formstylegrzx {
	/* padding-top: 2vh; */
}

.formstylegrzx .inputstyle {
	width: 10vw;
}

.container {
}

.header {
	font-size: 12px;
}

.flex {
	display: flex;
}
.flex-end {
	justify-content: flex-end;
}
.weiziicon {
}

.pleft {
	margin-left: 0.5vw;
	font-family: MicrosoftYaHei;
	font-weight: 400;
	font-size: 16px;
	color: #000000;
}

.hc {
	background-color: #3daf2e;
	height: 10vh;
	margin-top: 2vh;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	padding: 0 20px;
	box-sizing: border-box;
}

button:hover {
	background-color: #42b134;
	color: #ffffff;
}

/* 修改标签的背景色 */
.tabwidth /deep/ .el-tabs__header {
	border: 0;
}

/* 修改选项卡栏的文本颜色 */
.tabwidth /deep/ .el-tabs__item {
	color: #000000;
	border: 0;
	margin-left: 10px;
	margin-right: 10px;
}

/* 修改选中的选项卡的背景色和边框颜色 */
.tabwidth /deep/ .el-tabs__item.is-active {
	/*background-color: #409EFF;*/
	/*color: #fff;*/
	/*border-color: #409EFF;*/
	border-bottom: 2px solid #22ac38;
}

/* 修改分隔线颜色 */
.tabwidth /deep/ .el-tabs__nav-wrap::after {
	background-color: #fff;
}

.tabwidth /deep/ .el-tabs__header .el-tabs__nav {
	border: 0;
}

.spanstyles {
	width: 7vw;
	display: inline-block;
	margin-left: 20px;
}
</style>
