import { render, staticRenderFns } from "./Airticketorderdetailstwo.vue?vue&type=template&id=630eefdf&scoped=true"
import script from "./Airticketorderdetailstwo.vue?vue&type=script&lang=js"
export * from "./Airticketorderdetailstwo.vue?vue&type=script&lang=js"
import style0 from "./Airticketorderdetailstwo.vue?vue&type=style&index=0&id=630eefdf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630eefdf",
  null
  
)

export default component.exports