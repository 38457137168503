<template>
	<div class="cdiv">
		<div class="header flex">
			<div>
				<el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')" fit="cover"></el-image>
			</div>
			<div class="pleft">当前位置：<span>首页</span><span>>火车票订单</span></div>
		</div>
		<div class="hc">
			<el-form size="small" :inline="true" ref="form" :model="form" label-width="80px" class="formstylejp">
				<el-col :span="6">
					<el-form-item label="下单日期" class="mttop" prop="date1">
						<el-date-picker
							type="date"
							value-format="yyyy-MM-dd"
							placeholder="选择日期"
							v-model="form.order_time"
							class="inputstyle"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="出发日期" class="mttop" prop="date2">
						<el-date-picker
							type="date"
							value-format="yyyy-MM-dd"
							placeholder="选择日期"
							v-model="form.send_date"
							class="inputstyle"
						></el-date-picker>
					</el-form-item>
				</el-col>

				<!-- <el-form-item label="下单人" class="mttop" prop="sendcity">
                    <el-col :span="20">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.createperson"
                            clearable></el-input>
                    </el-col>
                </el-form-item> -->
				<el-col :span="6">
					<el-form-item label="流水号" class="mttop" prop="sendcity">
						<el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.liushui_no" clearable></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="乘客姓名" class="mttop" prop="sendcity">
						<el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.xinming" clearable></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="火车票号" class="mttop" prop="sendcity">
						<el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.tk_no" clearable></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="车次号" class="mttop" prop="sendcity">
						<el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.train_number" clearable></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="出发" class="mttop" prop="departurestation">
						<!-- <el-select class="inputstyle heightstyle" v-model="form.departurestation" filterable remote
                            reserve-keyword placeholder="请输入关键词"
                            :remote-method="(value) => remoteMethod(value, 'startStationOptions')" :loading="loading">
                            <el-option v-for="item in startStationOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select> -->
						<el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.departure_station" clearable></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="到达" class="mttop" prop="arrivalstation">
						<!-- <el-select class="inputstyle heightstyle" v-model="form.arrivalstation" filterable remote
                            reserve-keyword placeholder="请输入关键词"
                            :remote-method="(value) => remoteMethod(value, 'endStationOptions')" :loading="loading">
                            <el-option v-for="item in endStationOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select> -->
						<el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.arrival_station" clearable></el-input>
					</el-form-item>
				</el-col>
				<!-- <el-form-item label="所属单位" class="mttop" prop="danweiName">
                    <el-col :span="20">
                        <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.danweiName"
                                  clearable></el-input>
                    </el-col>
                </el-form-item> -->
				<el-col :span="6">
					<el-form-item label="订单状态" class="mttop" prop="ticketstatus">
						<el-select class="inputstyle" v-model="form.ticket_status" placeholder="订单状态">
							<el-option label="全部" value=""></el-option>
							<!-- <el-option label="有效" value="1"></el-option> -->
							<el-option v-for="(item, index) in Object.keys(ticketTypeDic)" :key="index" :label="ticketTypeDic[item]" :value="item"> </el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :span="2">
					<el-form-item class="mttop">
						<el-button class="querybtn" @click="search">搜索</el-button>
					</el-form-item>
				</el-col>
			</el-form>
		</div>
		<template v-for="(item, index) in list">
			<div class="top" :key="index">
				<div class="hcpdiv flex">
					<div>
						<el-image class="hcpicon" :src="require('../assets/img/hc.png')" fit="cover"></el-image>
					</div>
					<div class="titlecolor mleft">火车票</div>
					<div class="mleft xiancolor">|</div>
					<div class="mleft dfkcolor">{{ payStatus[item.pay_status] }}</div>
					<div class="mleft xiancolor">|</div>
					<div class="mleft dfkcolor">{{ drawTicketStatus[item.draw_ticket_status] }}</div>
				</div>
				<div v-if="item.ticket_status == 9" class="change-check dfkcolor"><b>(改签)</b> 原流水号:{{ item.old_liushui_no }}</div>
			</div>

			<div class="tcontent flex">
				<div class="tcontentwidth">
					<div class="traindatediv flex">
						<div class="iconleft">
							<el-image class="weiziiconsj" :src="require('../assets/img/shijian.png')" fit="cover"></el-image>
						</div>
						<div class="datestrs">
							{{ item.senddata ? item.senddata.split(" ")[0] : "" }}
						</div>
						<div class="datestrs danweistyle" v-if="item.danwei_name != null && item.danwei_name != ''">
							所属单位：<span>{{ item.danwei_name }}</span>
						</div>
					</div>
					<div class="flex">
						<div class="scheci commonmtop">
							<div class="flex">
								<div class="sccdate">{{ item.departure_station }}</div>
								<div class="sficon">
									<el-image class="weiziicon" :src="require('../assets/img/shi.png')" fit="cover"></el-image>
								</div>
							</div>
							<div class="scdiv flex">
								<div class="startzhan bluecolor">
									{{ item.send_date ? item.send_date.split(" ")[1] : "" }}
								</div>
							</div>
						</div>
						<div class="haoshi commonmtop">
							<div class="cxinfo">{{ item.train_number }}</div>
							<div>
								<el-image class="weiziicon" :src="require('../assets/img/jiantou.png')" fit="cover"></el-image>
							</div>
							<div class="sleft">{{ item.runtime }}</div>
						</div>
						<div class="endcheci commonmtop">
							<div class="flex">
								<div class="sccdate">{{ item.arrival_station }}</div>
								<div class="sficon">
									<el-image class="weiziicon" :src="require('../assets/img/zhong.png')" fit="cover"></el-image>
								</div>
							</div>
							<div class="scdiv flex">
								<div class="startzhan bluecolor">
									{{ item.arrival_date ? item.arrival_date.split(" ")[1] : "" }}
								</div>
							</div>
						</div>

						<div></div>
					</div>
					<div class="hengxian mtopstyle"></div>
					<div class="xiadaninfo flex">
						<div style="margin-right: 10px">
							下单人：<span>{{ userinfo.username }}</span>
						</div>
						<div>
							共：<span>{{ item.tk_num }}人</span>
						</div>
						<div class="xiadandate">下单时间：{{ item.order_time }}</div>
					</div>
				</div>
				<div class="shuxian"></div>
				<div class="rightoper">
					<div class="redcolorstyle mstyle">￥{{ item.order_money }}</div>
					<div class="operbtn flex">
						<el-button class="sybtn" @click="() => forwardtrainorderdetails(item.liushui_no, item)">详情</el-button>
						<el-button v-if="item.pay_status != 2" class="paycolor" @click="() => toPayOrder(item)">支付</el-button>
						<el-button class="sybtn" v-if="item.cancelStatus !== '5' && item.refundStatus !== '3'" @click="() => handelCancle(item)"
							>取消</el-button
						>
					</div>
				</div>
			</div>
		</template>

		<div class="pager flex">
			<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="pagination.total"></el-pagination>
		</div>
		<div class="copyright">工业和信息化部备案管理系统网站 豫B1-20080191-28</div>
        
        <el-dialog :title="`请选择支付方式   --  金额:【${payOrder.order_money}】 `" :visible.sync="payDialogVisible" class="pay-dialog" width="550px">
          <div class="pay-box">
              <div class="pay-item" v-if="userInfoData.role_id != 2">
                  <el-image class="pay-type" :src="require('../assets/img/alipay.png')"
                            @click="handlePay(1)"
                            fit="cover"></el-image>
                    <span>支付宝支付</span>
              </div>
              <div class="pay-item" v-if="userInfoData.role_id != 2">
                  <el-image class="pay-type" :src="require('../assets/img/wxpay.png')"
                            @click="handlePay(2)"
                            fit="cover"></el-image>
                            <span>微信支付</span>
              </div>
              <div class="pay-item" v-if="userInfoData.role_id == 2">
                  <el-image class="pay-type" :src="require('../assets/img/creditpay.png')"
                            @click="handlePay(3)"
                            fit="cover"></el-image>
                            <span>授信支付</span>
              </div>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="payDialogVisible = false">取 消</el-button>
          </div>
        </el-dialog>
	</div>
</template>

<script>
import Cookie from "js-cookie";
import ElImage from "element-ui/packages/image/src/main";
import ElButton from "element-ui/packages/button/src/button";
import { querytorderpage, querytrainlike, updateorderstatus, getUserInfo, trainPay } from "@/api";
// import { search } from "core-js/fn/symbol";
export default {
	components: {
		ElButton,
		ElImage,
	},
	data() {
		return {
			activeName: "first",
			form: {
				user_id: "",
				order_time: "",
				send_date: "",
				liushui_no: "",
				tk_no: "",
				train_number: "",
				departure_station: "",
				arrival_station: "",
				ticket_status: "",
				xinming: "",
			},
			rules: {
				mobilephone: [
					{ required: true, message: "请输入手机号", trigger: "blur" },
					{ pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确", trigger: "blur" },
				],

				pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
			},
			list: [],
			pagination: {
				page: 1,
				pagesize: 10,
				total: 1,
			},
            drawTicketStatus: {
                0: '未出票',
                1: '出票中',
                2: '已完成'
            },            
            payStatus: {
                1: '待付款',
                2: '已付款'
                //: '付款中',
            },
			// 1 待出票  2出票中 3 未完成 4 已完成
			ticketTypeDic: {
				1: "待付款",
				2: "改签审核中",
				3: "退票审核中",
				4: "取消审核中",
				5: "已出票",
				6: "改签拒绝",
				7: "退票拒绝",
				8: "取消拒绝",
				9: "已改签",
				10: "已退票",
				11: "已取消",
				// 1: "待出票",
				// 2: "出票中",
				// 3: "未完成",
				// 4: "已完成",
				// 5: "待支付",
			},
			// 1 待出票  2出票中 3 已完成
			ticketOutTypeDic: {
				1: "待出票",
				2: "出票中",
				3: "已完成",
			},
			startStationOptions: [],
			endStationOptions: [],
			loading: false,
            payOrder: {},
            payDialogVisible: false,
            userInfoData: {}
		};
	},
	created() {},
	activated() {
		const userinfo = JSON.parse(Cookie.get("userinfo"));
		this.userinfo = userinfo;
		this.form.user_id = userinfo.id;
		this.getOrderList();
        const _this = this;
        getUserInfo({user_id: userinfo.id}).then(res => {
            const resData = res.data;
            if(resData.code == 1) {
                _this.userInfoData = resData.data
            }            
        })
	},
	methods: {
        handlePay(type) {
            const _this = this;
            // 授信支付
            if(type == 3) {
                trainPay({
                    "user_id": _this.form.user_id, //用户id
                    "pay_method": "line_credit", //wx 微信 ali 支付宝  line_credit 授信额度
                    "liushui_no": _this.payOrder.liushui_no //订单流水号
                }).then(res => {
                    if(res.data.code == 1) {
                        _this.payDialogVisible = false;
                        _this.payOrder = {};
                        _this.getOrderList();
                    }
                    _this.$message.warning(res.data.msg);
                })
            }
        },
        toPayOrder(order) {
            this.payOrder = order;
            this.payDialogVisible = true;
        },
		debounce(func, wait, immediate) {
			let timeout;
			return (function () {
				let context = this,
					args = arguments;
				let later = function () {
					timeout = null;
					if (!immediate) func.apply(context, args);
				};
				let callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) func.apply(context, args);
			})();
		},
		remoteMethod(query, type = "startStationOptions") {
			this.debounce(() => {
				if (query !== "") {
					this.loading = true;
					querytrainlike({ stationName: query }).then((res) => {
						// console.log("%c Line:273 🍩", "color:#4fff4B", res);
						const { status, data } = res;
						if (status == 200) {
							console.log("%c Line:277 🥐", "color:#b03734", data, type);
							this[type] = data.map((item) => {
								return { value: item.stationName, label: item.stationName };
							});
						}
						this.loading = false;
					});
					// setTimeout(() => {
					//     this.loading = false;
					//     this.options = this.list.filter((item) => {
					//         return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
					//     });
					// });
				} else {
					this.options = [];
				}
			}, 500);
		},
		handleCurrentChange(val) {
			this.pagination.page = val;
			this.getOrderList();
		},
		search() {
			this.pagination.page = 1;
			this.getOrderList();
		},
		async getOrderList() {
			try {
				const data = await querytorderpage({ ...this.pagination, ...this.form });
				this.list = data.data.data.order;
				this.pagination.total = data.data.data.pages * 10 || 1;
			} catch (error) {
				this.list = [];
			}
		},
		//转向火车票详情第二个页面
		forwardtorderdetails() {
			this.$router.push("/trainorderdetailstwo");
		},
		//转向火车订单详情
		forwardtrainorderdetails(orderId, item) {
			//看是否退票或者改签
			const refundstatus = item.refundStatus;
			const changestatus = item.changeStatus;
			//退票
			if (3 == refundstatus || 3 == changestatus) {
				this.$router.push("/traingaiqiantuipiao?orderId=" + orderId);
			} else {
				this.$router.push("/trainorderdetails?orderId=" + orderId);
			}
		},
		async handelCancle(item) {
			this.$confirm("是否确认取消订单?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(async () => {
					const userinfo = JSON.parse(Cookie.get("userinfo"));
					const data = await updateorderstatus({
						liushui_no: item.liushui_no,
						user_id: userinfo.id,
					});
					if (data.data.code == 1) {
						this.$message({
							type: "success",
							message: data.data.message,
						});
						this.getOrderList();
					} else {
						this.$message({
							type: "error",
							message: data.data.msg,
						});
					}
				})
				.catch(() => {});
		},
	},
	name: "trainorder-right",
};
</script>

<style scoped>
.pay-dialog .pay-box {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    padding: 20px;
}
.pay-dialog .pay-box .pay-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: bold;
}
.pay-dialog .pay-box .pay-type {
    width: 100px;
    cursor: pointer;
}
.pay-dialog .pay-box .pay-item:hover {
    opacity: .7;
    color: #1e9a30;
}
.el-form--inline .el-form-item {
	margin-right: 0;
	width: 100%;
}
/deep/ .el-input--small .el-input__inner {
	box-sizing: border-box;
	width: 146px;
}
.danweistyle {
	font-size: 13px;
	margin-top: 2px;
}
.tcontentwidth {
	width: calc(60% - 0px - 0px) !important;
}

.rightoper {
	margin-top: 30px;
}

.shuxian {
	height: 190px;
	font-size: 1px;
	width: 1px;
	border-right: 1px dashed rgba(66, 177, 52, 1);

	margin-left: 30px;
}

.defaultbtn {
	background-color: #42b134;
	color: #ffffff;
}

.top {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.hcpdiv {
	margin-top: 2vh;
	background-color: #f1f1f1;
	width: 285px;
	padding-top: 15px;
	padding-left: 1.6vw;
	border-top-right-radius: 20px;
	border-top-left-radius: 6px;
}
.change-check {
	padding-top: 20px;
    width: 350px;
}

.titlecolor {
}

.xiancolor {
	color: rgba(66, 177, 52, 1);
}

.hcpicon {
	width: 20px;
	height: 27px;
}

.dfkcolor {
	color: rgba(100, 100, 100, 1);
}

.mleft {
	margin-left: 16px;
}

.titlecolor {
	color: rgba(66, 177, 52, 1);
}

.mttop {
	margin-top: 1vh;
}

.weiziiconst {
	width: 24px;
	height: 24px;
}

.inputstyle {
	width: 90%;
	box-sizing: border-box;
}

.cdiv {
}

.xiadandate {
	color: #646464;
	font-size: 12px;
	margin-left: 20px;
	justify-content: space-between;
}

.xiadaninfo {
	margin-left: 1.8vw;
	margin-top: 20px;
	color: #646464;
	font-size: 12px;
}

.mtopstyle {
	margin-left: 1vw;
	margin-top: 1vh;
}

.hengxian {
	border-bottom: 1px solid rgba(34, 172, 56, 1);
	margin-left: 2vw;
}

.paycolor {
	color: #42b134;
	border: 1px solid #42b134;
	width: 100px;
	height: 35px;
	text-align: center;
}

.mstyle {
	width: 100px;
	text-align: center;
}

.operbtn {
	margin-left: 30px;
	margin-top: 8vh;
}

.redcolorstyle {
	color: #ff7342;
	font-weight: bolder;
}

.bluecolor {
	color: #22ac38;
}

.sficon {
	margin-left: 0.5vw;
}

.tabwidth {
	background-color: #f1f1f1;
	margin-top: 3vh;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

.weiziiconsj {
	width: 16px;
	height: 16px;
	padding-left: 1.2vw;
}

.iconleft {
	margin-left: 0.5vw;
	margin-top: 0.2vh;
}

.datestrs {
	margin-left: 0.5vw;
}

.traindatediv {
	background-color: #f1f1f1;
	border-top-right-radius: 20px;
	padding-top: 20px;
}

.copyright {
	font-size: 12px;
	text-align: center;
	margin-top: 5vh;
	color: #b6b6b6;
}

.mleftmargin {
	margin-left: 1vw;
}

.pager {
	margin-top: 5vh;
	margin-left: 0vw;
	display: flex;
	justify-content: center;
}

.shouye {
}

.sybtn {
	width: 100px;
	height: 35px;
	border: 1px solid #666666;
	text-align: center;
}

.btnnodiv {
	margin-left: 1vw;
}

.btnno {
	width: 50px;
	height: 35px;
	border: 1px solid #666666;
	text-align: center;
}

.commonmtop {
	margin-top: 2vh;
}

.btnmargin {
	margin-top: 1vh;
}

.btnstyle {
	width: 100px;
	height: 35px;
	background-color: #42b134;
	color: #ffffff;
	border-radius: 5px;
}

.checizuowei {
	margin-left: 5vw;
}

.colorstyle {
	color: #f49200;
	font-weight: bolder;
}

.checileixing {
	margin-left: 5vw;
}

.checimoney {
	margin-left: 5vw;
}

.zwleixing {
	margin-top: 0.1vh;
}

.cmtopzw {
	margin-top: 2vh;
}

.cxinfo {
	margin-left: 2vw;
}

.endcheci {
	margin-left: 3vw;
}

.sleft {
	margin-left: 1vw;
	text-align: center;
}

.scheci {
	margin-left: 2vw;
}

.startzhan {
	margin-left: 1vw;
}

.scdiv {
	margin-top: 2vh;
}

.sccdate {
	font-size: 20px;
	font-weight: bolder;
	margin-left: 1vw;
}

.haoshi {
	margin-left: 3vw;
}

.tcontent {
	background-color: #f1f1f1;
	height: 190px;
	margin-top: -1vh;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 20px;
}

.mleftd {
	margin-left: -2vw;
}

.querybtn {
	background-color: #42b134;
	color: #ffffff;
	border-radius: 5px;
	margin-left: 1vw;
	width: 100px;
}

.trainorderstyle {
	margin-left: 1vw;
}

.formstyle {
	padding-top: 2.2vh;
}

.container {
}

.header {
	font-size: 12px;
}

.flex {
	display: flex;
}

.weiziicon {
}

.pleft {
	margin-left: 0.5vw;
	font-family: MicrosoftYaHei;
	font-weight: 400;
	font-size: 16px;
	color: #000000;
}

.hc {
	background-color: #f1f1f1;
	height: 210px;
	margin-top: 2vh;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

.formstylejp {
	padding-top: 2.2vh;
}

button:hover {
	background-color: #42b134;
	color: #ffffff;
}
</style>
