<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')"
                          fit="cover"></el-image>
            </div>
            <div class="pleft">
                当前位置：<span>首页</span><span>>账户明细</span>
            </div>
        </div>
        <div class="hc">

            <el-form
                    size="small"
                    :inline="true"
                    ref="form"
                    :model="form"
                    label-width="80px"
                    class="formstyle"
                    style="margin-left:1vw;"
            >

                <el-form-item label="开始时间" class="mttop" prop="startdate">
                    <el-col :span="20">
                        <el-date-picker
                                type="date"
                                placeholder="选择日期"
                                v-model="form.startdate"
                                style="width: 100%"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                        ></el-date-picker>
                    </el-col>
                </el-form-item>



                <el-form-item class="mttop mleftd">
                    <el-col :span="20">
                        <span>———</span>
                    </el-col>
                </el-form-item>


                <el-form-item label="结束时间" class="mttop" prop="enddate">
                    <el-col :span="20">
                        <el-date-picker
                                type="date"
                                placeholder="选择日期"
                                v-model="form.enddate"
                                style="width: 100%"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                        ></el-date-picker>
                    </el-col>
                </el-form-item>


                <el-form-item class="mttop">
                    <el-col :span="10">
                        <el-button class="querybtn" @click="search">搜索</el-button>
                    </el-col>
                </el-form-item>

            </el-form>
            <div class="tbcontent">

                <el-table :data="tableData" stripe  class="tbtable">
                    <el-table-column prop="liushui_no" label="订单流水号"></el-table-column>
                    <el-table-column prop="create_time" label="时间"></el-table-column>
                    <el-table-column prop="danwei_name" label="单位名称"></el-table-column>
                    <el-table-column prop="ticket_type_name" label="订单类型">
                    </el-table-column>
                    <el-table-column prop="fee_type" label="支付类型">
                        <template #default="scope">
                            {{feeTypeMap[scope.row.fee_type]}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="fee" label="明细"></el-table-column>
                </el-table>

            </div>
            <div class="pager flex">
                <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
                :total="pagination.total"></el-pagination>
            </div>
            <div class="copyright">
                工业和信息化部备案管理系统网站 豫B1-20080191-28
            </div>

        </div>

    </div>
</template>

<script>
    import ElImage from "element-ui/packages/image/src/main";
    import ElCard from "element-ui/packages/card/src/main";
    import ElButton from "element-ui/packages/button/src/button";
    import { accountDetails } from '../api/index';
    import Cookie from 'js-cookie';
    export default {
        components: {
            ElButton,
            ElCard,
            ElImage
        },
        data () {
            return {
                form: {
                    startdate: "",
                    enddate:'',
                    mobilePhone:'',
                },
                tableData:[],
                rules: {
                    mobilephone: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                        { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
                    ],

                    pwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                    ],

                },
                pagination: {
                    curpage: 1,
                    pagesize: 10,
                    total: 0,
                },
                userinfo: {},
                feeTypeMap: {'1':'订单支付','2':'出票服务费','3':'退票服务费','4':'改签服务费','5':'退还费用'}
            }
        },
        activated(){
            const user = Cookie.get('phoneKey');
            //获取手机号
            this.form.mobilePhone=user;
            this.userinfo =JSON.parse(Cookie.get('userinfo'));
            this.getMingxiList();
        },
        name: "accountmingxi-right",
        methods: {
            //支付类型状态
            paystatusFormat(row){
                var  orderleixing=row.payLeixing;
                var returnstr='';
                if(1==orderleixing){
                    returnstr='订单已支付';
                }
                if(2==orderleixing){
                    returnstr='订单未支付';
                }
                return returnstr;
            },
            //订单类型状态
            statusFormat(row){
                var  orderleixing=row.orderLeixing;
                var returnstr='';
                if(1==orderleixing){
                    returnstr='机票订单';
                }
                if(2==orderleixing){
                    returnstr='订单火车票';
                }
                return returnstr;
            },
            handleCurrentChange(val) {
                this.pagination.curpage = val;
                this.getMingxiList();
            },
            search() {
                this.pagination.curpage = 1;
                this.getMingxiList();
            },
            async getMingxiList() {
                try {
                    const _this = this;
                    const data = await accountDetails({
                        user_id: _this.userinfo.id,
                        page: _this.pagination.curpage,
                        pagesize: _this.pagination.pagesize,
                        start_time: _this.form.startdate,
                        end_time: _this.form.enddate
                    });
                    this.tableData = data.data.data.list;
                    this.pagination.total = data.data.data.count;
                } catch (error) {
                    this.tableData = [];
                }
            },
        }
    }
</script>

<style scoped>
    .defaultbtn{
        background-color: #42B134;
        color:#FFFFFF;
    }
    .weiziiconst{
        width:24px;
        height:24px;
    }
    .cdiv{

    }
    .mleftmargin{
        margin-left:1vw;
    }
    .pager{
        margin-top:5vh;
        margin-left:0vw;
        display: flex;
        justify-content: center;
    }
    .shouye{

    }
    .sybtn{
        width:100px;
        height:35px;
        border:1px solid #666666;
        text-align: center;
    }
    .btnnodiv{
        margin-left:1vw;
    }
    .btnno{
        width:47px;
        height:35px;
        border:1px solid #666666;
        text-align: center;
    }
    .mleftd{
        margin-left:-2vw;
    }
    .querybtn{
        background-color:#42B134;
        color:#FFFFFF;
        border-radius: 5px;
        margin-left:-1vw;
        padding-left:6vw;
        padding-right:6vw;
    }
    .formstyle{
        padding-top:2.2vh;
    }
    .container{

    }
    .header{
        font-size:12px;
    }
    .flex{
        display:flex;
    }
    .weiziicon{

    }
    .pleft{
        margin-left:0.5vw;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
    }
    tbcontent{
        width:100vw;
    }
    .tbtable{
        margin-left:1vw;
        margin-right:10px;
    }
    .hc{
        height:100vh;
        margin-top:2vh;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;

    }
    .copyright{
        font-size:12px;
        text-align: center;
        margin-top:5vh;
        color:#B6B6B6;
    }
    button:hover {background-color: #42B134;color:#FFFFFF;}
</style>