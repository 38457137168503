export default {
  state: {
    isCollapse: false,
    tel: null,
  },
  mutations: {
    collapseFunt(state){
      state.isCollapse = true
    },
    collapseFuntchose(state){
      state.isCollapse = false
    },
    setTel(state){
      console.log('vuex:', state);
      state.tel = state
    }
  }
}