<template>
    <div>

        <el-container>
            <el-header height="auto"><common-header /></el-header>
            <el-container class="page-content">
                <el-aside class="no-top" width="auto">
                    <common-asideVue />
                </el-aside>
                <el-main class="el-main">
                    <traingaiqian-right />
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    import ElContainer from "element-ui/packages/container/src/main";
    import CommonAsideVue from '@/components/CommonAside.vue'
    import CommonHeader from '@/components/CommonHeader.vue'
    import TraingaiqianRight from "../components/TraingaiqianRight";
    export default {
        components: {
            TraingaiqianRight,
            ElContainer,CommonHeader,CommonAsideVue},
        name: "traingaiqian",
        data () {
            return {}
        },
        methods: {

        }
    }
</script>

<style scoped>
    .el-header{
        padding:0;
    }
    .no-top /deep/ .el-menu{
        margin-top: 0;
    }
</style>