<template>
  <div class="container">
    <div class="login-page">
      <div class="logo cleftmargin">

      </div>
      <div class="cjtitle cleftmargin">
        创建新账户
      </div>
      <div class="yyaccount">
          已有账户？<span class="ljlogin" title="马上登录" @click="gotologin">马上登录</span>
      </div>
      <div class="regformreg">

          <el-form ref="form"  :rules="rules" class="formstyle" label-position="top"  :model="form" label-width="30%">
                <el-form-item  label="手机号" class="mttop labelstyle" prop="mobilephone">
                    <el-input class="inputstyle heightstyle"  v-model="form.mobilephone" clearable></el-input>
                </el-form-item>
              <el-form-item label="验证码"  class="mttopinfo labelstyle" prop="checkcode">
                  <el-input class="checkcodes"  v-model="form.checkcode" clearable></el-input>
                  <el-button  class="sendmsgcodeinfo" style=" background: #42B134;color:#FFFFFF;"
                             @click="getCode"
                             :disabled="!login.canGet"
                             type="button">
                      <span v-show="!login.canGet">{{login.waitTime+"s"}}后重发</span>
                      <span v-show="login.canGet">{{tempLogin.text}}</span>
                  </el-button>
              </el-form-item>
              <el-form-item label="密码"  class="mttopinfo labelstyle"  prop="pwd">
                  <el-input show-password class="inputstyle"  v-model="form.pwd" clearable></el-input>
              </el-form-item>
              <el-form-item label="确认密码"  class="mttopinfo labelstyle"  prop="pwd2">
                  <el-input show-password class="inputstyle"  v-model="form.pwd2" clearable></el-input>
              </el-form-item>
              <el-form-item label="单位(选填)"  class="mttopinfo labelstyle"  prop="danwei">
                  <el-select filterable remote reserve-keyword :remote-method="(value) => getcomdeptdata(value)" :loading="loading" class="inputstyle" v-model="form.danwei" placeholder="请选择单位(选填)" @change="danweiChange">
                      <el-option
                        v-for="(item,index) in comdeptdata"
                        :key="index"
                        :label="item.company_name"
                        :value="item.company_id">
                      </el-option>
                  </el-select>
                  <!-- <el-input class="inputstyle"  v-model="form.danwei" clearable></el-input> -->
              </el-form-item>
              <el-form-item label="部门(选填)"  class="mttopinfo labelstyle"  prop="dept">
                  <el-select class="inputstyle" v-model="form.dept" placeholder="请选择部门(选填)">
                      <el-option label="请选择部门(选填)" value=""></el-option>
                      <el-option
                        v-for="(item,index) in comdeptdata2.departments"
                        :key="index"
                        :label="item.dpt_name"
                        :value="item.dpt_id">
                      </el-option>
                  </el-select>
                  <!-- <el-input class="inputstyle"  v-model="form.dept" clearable></el-input> -->
              </el-form-item>
              <div style="overflow: hidden;" v-if="form.danwei">
                  <el-col :span="12">
                      <el-form-item label="姓名"  class="mttopinfo labelstyle"  prop="xingming">
                          <el-input class="inputstyle heightstyle"  v-model="form.xingming" clearable></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="性别"  class="mttopinfo labelstyle"  prop="xingbie">
                          <el-select class="inputstyle" v-model="form.xingbie"
                              placeholder="选择">
                              <el-option label="男" value="1"></el-option>
                              <el-option label="女" value="2"></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="人员类型"  class="mttopinfo labelstyle"  prop="person_type">
                          <el-select class="inputstyle" v-model="form.person_type"
                              placeholder="选择">
                              <el-option label="成人" value="1"></el-option>
                              <el-option label="儿童" value="2"></el-option>
                              <el-option label="学生" value="3"></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="证件类型"  class="mttopinfo labelstyle"  prop="id_type">
                          <el-select class="inputstyle" v-model="form.id_type"
                              placeholder="选择">
                              <el-option label="身份证" value="1"></el-option>
                              <el-option label="护照" value="2"></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col :span="24">
                      <el-form-item label="证件号码"  class="mttopinfo labelstyle"  prop="id_number">
                          <el-input class="inputstyle heightstyle"  v-model="form.id_number" clearable></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="24">
                      <el-form-item label="证件照片"  class="mttopinfo labelstyle"  prop="zjnumber">
                        <el-col :span="12">
                          <el-upload :show-file-list="false" :fileList="fileList" :on-success="handleSuccessCardZhengmian"
                              action="http://jhkadmin.3.16448.cn/api/common/upload"
                              list-type="picture-card" :limit="1">
                              <i class="el-icon-plus"></i>
                              <img :src="id_img_zhengmian" class="previewimgstyles" v-if="id_img_zhengmian" />
                          </el-upload>
                        </el-col>
                        <el-col :span="12">
                          <el-upload :show-file-list="false" :fileList="fileList" :on-success="handleSuccessCardFanmian"
                              action="http://jhkadmin.3.16448.cn/api/common/upload" list-type="picture-card"
                              :limit="1">
                              <i class="el-icon-plus"></i>
                              <img :src="id_img_fanmian" class="previewimgstyles" v-if="id_img_fanmian" />
                          </el-upload>
                        </el-col>
                      </el-form-item>
                  </el-col>
              </div>
              <el-form-item >
                  <el-button class="el-button--inner" style="background-color: #42B134;color:#FFFFFF;border:1px solid #42B134;" @click="submitReginfo" title="立即注册">立即注册</el-button>
              </el-form-item>
          </el-form>

      </div>
    </div>
  </div>
</template>
<script>
    import Cookie from 'js-cookie';
    import { register,smsCode,querycompanyexitsinfo,getComdeptdata } from '../api/index';
export default {
  components: {

  },
  watch: {

  },
  created () {

  },
  data () {
    return {
        tempLogin: { // 定义一个临时对象
            canGet: true,
            timer: null,
            waitTime: 60,
            text: '发送验证码'
        },
        form: {
            mobilephone: "",
            checkcode: "",
            pwd:"",
            pwd2:"",
            danwei:"",
            dept:"",
        },
        rules: {
            mobilephone: [
                { required: true, message: '请输入手机号', trigger: 'blur' },
                { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
            ],
            checkcode: [
                { required: true, message: '请输入验证码', trigger: 'blur' },
            ],
            pwd: [
                { required: true, message: '请输入密码', trigger: 'blur' },
            ],
            pwd2: [
                { required: true, message: '请输入密码', trigger: 'blur' },
            ],
            xingming: [
                { required: true, message: '请输入姓名', trigger: 'blur' },
            ],
            xingbie: [
                { required: true, message: '请输入性别', trigger: 'blur' },
            ],
            person_type: [
                { required: true, message: '请选择人员类型', trigger: 'blur' },
            ],
            id_type: [
                { required: true, message: '请选择证件类型', trigger: 'blur' },
            ],
            id_number: [
                { required: true, message: '请输入证件号码', trigger: 'blur' },
            ],
            

        },
        comdeptdata:[],
        comdeptdata2:[],
        fileList: [],
        id_img_zhengmian:"",
        id_img_fanmian:"",
        loading:false
    }
  },
    computed: {
        login () { // 最终对象
            const _this = this
            if (!this.tempLogin.canGet) {
                if (this.tempLogin.waitTime === 0) {
                    _this.tempLogin.text = '重新发送'
                }
                return this.timeCountdown(this.tempLogin)
            } else {
                return this.tempLogin
            }
        },
    },
    activated(){
       // this.getcomdeptdata();
    },
  methods: {
      // 获取单位部门
      getcomdeptdata(query){
          const _this = this
          if(query.length<2){
              return false;
              _this.comdeptdata=[]
          }
          _this.loading=true
          getComdeptdata({search:query}).then(res => {
              console.log(res.data.data)
              _this.comdeptdata=res.data.data
              _this.loading=false
          })
      },
      danweiChange(){
          const _this = this
          var comdeptdata2=this.comdeptdata.find(function(item){
              return item.company_id==_this.form.danwei
          })
          _this.comdeptdata2=comdeptdata2||[]
          _this.form.dept=""
      },
      //注册提交
      submitReg(){
          var mobilephonestr=this.form.mobilephone;
          var checkcodestr=this.form.checkcode;
          var pwdstr=this.form.pwd;
          var danweistr=this.form.danwei;
          var deptstr=this.form.dept;
          if(""!=danweistr && null!=danweistr){
             //根据单位名查询是否注册过
              const params = {
                  danwei:danweistr,
                  deptName: deptstr
              }
              querycompanyexitsinfo(params).then(res => {
                  if (res.data.code && res.data.code === 200) {
                     this.submitReginfo();
                  }else{
                      var  msgstr=res.data.msg;
                      this.$message.error('异常:'+msgstr);
                  }
            })
          }else{
              this.submitReginfo();
          }
      },
      //提交注册
      submitReginfo(){
          var mobilephonestr=this.form.mobilephone;
          var checkcodestr=this.form.checkcode;
          var pwdstr=this.form.pwd;
          var pwdstr2=this.form.pwd2;
          var danweistr=this.form.danwei;
          var deptstr=this.form.dept;
          this.$refs["form"].validate((val) => {
              if (val) {
                  const params = {
                      mobile: mobilephonestr,
                      password: pwdstr,
                      code:checkcodestr,
                      gs_id:danweistr,
                      dept_id: deptstr,
                  }
                  if(pwdstr!=pwdstr2){
                      this.$message.warning('两次密码不一致');
                      return false;
                  }
                  if(danweistr){
                      if(!deptstr){
                          this.$message.warning('请选择部门');
                          return false;
                      }
                      if(!this.id_img_zhengmian){
                          this.$message.warning('请上传证件照正面');
                          return false;
                      }
                      if(!this.id_img_fanmian){
                          this.$message.warning('证件照反面');
                          return false;
                      }
                  }
                  params.xingming=this.form.xingming
                  params.xingbie=this.form.xingbie
                  params.person_type=this.form.person_type
                  params.id_type=this.form.id_type
                  params.id_number=this.form.id_number
                  params.id_img_zhengmian=this.id_img_zhengmian
                  params.id_img_fanmian=this.id_img_fanmian
                  //const p = this.$querystring.stringify(params);
                  register(params).then(res => {
                      if (res.data.code && res.data.code === 1) {
                      this.$message.success('注册成功');
                      //this.$emit('loadingFunt', false);
                      this.$router.push('/login');
                  }else{
                      var  msgstr=res.data.msg;
                      this.$message.error('注册失败:'+msgstr);
                      //this.$emit('loadingFunt', false);

                  }
              })
              } else {
                  return false;
           }
        });
      },
      //转向登录
      gotologin(){
          this.$router.push('/login');
      },
      //发送短信验证码
      getCode(){
          var  mobilephonestr=this.form.mobilephone;
          if(""==mobilephonestr || null==mobilephonestr){
              this.$message.warning('请输入手机号！');
              return;
          }
          const isPhone = (str) => {
              var reg = /^1[3-9]\d{9}$/;
              return reg.test(str);
          }
          
          if(!isPhone(mobilephonestr)) {
              this.$message.warning('请输入正确手机号！');
              return;
          }
          
          const params = {
              mobile: mobilephonestr,
              event:'register'
          }
          //调用短信发送接口
          smsCode(params).then(res => {
              if (res.data.code && res.data.code === 1) {
               this.$message.success('发送成功');
              }else{
               var  msgstr=res.data.msg;
               this.$message.error('发送失败:'+msgstr);
              }
          })
          this.timeCountdown(this.login) // 参数为最终对象
      },
      timeCountdown (obj) {
          // obj包括timer、waitTime 、canGet
          const TIME_COUNT = 60 // 默认倒计时秒数
          if (!obj.timer) {
              obj.waitTime = TIME_COUNT
              obj.canGet = false
              obj.timer = setInterval(() => {
                  if (obj.waitTime > 0 && obj.waitTime <= TIME_COUNT) {
                      obj.waitTime--
                  } else {
                      obj.canGet = true
                      clearInterval(obj.timer) // 清空定时器
                      obj.timer = null
                  }
              }, 1000)
          }
          return {
              timer: obj.timer,
              canGet: obj.canGet,
              waitTime: obj.waitTime
          }
      },
      //上传证件照正面
      handleSuccessCardZhengmian(res, file) {
          console.log(res)
          if(res.code==1){
              this.id_img_zhengmian=res.data.fullurl
          }else{
              this.$message.error(res.msg)
          }
          console.log(res)
          console.log(this.form)
      },
      //图片预览
      handlePictureCardPreview(file) {
          this.id_img_zhengmian = file.url;
      },
      //上传证件照反面
      handleSuccessCardFanmian(res, file) {
          if(res.code==1){
              this.id_img_fanmian=res.data.fullurl
          }else{
              this.$message.error(res.msg)
          }
      
      },
  }
}
</script>
<style lang="less">
.el-upload{position: relative;}
.el-upload img{position: absolute;width: 100%;height: 100%;left: 0;top: 0;object-fit: contain;}
    .mttopinfo{
        margin-top:-1.3vh;
    }
    .regformreg .labelstyle .el-form-item__label{
        font-size:18px;
        font-family: 'MicrosoftYaHei';
        color:#000000;
        margin-top:1vh;
    }
    .regformreg .el-form-item__label{
        line-height:25px;
    }
    .regformreg .formstyle{
        font-family: "MicrosoftYaHei";
        color:#000000;
    }
  //   .nowreginfo{
  //       width:290px;
  //       height:40px;
  //       background-color: #42B134;
  //       border-radius: 7px;
  //       color:#FFFFFF;
  //       text-align: center;
  //       cursor:pointer;
  //   }
    .el-button--inner{
        width:100%;
        height:40px;
        background-color: #42B134;
        border-radius: 7px;
        color:#FFFFFF;
        text-align: center;
        cursor:pointer;
        border:1px solid #42B134;
    }

    .sendmsgcodeinfo{
        width: 120px;
        height: 40px;
        background: #42B134;
        border-radius: 7px;
        color:#FFFFFF;
        text-align: center;
        margin-left:10px;
        padding:10px;
        padding-left:20px;
        padding-right:20px;
        cursor:pointer;
    }
    .checkcodes{
        width:215px;
        border-radius: 10px;
    }
    .regformreg .el-input__inner {
        border-color: #42B134;
        border-radius: 7px;
    }

    .regformreg .el-input__inner:focus {
        border-color: #42B134;
        border-radius: 7px;
    }

  .regformreg{
      margin-top:3vh;

  }
  // .ljlogin{
  //   color:#42B134;
  //   font-family: 'MicrosoftYaHei';
  //   cursor:pointer;
  // }
  // .yyaccount{
  //   margin-left:40vw;
  //   color:#000000;
  //   font-family: 'MicrosoftYaHei';
  //     font-size:18px;
  //   margin-top:1vh;
  // }
  // .cleftmargin{
  //   margin-left:35vw;
  // }
  // .cjtitle{
  //   font-size:36px;
  //   color:#42B134;
  //   font-weight: Bold;
  //     font-family: 'MicrosoftYaHei-Bold';
  //   padding-top:15vh;
  //   padding-left:5vw;
  // }
  // .container{
  //    background-image:url(../assets/img/bg.png);
  //    background-repeat: no-repeat;
  //    width:100%;
  //   min-height: 100vh;
  //   background-size: 100% 100%;

  // }
  // .logo{
  //   position: absolute;
  //   background-image:url(../assets/img/logo.png);
  //   background-repeat: no-repeat;
  //   width:185px;
  //   height:72px;
  //   margin-left:40vw;
  //   margin-top:20px;
  //   z-index:2000;

  // }
  // .box-card{

  // }
</style>