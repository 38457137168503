<template>
	<div class="cdiv">
		<div class="header flex">
			<div>
				<el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')" fit="cover"></el-image>
			</div>
			<div class="pleft">当前位置：<span>首页</span><span>>机票预订</span></div>
		</div>
		<div class="hc">
			<div class="baoqiantips">
				<div>抱歉！在线查询功能暂未对您开放！您可以直接预定下单！</div>
				<div>也可以通过企业微信、公众号找到我们！</div>
				<div>专业客服会为您提供1V1查询服务！</div>
			</div>
			<div class="qrcode flex">
				<div class="qrcodes">
					<div>
						<el-image class="qrcodeicon" :src="objws.wxkf_img" fit="cover"></el-image>
					</div>
					<div>微信客服</div>
				</div>
				<div class="qrcodes">
					<div>
						<el-image class="qrcodeicon" :src="objws.xcx_img" fit="cover"></el-image>
					</div>
					<div>小程序入口</div>
				</div>
				<div class="qrcodes">
					<div>
						<el-image class="qrcodeicon" :src="objws.gzh_img" fit="cover"></el-image>
					</div>
					<div>公众号入口</div>
				</div>
			</div>
			<div class="operbtn">
				<el-form ref="form" :rules="rules" label-position="top" :model="form" label-width="30%">
					<el-form-item class="itemstyles">
						<el-button class="zxkefu" @click="gozixunkefu" title="点击咨询客服">点击咨询客服</el-button>
					</el-form-item>
					<el-form-item class="itemstyles">
						<el-button class="ydjp" @click="gotoyudingairtk" title="预订机票">预订机票</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="copyright">工业和信息化部备案管理系统网站 豫B1-20080191-28</div>
	</div>
</template>

<script>
import ElImage from "element-ui/packages/image/src/main";
import ElCard from "element-ui/packages/card/src/main";
import { querywebsite, airInit } from "../api/index";
export default {
	components: {
		ElCard,
		ElImage,
	},
	data() {
		return {
			objws: {},
			form: {
				date1: "",
				sendcity: "",
				arrivalcity: "",
			},
			rules: {
				mobilephone: [
					{ required: true, message: "请输入手机号", trigger: "blur" },
					{ pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确", trigger: "blur" },
				],

				pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
			},
		};
	},
	activated() {
		this.querywebsiteinfo();
	},
	methods: {
		//查询网站信息接口
		querywebsiteinfo() {
			const params = {};
			airInit(params).then((res) => {
				this.objws = res.data.data;
			});
		},
		//咨询客服
		gozixunkefu() {            
            window.open(this.objws.kefu_url, "_blank");
        },
		//预订机票
		gotoyudingairtk() {
			this.$router.push("/airticketyudingshenqing");
		},
	},
	name: "airticketyuding-right",
};
</script>

<style scoped>
.weiziiconst {
	width: 24px;
	height: 24px;
}

.cdiv {
	/* margin-top: 30px; */
}

.itemstyles {
	/* margin-left: -16vw; */
	padding-top: 10px;
	padding-bottom: 10px;
}

.ydjp {
	border: 1px solid #42b134;
	color: #ffffff;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 11.5vw;
	padding-right: 11.5vw;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	margin-left: 0;
	color: #42b134;
}

.operbtn {
	margin-top: 5vh;
	/* margin-left:20vw; */
	justify-content: center;
	display: flex;
	flex-direction: column;
	padding: 0 50px;
}
.operbtn button {
	margin-bottom: 20px;
}
.zxkefu {
	background-color: #42b134;
	color: #ffffff;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 10.8vw;
	padding-right: 10.8vw;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

.mleftd {
	margin-left: -2vw;
}

.querybtn {
	background-color: #42b134;
	color: #ffffff;
	border-radius: 5px;
	margin-left: -1vw;
}

.formstyle {
	padding-top: 2.2vh;
}

.container {
}

.header {
	font-size: 12px;
}

.flex {
	display: flex;
}

.weiziicon {
}

.pleft {
	margin-left: 0.5vw;
	font-family: MicrosoftYaHei;
	font-weight: 400;
	font-size: 16px;
	color: #000000;
}

.copyright {
	width: calc(88% - 0px - 10px) !important;
	font-size: 12px;
	text-align: center;
	margin-top: 5vh;
	color: #b6b6b6;
}

.hc {
	background-color: #f1f1f1;
	width: calc(88% - 0px - 10px) !important;
	margin-top: 2vh;
	text-align: center;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

.baoqiantips {
	padding-top: 4vh;
	line-height: 28px;
	color: #000000;
	font-family: "MicrosoftYaHei";
}

.qrcode {
	margin-top: 2vh;
	/* margin-left:18vw; */
	width: 100%;
	justify-content: center;
}

.qrcodes {
	/* margin-left: 2vw; */
	border: 1px solid #585858;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	padding: 10px;
	width: 100px;
	font-size: 12px;
	text-align: center;
}
.qrcodes:nth-child(2) {
	margin: 0 2vw;
}
.qrcodeicon {
	width: 62px;
	height: 63px;
}
</style>
