<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')"
                          fit="cover"></el-image>
            </div>
            <div class="pleft">
                当前位置：<span>首页</span><span>>火车票订单</span><span>>火车票订单详情</span>
            </div>
        </div>
        <div class="addpgstyle">
            订单状态：待出票
        </div>
        <div class="tcontent">
            <div class="ttop flex">
                <div class="liushuino graycolor">
                    流水号：2164686445451123
                </div>
                <div class="xiadandate graycolor">
                    下单时间：2020-02-02 12:12:33
                </div>
                <div class="fachedate graycolor">
                    发车时间：2020-02-02 12:12:33
                </div>
            </div>
            <div class="flex">
                <div class="scheci commonmtop">
                    <div class="sccdate flex">
                        <div class="startzhan">北京南</div>
                        <div class="iconstyles">
                            <el-image style="margin-left:1vw;" class="weiziicon" :src="require('../assets/img/shi.png')"
                                      fit="cover"></el-image>
                        </div>
                    </div>
                    <div class="scdiv flex startweight bluecolor">
                        06:20
                    </div>
                </div>
                <div class="haoshi commonmtop">
                    <div class="cxinfo">G102</div>
                    <div>
                        <el-image class="weiziicon" :src="require('../assets/img/xian.png')"
                                  fit="cover"></el-image>
                    </div>
                    <div class="sleft">6小时20分</div>
                </div>
                <div class="endcheci commonmtop">
                    <div class="sccdate flex">
                        <div class="startzhan">郑州东</div>
                        <div class="iconstyles">
                            <el-image style="margin-left:1vw;" class="weiziicon" :src="require('../assets/img/zhong.png')"
                                      fit="cover"></el-image>
                        </div>
                    </div>
                    <div class="scdiv flex startweight bluecolor">
                        15:20
                    </div>
                </div>
            </div>

        </div>


        <div class="hcorder flex">
            <div class="gqleftdiv">
                <div class="gqleftdivinfo">
                    <div class="xuhao">
                        1
                    </div>
                </div>
                <div class="gqstyle">
                    <div class="gqstyleinfo">
                        已改签
                    </div>
                </div>
            </div>

            <div class="gqrightdiv">
                <div class="flex gqrightdivleft">
                    <div>
                        <span class="fmstyle">韩某某</span><span class="mspanleft">成人  身份证：110xxxxxxxxxxxxxxx</span>
                    </div>
                    <div class="mgleftstyle">
                        一等座  73车12号
                    </div>
                    <div class="mgleftstyle">
                        订单号：556111565565665
                    </div>
                    <div class="mgleftstyle">
                        <el-image class="imgicons" :src="require('../assets/img/baozhang.png')"
                                  fit="cover"></el-image>
                    </div>
                </div>
                <div class="flex mtopsinfo" style="margin-top:0;">
                    <div>
                        改签流水号：10000000
                    </div>
                    <div class="querybtninfo">
                        查看
                    </div>
                </div>
                <div class="flex mtopsinfo">
                    <div>
                        改签原因：这里显示具体的改签时间
                    </div>

                </div>
            </div>


        </div>
        <div class="hcordertk flex">
            <div class="gqleftdivtwos">
                <div class="gqleftdivinfo">
                    <div class="xuhao">
                        2
                    </div>
                </div>

            </div>
            <div class="gqrightdivtwo">
                <div class="flex gqrightdivlefttwo">
                    <div>
                        <span class="fmstyle">韩某某</span><span class="mspanleft">成人  身份证：110xxxxxxxxxxxxxxx</span>
                    </div>
                    <div class="mgleftstyle">
                        一等座  73车12号
                    </div>
                    <div class="mgleftstyle">
                        订单号：556111565565665
                    </div>

                </div>
            </div>
        </div>
        <div class="hcordertp flex">
            <div class="gqleftdivtp">
                <div class="gqleftdivinfo">
                    <div class="xuhao">
                       3
                    </div>
                </div>
                <div class="gqstyle">
                    <div class="gqstyleinfo">
                        已退票
                    </div>
                </div>
            </div>

            <div class="gqrightdiv tkmargintop">
                <div class="flex gqrightdivleft">
                    <div>
                        <span class="fmstyle">韩某某</span><span class="mspanleft">成人  身份证：110xxxxxxxxxxxxxxx</span>
                    </div>
                    <div class="mgleftstyle">
                        一等座  73车12号
                    </div>
                    <div class="mgleftstyle">
                        订单号：556111565565665
                    </div>
                    <div class="mgleftstyle">

                    </div>
                </div>

                <div class="flex mtopsinfo">
                    <div>
                        退票原因：这里显示具体的改签时间
                    </div>

                </div>
            </div>
        </div>
        <div class="remark">
            备注
        </div>
        <div class="remarkinfo">
            <el-input type="textarea" v-model="textareaValue"
                      maxlength="500" show-word-limit resize="none" :rows="5"
                      class="textarea-box" placeholder="这里显示订单备注信息,可多行"></el-input>
        </div>
        <div class="ftbottom flex">
            <div class="xiadanperson">
                下单人：张三 共2人
            </div>
            <div>
                总金额：<span class="moneycolor">￥1000.5</span>
            </div>
        </div>


        <div class="copyright">
            工业和信息化部备案管理系统网站 豫B1-20080191-28
        </div>
        <el-dialog
                title="退票"
                v-show="dialogVisible || isCollapse"
                :visible="dialogVisible || isCollapse"
                width="320px"
                top="80px"
                :before-close="handleCloseTuipiao"
        >
            <el-card class="box-card">
                <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                    <div>
                        <div class="pgmargintop flex">
                            <div class="pgmleft">确认韩某某退票吗？</div>

                        </div>

                    </div>
                    <div class="btnstylesinfo">
                        <el-button
                                class="login-btn clearbtn">
                            取消
                        </el-button>
                        <el-button
                                class="login-btn"
                                type="primary"
                                style="background-color: rgba(66, 177, 52, 1);"
                                :loading="logLoading"
                        >确认</el-button
                        >
                    </div>
                </el-form>
            </el-card>
        </el-dialog>
        <el-dialog
                title="改签"
                v-show="dialoggaiqianVisible || isCollapse"
                :visible="dialoggaiqianVisible || isCollapse"
                width="50%"
                top="80px"
                :before-close="handleClosegaiqian"
        >
            <el-card class="box-card">
                <el-form ref="form" :rules="rules" :model="form" class="formstyleyd" label-width="150px">
                    <div class="flex">


                        <el-form-item label="出发车站：" class=" fsizestyle" prop="sendcity">
                            <el-col :span="20">
                                <el-input class="inputstyle heightstyle"  placeholder="请输入" v-model="form.user" clearable></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="到达车站：" class=" fsizestyle" prop="sendcity">
                            <el-col :span="20">
                                <el-input  class="inputstyle heightstyle"  placeholder="请输入" v-model="form.id" clearable></el-input>
                            </el-col>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <el-form-item label="出发日期：" class="mttop" prop="date1">
                            <el-col :span="20">
                                <el-date-picker
                                        type="date"
                                        placeholder="选择日期"
                                        v-model="form.senddate"
                                        style="width: 10vw;"
                                ></el-date-picker>
                            </el-col>
                        </el-form-item>
                    </div>
                    <div class="flex">
                       <span class="gqdesc">
                           改签说明：发车的48小时内无法修改目的地，只能改签15天以内的车次
                       </span>
                    </div>

                    <div class="pgbtns">

                        <el-button
                                class="login-btn confirmbtn"
                                type="primary"
                                style="background-color: rgba(66, 177, 52, 1);"
                                :loading="logLoading"
                        >提交</el-button
                        >
                    </div>

                </el-form>
            </el-card>
        </el-dialog>
        <el-dialog
                title="修改"
                v-show="dialogupdateVisible || isCollapse"
                :visible="dialogupdateVisible || isCollapse"
                width="50%"
                top="80px"
                :before-close="handleCloseupdate"
        >
            <el-card class="box-card">
                <el-form ref="form" :rules="rules" :model="form" class="formstyleyd" label-width="150px">
                    <div class="flex">

                        <el-form-item  label="人员类型" class="mttop" prop="sendcity">
                            <el-col :span="20">
                                <el-select v-model="form.rytype" placeholder="选择人员类型">
                                    <el-option label="成人" value="0"></el-option>
                                    <el-option label="儿童" value="1"></el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="姓名" class="mttop fsizestyle" prop="sendcity">
                            <el-col :span="20">
                                <el-input class="inputstyle heightstyle"  placeholder="请输入" v-model="form.user" clearable></el-input>
                            </el-col>
                        </el-form-item>

                    </div>
                    <div class="flex">
                        <el-form-item  label="证件类型" class="mttop" prop="sendcity">
                            <el-col :span="20">
                                <el-select v-model="form.idtype" placeholder="选择证件类型">
                                    <el-option label="身份证" value="0"></el-option>
                                    <el-option label="护照" value="1"></el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="证件号码" class=" fsizestyle" prop="sendcity">
                            <el-col :span="20">
                                <el-input  class="zjinputstyle heightstyle"  placeholder="请输入" v-model="form.id" clearable></el-input>
                            </el-col>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <el-form-item  label="席座" class="" prop="sendcity">
                            <el-col :span="20">
                                <el-select v-model="form.zuoxi" placeholder="选择席座">
                                    <el-option label="二等座" value="0"></el-option>
                                    <el-option label="一等座" value="1"></el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <el-form-item label="购买保险" class=" fsizestyle" prop="sendcity">
                            <el-checkbox
                                    class="all_t"
                                    :indeterminate="isIndeterminate"
                                    v-model="form.isbaoxian"
                                    @change="handleCheckAllChangeup"
                                    :checked="ischecked"
                            >是</el-checkbox
                            >
                            <el-checkbox
                                    class="all_t"
                                    :indeterminate="isIndeterminate"
                                    v-model="form.isnobaoxian"

                            >否</el-checkbox
                            >
                        </el-form-item>
                    </div>
                    <div class="pgbtns">

                        <el-button
                                class="login-btn confirmbtn"
                                type="primary"
                                style="background-color: rgba(66, 177, 52, 1);"
                                :loading="logLoading"
                        >提交</el-button
                        >
                    </div>

                </el-form>
            </el-card>
        </el-dialog>

    </div>
</template>

<script>
    import ElImage from "element-ui/packages/image/src/main";
    import ElCard from "element-ui/packages/card/src/main";
    import ElButton from "element-ui/packages/button/src/button";
    export default {
        components: {
            ElButton,
            ElCard,
            ElImage
        },
        data () {
            return {
                ischecked:false,
                dialogVisible:false,
                dialoggaiqianVisible:false,
                dialogupdateVisible:false,
                textareaValue:'',
                form: {
                    date1: "",
                    sendcity: "",
                    arrivalcity:"",
                },
                rules: {
                    mobilephone: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                        { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
                    ],

                    pwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                    ],

                }
            }
        },
        created(){
            this.ischecked=true;
        },
        methods: {
            //删除
            gotodel(){
                this.$confirm('您确定要删除该记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                    //     this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });

            },
            //退票关闭窗口
            handleCloseTuipiao(){
                this.dialogVisible=false;
            },
            //退票
            gototuipiao(){
                this.dialogVisible=true;
            },
            handleClosegaiqian(){
                this.dialoggaiqianVisible=false;
            },
            //改签弹窗
            gotogaiqian(){
                this.dialoggaiqianVisible=true;
            },
            //修改弹窗
            gotoupdate(){
                this.dialogupdateVisible=true;
            },
            //关闭修改弹窗
            handleCloseupdate(){
                this.dialogupdateVisible=false;
            },
            //转向预定申请
            forwardyuding(){
                this.$router.push('/trainyudingshenqing');
            }
        },
        name: "trainorderdetails-right"
    }
</script>

<style scoped>
    .mspanleft{
        display: inline-block;
        margin-left:40px;
    }
    .fmstyle{
        display: inline-block;
        font-family: MicrosoftYaHei;
        font-weight: bold;
    }
    .tkmargintop{
        margin-top:10px;
    }
    .querybtninfo{
        border:1px solid rgba(0, 0, 0, 1);
        margin-left:20px;
        padding-left:10px;
        padding-right:10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    .mtopsinfo{
        margin-top:10px;
    }
    .gqrightdivleft{
        margin-top:10px;
    }
    .gqrightdivlefttwo{

    }
    .imgicons{
        width:28px;
        height:28px;
    }
    .mgleftstyle{
        padding-left:20px;
    }
    .gqrightdiv{

    }
    .gqrightdivtwo{
        height:60px;
        align-items: center;
        display: flex;

    }
    .gqleftdivtp{
        width:140px;
        font-size:16px;
        height: 140px;
        padding-top:20px;
    }
    .gqleftdiv{
        width:140px;
        font-size:16px;
        height: 140px;
        padding-top:30px;
    }
    .gqleftdivtwos{
        width:140px;
        font-size:16px;
        height: 140px;
        padding-top:20px;
    }
    .gqleftdivinfo{
        width:140px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .gqstyle{
        width:140px;
        justify-content: center;
        align-items: center;
        display: flex;
        padding-top:10px;
    }
    .gqstyleinfo{

    }
    .xuhao{
        width:20px;
        height:20px;
        border-radius: 50%;
        background-color: #000000;
        color:#FFFFFF;
        text-align: center;
    }
    .remark{
        font-weight: bolder;
        margin-top:20px;
    }
    .remarkinfo{
        width: calc(88% - 0px - 10px) !important;
    }
    .textarea-box{
        width: calc(100% - 0px - 0px) !important;
        background-color: #F1F1F1;
        margin-top:2vh;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    .textarea-box  /deep/ .el-textarea__inner {
        background-color: #F1F1F1;
    }
    .pgmleft{
        text-align: center;
    }
    .pgmargintop{
        margin-top:20px;
        justify-content: center;
        display: flex;
    }
    .btnstylesinfo{
        margin-top:30px;
        justify-content: center;
        display: flex;
        text-align: center;
    }
    .gqdesc{
        margin-left:68px;
    }
    .formstyleyd .inputstyle{
        width:10vw;
    }
    .confirmbtn{
        width: calc(58% - 0px - 0px) !important;
        padding-left:200px;
        padding-right:200px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }
    .pgbtns{
        margin-top:20px;
        width: calc(100% - 0px - 0px) !important;
        justify-content: center;
        text-align: center;
    }
    .pgtop{
        margin-top:10px;
    }
    .pgleft{
        margin-left:10px;
    }
    .fmtop{

    }
    .weiziiconst{
        width:24px;
        height:20px;
    }
    .cdiv{
        margin-top:30px;
    }
    .obtn{
        margin-top:5vh;
        margin-left:18vw;
    }
    .cancelbtn{
        background: #969696;
        padding-left:20px;
        padding-right:20px;
        color:#FFFFFF;
        width:230px;
        border-radius: 10px;
    }
    .paybtn{
        background: #42B134;
        padding-left:20px;
        padding-right:20px;
        color:#FFFFFF;
        width:230px;
        margin-left:2vw;
        border-radius: 10px;
    }
    .xiadanperson{
        width:54vw;
    }
    .ftbottom{
        margin-top:2vh;
        width: calc(88% - 0px - 10px) !important;
    }
    .moneycolor{
        color:#FF7342;
    }
    .hengxian{
        width: calc(100% - 0px - 10px) !important;
        border:1px dashed #999999;
    }
    .imgmargin{

    }
    .selck{

    }
    .cstyle{
        margin-left:1.5vw;
    }
    .mleftinfo{
        margin-left:1vw;
    }
    .graycolor{
        color:#646464;
    }
    .ttop{
        margin-left:2vw;
    }
    .xiadandate{
        margin-left:8vw;
    }
    .fachedate{
        margin-left:8vw;
    }
    .shuoming{
        font-size:13px;
        color:#444444;
        line-height:25px;
    }
    .textarea-box{
        width:68vw;
        background-color: #F1F1F1;
        margin-top:2vh;
        border-radius: 10px;
    }
    .remark{
        font-weight: bolder;
    }
    .orderbtn{
        width:200px;
        height:35px;
        border:1px solid #42B134;
        color:#FFFFFF;
        padding:10px;
        margin-top:5vh;
        background-color: #42B134;
    }
    .pgbtn{
        width:200px;
        height:35px;
        border:1px solid #000000;
        color:#000000;
        padding:10px;
        margin-top:5vh;
    }
    .addpg{
        margin-left:25vw;
        margin-right:25vw;
    }
    .xuhao{
        width:20px;
        height:20px;
        border-radius: 50%;
        background-color: #000000;
        color:#FFFFFF;
        text-align: center;

    }
    .pginfotitle{
        width:61vw;
        font-weight: bolder;
        margin-left:1vw;
    }
    .pginfo{
        margin-top:2vh;
    }
    .sfsbbtn{
        border:1px solid #000000;
        color:#000000;
        margin-left:1vw;
        margin-top:4vh;
    }
    .btnoper{

    }
    .bluecolor{
        color:#42B134;
    }
    .startweight{
        font-weight: bolder;
        font-size:20px;
    }
    .iconstyles{

    }
    .addpgstyle{
        font-weight: bolder;
        margin-top:2vh;
    }
    .copyright{
        width: calc(88% - 0px - 10px) !important;
        font-size:12px;
        text-align: center;
        margin-top:5vh;
        color:#B6B6B6;
    }
    .mleftmargin{
        margin-left:1vw;
    }
    .pager{
        margin-top:5vh;
        margin-left:10vw;
    }
    .shouye{

    }
    .sybtn{
        width:100px;
        height:35px;
        border:1px solid #666666;
        text-align: center;
    }
    .btnnodiv{
        margin-left:1vw;
    }
    .btnno{
        width:50px;
        height:35px;
        border:1px solid #666666;
        text-align: center;
    }

    .commonmtop{
        margin-top:2vh;
    }
    .btnmargin{
        margin-top:1vh;
    }
    .btnstyle{
        width:100px;
        height:35px;
        background-color: #42B134;
        color:#FFFFFF;
        border-radius: 5px;
    }
    .operbtn{
        margin-left:8vw;
    }
    .checizuowei{
        margin-left:5vw;
    }
    .colorstyle{
        color:#F49200;
        font-weight: bolder;
    }
    .checileixing{
        margin-left:5vw;
    }
    .checimoney{
        margin-left:5vw;
    }
    .zwleixing{
        margin-top:0.1vh;
    }
    .cmtopzw{
        margin-top:2vh;
    }
    .cxinfo{
        margin-left:14vw;
    }
    .endcheci{
        margin-left:3vw;
    }
    .sleft{
        margin-left:13vw;
    }
    .scheci{
        margin-left:2vw;

    }
    .startzhan{
        margin-left:0.5vw;
    }
    .scdiv{
        margin-top:2vh;
        margin-left:1vw;
    }
    .sccdate{
        font-size:20px;
        font-weight: bolder;

    }
    .haoshi{
        margin-left:3vw;
    }
    .tcontent{
        background-color:#F1F1F1;
        height:140px;
        margin-top:2vh;
        width: calc(88% - 0px - 10px) !important;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        padding-top:4vh;
    }
    .mleftd{
        margin-left:-2vw;
    }
    .querybtn{
        background-color:#42B134;
        color:#FFFFFF;
        border-radius: 5px;
        margin-left:-1vw;
    }
    .formstyletrainord{
        padding-top:2.2vh;

    }
    .container{

    }
    .header{
        font-size:12px;
    }
    .flex{
        display:flex;
    }
    .weiziicon{

    }
    .pleft{
        margin-left:0.5vw;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
    }
    .hcorder{
        background-color: rgba(251, 251, 227, 1);
        width: calc(88% - 0px - 10px) !important;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        height:110px;
        margin-top:2vh;
    }

    .hcordertp{
        background-color: rgba(251, 229, 227, 1);
        width: calc(88% - 0px - 10px) !important;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        height:90px;
        margin-top:2vh;
    }
    .hcordertk{
        background-color: rgba(241, 241, 241, 1);
        width: calc(88% - 0px - 10px) !important;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        height:60px;
        margin-top:2vh;
    }
    .hc{
        background-color: #F1F1F1;
        width:68vw;
        height:14vh;
        margin-top:2vh;
    }
    .hcpg{
        background-color: #F1F1F1;
        width:68vw;
        height:27vh;
        margin-top:2vh;
    }
    button:hover {background-color: #42B134;color:#FFFFFF;}
</style>